import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { Outlet } from "react-router-dom";

import "./user-layout.css";
import MenuItemsUser from "../menu-items-user/menu-items-user";
import MobileMenuItemsUser from "../menu-items-user/mobile-view-menu-item";

const drawerWidth = 240;
const navItems = ["Home", "About", "Contact"];

function DrawerAppBar(props) {
  const { window, active } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center", backgroundColor: "#000936" }}
    >
      <Typography
        component="h1"
        variant="h6"
        color="inherit"
        noWrap
        // sx={{ display: { xs: "none", sm: "block" } }}
        style={{
          alignSelf: "start",
          textAlign: "start",
          color: "white",
          fontFamily: "Poppins",
          fontWeight: "500",
          fontSize: 25,
          marginTop: "8px",
          marginLeft: "40px",
        }}
      >
        ABSYZ Lounge
      </Typography>
      <Divider style={{ backgroundColor: "#000035" }} />
      <List
        style={{
          backgroundColor: "#000936",
        //   height: "100%",
        //   display: "flex",
        //   justifyContent: "center",
        //   alignItems: "flex-start",
        }}
      >
        <MobileMenuItemsUser active={active} />
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: { xs: "flex", sm: "none" } }}>
      <AppBar
        component="nav"
        position="absolute"
        style={{ backgroundColor: "#000936" }}
      >
        <Toolbar className="column">
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon className="menuiconBtn" />
          </IconButton>
          <Typography
            component="h1"
            variant="h6"
            color="inherit"
            noWrap
            // sx={{ display: { xs: "none", sm: "block" } }}
            style={{
              alignSelf: "start",
              textAlign: "start",
              color: "white",
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: 25,
              marginTop: "8px",
              marginLeft: "40px",
            }}
          >
            ABSYZ Lounge
          </Typography>
        </Toolbar>
      </AppBar>
      <Box component="nav" sx={{ backgroundColor: "#000936" }}>
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              backgroundColor: "#000936",
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        // className='layout-size'
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          overflow: "hidden !important",
        }}
      >
        <Toolbar />
        <Container
          className="adminlayout"
          maxWidth="xxl"
          sx={{ minheight: "40vh", padding: "0px !important" }}
        >
          <Outlet />
        </Container>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
