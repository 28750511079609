import React, { useState } from "react";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
// import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import MenuItemsUser from "../menu-items-user/menu-items-user";
import "./user-layout.css";
import { Outlet } from "react-router-dom";
import referAFriend from "../../../assets/images/referAFriend.png";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import axios from "axios";
import { Custom } from "../../../components/Custom/Custom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { MyForm, Form } from "../../../components/MyForm";
import IconButton from "@mui/material/IconButton";
import Notification from "../../../components/Custom/Notification";
import referIcon from "../../../assets/images/referIcon.png";
import blogIcon from "../../../assets/images/blogIcon.png";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/core";
import API from "../../../api/index";
import { useLocation } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import PropTypes from "prop-types";
import DrawerAppBar from "./mobile-user-layout";

const api1 = API.Api1;
const api2 = API.Api2;

const styles = makeStyles((theme) => ({
  main: {
    "& .MuiDialog-paper": {
      maxWidth: "1000px",
    },
  },
  phoneWidth: {
    "& .MuiOutlinedInput-adornedStart": {
      width: "277px !important",
    },
    

    openDrawer: {
      "& .MuiPaper-root": {
        backgroundColor: "#000936",
      },
    },
  },
}));

const getRelation = [
  { id: "1", title: "Friend" },
  { id: "2", title: "Cousin" },
];
const getvalue = [
  { id: "1", title: "Hyderabad" },
  { id: "2", title: "Bangalore" },
];
const choices = [
  { value: "UI Developer", label: "UI Developer" },
  { value: "Salesforce Developer", label: "Salesforce Developer" },
  { value: "Front End Developer", label: "Front End Developer" },
];

const initialValues = {
  fullname: "",
  email: "",
  department: "",
  phone: "",
  referalrelation: "",
  tech: "",
  jobpos: "",
  uploadresume: "",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const SheetJSFT = ["pdf", "doc"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const mdTheme = createTheme();

const drawerWidths = 240;

const navItems = ["Home", "About", "Contact"];
//
function UserHomePage(props) {
  const classes = styles();
  const { window } = props;
  const [open, setOpen] = useState(false);
  const [openReferPopup, setOpenReferPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [fileMerge, setFileMerge] = useState();
  const [showValues, setShowValues] = useState(false);
  const [done, setDone] = useState(undefined);
  const [mobileOpen, setMobileOpen] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  //   const navigate = useNavigate();

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const location = useLocation();
  console.log(location);
  const active =
    location.pathname === "/user/home/quizSelection" ||
    location.pathname === "/user/home/quizExpand" ||
    location.pathname === "/user/home/quizQuestions" ||
    location.pathname === "/user/home/quizResult";

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("fullname" in inputValues)
      code.fullname = inputValues.fullname ? "" : "First Name is required";
    if ("email" in inputValues)
      code.email =
        inputValues.email && /$^|.+@.+..+/.test(inputValues.email)
          ? ""
          : "Email is not valid";
    if ("phone" in inputValues)
      code.phone =
        inputValues.phone.length > 9 ? "" : "Minimum 10 numbers required";
    if ("jobpos" in inputValues) {
      // console.log(inputValues.jobpos);
      code.jobpos =
        inputValues.jobpos.length !== 0 ? "" : "SkillSet is required";
    }
    if ("department" in inputValues)
      code.department =
        inputValues.department.length !== 0
          ? ""
          : "Current Openings is required";
    if ("tech" in inputValues)
      code.tech =
        inputValues.tech.length !== 0 ? "" : "Preferred Location is required";
    if ("referalrelation" in inputValues)
      code.referalrelation =
        inputValues.referalrelation.length !== 0
          ? ""
          : "Referal Relation is required";
    // if ('uploadresume' in inputValues)
    //   code.uploadresume = inputValues.uploadresume ? '' : 'Resume is required';
    setErrors({
      ...code,
    });
    // console.log(inputValues);
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    handleValueChange,
  } = MyForm(initialValues, true, validate);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const onClickLogout = () => {
    Cookies.remove("jwt_token");
    navigate("/");
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpenReferPopup(true);
    getDepartments();
  };

  const handleClose = () => {
    //     {(validate() === true)?
    // setOpen(true)
    // :null
    // console.log("insdie handleclose")}
    setOpenReferPopup(true);
  };

  const handleCloseByCancel = () => {
    resetForm();

    setOpenReferPopup(false);
    // console.log('insdie handleclose');
  };

  const getDepartments = async () => {
    await axios({
      method: "get",
      url: `${api1}/admin/departments/`,
      headers: { token: `qwerty`, "Content-Type": "application/json" },
    }).then((response) => {
      //console.log(response.data.data[0].departments)
      const getDepartmentCollection = response.data.data[0].departments;

      const newData = [];
      for (var i = 0; i < getDepartmentCollection.length; i++) {
        //console.log(getDepartmentCollection[i]);
        const dept = {
          id: i,
          title: getDepartmentCollection[i].dp_name,
          dp_pk: getDepartmentCollection[i].dp_pk,
        };
        newData.push(dept);
      }
      setDone(true);
      //  console.log(newData);
      setDepartments(newData);
    });
  };

  const jwtToken = Cookies.get("jwt_token");
  // console.log(jwtToken, 'jwt');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // console.log(typeof(fileMerge),'fileMerge');
      // //console.log(newData);
      var formData = new FormData();
      formData.append("re_fullname", values.fullname);
      formData.append("re_email", values.email);
      formData.append("current_openings", values.department);
      formData.append("re_relation", values.referalrelation);
      formData.append("re_location", values.tech);
      formData.append("other_skillset", values.jobpos);
      formData.append("re_number", values.phone);
      // console.log(fileMerge, "files")
      formData.append("resume", fileMerge);

      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }

      const headers = {
        // "token": "qwerty",
        "x-auth-token": `${jwtToken}`,
        // 'Access-Control-Allow-Origin': '*',
      };
      //  const config = { headers };
      try {
        const response = await fetch(`${api1}/user/addfrd`, {
          method: "POST",
          body: formData,
          headers: headers,
        });
        const resultInJson = await response.json();
        // console.log(resultInJson,"responseofreferafriend");
        if (resultInJson.api_status === 200) {
          // const fullname = formData.re_fullname;
          //   const number = formData.re_number;
          const url = resultInJson.data.url;
          const payload = JSON.stringify({
            re_fullname: values.fullname,
            re_number: values.phone,

            re_email: values.email,
            current_openings: values.department,
            re_relation: values.referalrelation,
            re_location: values.tech,
            other_skillset: values.jobpos,
            resume: fileMerge,
            url: url,
          });

          const headers = {
            "Content-Type": "application/json",
            "x-auth-token": `${jwtToken}`,
          };
          const config = headers;
          const res = await fetch(`${api1}/user/refer-mail`, {
            method: "POST",
            body: payload,
            headers: config,
          });
          const resultInJson2 = await res.json();
          // console.log(resultInJson2,"responseofreferafriend2");
          // console.log(res, 'response of mail');
          resetForm();
          setOpenReferPopup(false);
          setNotify({
            isOpen: true,
            message: "Referred successfully",
            type: "success",
          });
        }
        // console.log(resultInJson2);
      } catch (e) {
        // console.log('something went wrong!', e);
      }
    }
  };

  const handleChange = (e /*:File*/) => {
    // console.log("file", files.target.files);
    setFileMerge(e.target.files[0]);
    console.log("resume", e.target.files[0]);
    // console.log(files.target.files[0])
    setShowValues(true);
  };

  const menuId = "primary-search-account-menu";

  // To open and close the menu Icon
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: { xs: "none", sm: "flex" } }}>
        <CssBaseline />
        <AppBar
          position="absolute"
          style={{ backgroundColor: "#000936" }}
          open={open}
        >
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
            className="column"
          >
            <IconButton
              edge="start"
              color="inherit"
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                // marginRight: "12px",
                paddingRight: "15px !important",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon className="menuiconBtn" />
            </IconButton>

            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
              style={{
                alignSelf: "start",
                textAlign: "start",
                color: "white",
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: 25,
                marginTop: "11px",
                marginLeft: "40px",
              }}
            >
              ABSYZ Lounge
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", sm: "flex", md: "flex" } }}>
              <Link href="https://absyz.com/blog/" target="_blank">
                <IconButton
                  size="medium"
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={menuId}
                  aria-haspopup="true"
                  //onClick={onClickLogout}
                  color="inherit"
                >
                  {/* <FeedIcon fontSize="inherit" className="logoutbtn" /> */}
                  <img src={blogIcon} alt="blogs icon" width="23" height="23" />
                  <Typography
                    style={{
                      marginRight: "25px",
                      paddingLeft: "7px",
                      fontSize: "14px",
                      textDecoration: "underline",
                      color: "#fff",
                    }}
                  >
                    Blogs
                  </Typography>
                </IconButton>
              </Link>
              <IconButton
                className="referafriend-button"
                size="medium"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                //onClick={onClickLogout}
                onClick={handleClickOpen}
                color="inherit"
                sx={{
                  padding: "7px 10px 6.7px",
                  borderRadius: "4px",
                  backgroundColor: "#fb2485",
                }}
              >
                {/* <GroupIcon fontSize="inherit" className="logoutbtn" /> */}
                <img src={referIcon} alt="person icon" width="23" height="23" />
                <Typography style={{ paddingLeft: "7px", fontSize: "14px" }}>
                  Refer A Friend
                </Typography>
              </IconButton>

              <Dialog
                open={openReferPopup}
                onClose={handleClose}
                style={{
                  padding: "0px 0px 0px 0px !important",
                  overflowY: "hidden !important",
                }}
                maxWidth={maxWidth}
                fullWidth={fullWidth}
                className={classes.main}
              >
                <Form onSubmit={handleSubmit}>
                  {/* <DialogTitle>Create New Candidate Record</DialogTitle> */}
                  <DialogContent>
                    <Box sx={{ flexGrow: 1, padding: "0px !important" }}>
                      <Grid container style={{ padding: "0px !important" }}>
                        <Grid item xs={12} sm={4} md={4} lg={4}>
                          <div>
                            <img
                              src={referAFriend}
                              alt="ReferAFriendPic"
                              className="userlayout-refer-a-friend"
                            />
                          </div>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={8}
                          md={8}
                          lg={8}
                          className="userlayout-refer-a-friend-grid"
                        >
                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              className="userlayout-grid-inner"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                              >
                                Name
                              </Typography>
                              <span style={{ marginLeft: "-7px" }}>
                                <Custom.Input
                                  name="fullname"
                                  placeholder="Enter Full Name"
                                  value={values.fullname}
                                  onChange={handleInputChange}
                                  error={errors.fullname}
                                  size="small"
                                />
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                Phone Number
                              </Typography>
                              <span style={{ marginLeft: "-7px" }}>
                                <Custom.Phone
                                  name="phone"
                                  label="0000000000"
                                  value={values.phone}
                                  onChange={handleInputChange}
                                  error={errors.phone}
                                  size="small"
                                  className={classes.phoneWidth}
                                />
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                Email Address
                              </Typography>
                              <span style={{ marginLeft: "-7px" }}>
                                <Custom.Input
                                  name="email"
                                  value={values.email}
                                  placeholder="example@email.com"
                                  onChange={handleInputChange}
                                  error={errors.email}
                                  size="small"
                                />
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                Referal Relation
                              </Typography>
                              <span style={{ marginLeft: "-7px" }}>
                                <Custom.Dropdown
                                  name="referalrelation"
                                  label="Select Option"
                                  onChange={handleInputChange}
                                  options={getRelation}
                                  value={values.referalrelation}
                                  error={errors.referalrelation}
                                  size="small"
                                  style={{
                                    height: 40,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                ></Custom.Dropdown>
                                {/*                               
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="referalrelation"
                                  // value={dp_caty_status}
                                  // label="Select Position"
                                  // label="Select option"
                                  displayEmpty
                                  value={values.referalrelation}
                                  error={errors.referalrelation}
                                  size="large"
                                  style={{
                                    height: 40,
                                    width: 260,
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  autoWidth
                                  onChange={handleInputChange}
                                >
                                  <MenuItem
                                    style={{
                                      height: 40,
                                      width: 260,
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    value={'Y'}
                                  >
                                    Friend
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      height: 40,
                                      width: 260,
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    value={'N'}
                                  >
                                    Cousin
                                  </MenuItem>
                                </Select> */}
                              </span>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                Current Openings
                              </Typography>
                              <span style={{ marginLeft: "-7px" }}>
                                <Custom.Dropdown
                                  name="department"
                                  label="Select Option"
                                  value={values.department}
                                  onChange={handleInputChange}
                                  options={departments}
                                  error={errors.department}
                                  size="small"
                                  style={{
                                    height: 40,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                ></Custom.Dropdown>
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                Other SkillSet
                                {/* <Tooltip title="Give NA if you don't find option in current openings" arrow>
  <IconButton size="small"><InfoIcon fontSize="inherit"/></IconButton>
</Tooltip> */}
                              </Typography>
                              <span
                                style={{ marginLeft: "-7px", marginTop: "0px" }}
                              >
                                <Custom.Input
                                  name="jobpos"
                                  placeholder="NA"
                                  // defaultValue="NA"
                                  onChange={handleInputChange}
                                  value={values.jobpos}
                                  error={errors.jobpos}
                                  size="small"
                                />
                                {/* <Custom.SearchDropdown
                                  options={choices}
                                  label="jobpos"
                                  size="small"
                                  //  value={values.jobpos}
                                  placeholder="Search Job "
                                  style={{ width: 260 }}
                                  onChange={handleValueChange}
                                  isClearable
                                  error={errors.jobpos}
                                /> */}
                              </span>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                  // marginTop:'7px'
                                }}
                                variant="h6"
                              >
                                Preferred Location
                              </Typography>
                              <span style={{ marginLeft: "-7px" }}>
                                <Custom.Dropdown
                                  name="tech"
                                  label="Select Option"
                                  onChange={handleInputChange}
                                  options={getvalue}
                                  value={values.tech}
                                  error={errors.tech}
                                  size="small"
                                  style={{
                                    height: 40,
                                    alignItems: "center",
                                    display: "flex",
                                  }}
                                ></Custom.Dropdown>
                              </span>
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={10}
                            md={6}
                            lg={6}
                            className="userlayout-refer-a-friend-grid-inner"
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                style={{
                                  marginLeft: 0,
                                  fontFamily: "Poppins",
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  fontStretch: "normal",
                                  fontStyle: "normal",
                                  lineHeight: "normal",
                                  letterSpacing: "normal",
                                  textAlign: "left",
                                  color: "#000",
                                }}
                                variant="h6"
                              >
                                Upload Resume
                              </Typography>
                              <span style={{ marginLeft: "0px" }}>
                                <input
                                  type="file"
                                  name="uploadresume"
                                  className="upload-box1"
                                  accept={SheetJSFT}
                                  style={{
                                    height: 40,
                                    marginTop: "8px",
                                    fontSize: "0.8em",
                                    fontFamily: "Poppins",
                                  }}
                                  onChange={handleChange}
                                />
                              </span>
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className="userlayout-last-buttons"
                            // style={{
                            //   display: 'flex',
                            //   justifyContent: 'flex-end',
                            //   marginTop: '10px',
                            // }}
                          >
                            <ReusableButton
                              onClick={handleCloseByCancel}
                              size="large"
                              variant="outlined"
                              buttonName="Cancel"
                              style={{
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#000936",
                                border: "1px solid #000936",
                                padding: "4px 20px",
                                borderRadius: "4px",
                                marginRight: "10px",
                                marginBottom: "25px",
                                textTransform: "none",
                                height: "42px",
                              }}
                            >
                              Cancel
                            </ReusableButton>
                            <ReusableButton
                              onClick={handleClose}
                              className="addbutton"
                              type="submit"
                              size="large"
                              variant="contained"
                              buttonName="Refer Now"
                              style={{
                                backgroundColor: "#fb2485",
                                marginRight: "20px",
                                marginBottom: "25px",
                                fontSize: "16px",
                                fontWeight: "normal",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                lineHeight: "normal",
                                letterSpacing: "normal",
                                textAalign: "center",
                                color: "#fff",
                                padding: "8px 20px",
                                borderRadius: "4px",
                                textTransform: "none",
                                height: "42px",
                              }}
                            >
                              Refer
                            </ReusableButton>
                          </Grid>
                        </Grid>
                      </Grid>
                    </Box>
                  </DialogContent>
                </Form>
              </Dialog>

              <IconButton
                size="medium"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={onClickLogout}
                color="inherit"
              >
                <LogoutIcon
                  fontSize="inherit"
                  sx={{ marginLeft: "15px", marginRight: "5px" }}
                  className="logoutbtn"
                />
              </IconButton>
            </Box>
            {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box> */}
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          open={open}
          sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Toolbar
            style={{
              backgroundColor: "#000936",
              display: "flex",
              justifyContent: "end",
            }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
              backgroundColor: "#000936",
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon
                style={{
                  color: "white",
                }}
              />
            </IconButton>
          </Toolbar>
          <Divider style={{ backgroundColor: "#000035" }} />
          <List
            component="nav"
            className={classes.openDrawer}
            style={{ backgroundColor: "#000936", height: "100%" }}
          >
            {<MenuItemsUser active={active} />}
          </List>
        </Drawer>

        <Box
          component="main"
          // className='layout-size'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "hidden !important",
          }}
        >
          <Toolbar />
          <Container
            className="adminlayout"
            maxWidth="xxl"
            sx={{ minheight: "40vh", padding: "0px !important" }}
          >
            <Outlet />
          </Container>
        </Box>
        <Notification notify={notify} setNotify={setNotify} />
      </Box>

      {/* mobile view appbar */}
      <DrawerAppBar active={active} />
    </ThemeProvider>
  );
}

export default UserHomePage;
