import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
// import Link from "@mui/material/Link";
import { ReusableInputfield } from "../../../components/Custom/inputfield/inputfield";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me";
// import { blue } from "@mui/material/colors";
import AdminBg from "../../../assets/images/adminLoginPageBg.png";
import AdminContentPic from "../../../assets/images/adminLoginPageContentpic.png";
import Rectangle from "../../../assets/images/Rectangle.png";
import "./user-login.css";
import { makeStyles } from "@material-ui/core/styles";
import Cookies from "js-cookie";
import { CircularProgress } from "@material-ui/core";

import Notification from "../../../components/Custom/Notification";
import loginImg1 from "../../../assets/images/loginImg1.png";
import Link from "@mui/material/Link";
import { NavLink } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import API from "../../../api/index";
import ReactLoading from "react-loading";
import HelpIcon from "@mui/icons-material/Help";
const api1 = API.Api1;
const api2 = API.Api2;

const inputFieldData = {
  email: "",
  password: "",
};

const inputErrorMsg = {
  forEmail: "",
  forPassword: "",
};

const inputFieldErrorStatus = {
  emailStatus: false,
  passwordStatus: false,
};

export const UserLogin = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);

  const [loading, setLoading] = useState(false);

  const [emailValue, setEmailValue] = useState("");
  // const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [done, setDone] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.logincheckbox && localStorage.loginusername !== "") {
      setIsChecked(true);
      setInputFieldValues({
        ...inputFieldValues,
        email: localStorage.loginusername,
      });
      setEmailValue(localStorage.loginusername);
      setInputFieldValues({
        ...inputFieldValues,
        password: localStorage.loginpassword,
      });
      //console.log(localStorage.loginusername, 'loginusername');
      //console.log(localStorage.loginpassword, 'loginpassword');
      //console.log(localStorage.logincheckbox, 'loginchkbx');
    }
  }, []);
  const useStyles = makeStyles({
    inputfieldLabel: {
      "& .MuiFormLabel-root": {
        color: "black",
        fontWeight: 400,
      },
    },
  });

  const classes = useStyles();
  // //console.log(inputFieldValues,"inputFieldValues")

  const handleSubmit = async () => {
    setLoading(true);
    console.log("handleclick");
    //console.log(emailValue, 'username85');
    setErrorStatus({ ...errorStatus, emailStatus: false });
    setErrorStatus({ ...errorStatus, passwordStatus: false });

    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var passwordValidation =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    //console.log(emailValue, 'input VALUE gIVEN');
    //console.log(inputFieldValues.password, 'input VALUE gIVEN');

    if (emailValidation.test(emailValue) === false) {
      setErrorStatus({ ...errorStatus, emailStatus: true });
      setInputFieldErrors({ forEmail: "Enter Correct Email-ID" });
    } else if (passwordValidation.test(inputFieldValues.password) === false) {
      setErrorStatus({ ...errorStatus, passwordStatus: true });
      setInputFieldErrors({
        ...inputFieldErrors,
        forPassword: "Enter Correct Password",
      });
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        //body: JSON.stringify(getDesignation1),
        body: JSON.stringify({
          email: emailValue,
          password: inputFieldValues.password,
        }),
      };
      const response = await fetch(`${api1}/user/login`, requestOptions);
      const onSubmitSuccess = (jwtToken) => {
        setLoading(false);
        Cookies.set("jwt_token", jwtToken, {
          expires: 30,
        });
      };

      const data = await response.json();
       console.log(data,"datavalue");
      if (data.api_status === 200) {
        console.log("hehe");
        const jwtToken = data.data[0].jwttoken;
        const userdetails = data.data[0].userdetails;

        // const timer = setTimeout(() => {

        //   setNotify({
        //     isOpen: true,
        //     message: 'Login Successfully',
        //     type: 'success',
        //   });
        //   console.log("holo timer")

        //   setTimeout(() => {

        //     console.log("inside")
        //  }, 2000);

        if (isChecked === true && inputFieldValues.email !== "") {
          localStorage.loginusername = emailValue;
          localStorage.loginpassword = inputFieldValues.password;
          localStorage.logincheckbox = isChecked;
        }
        if (response.ok === true) {
          onSubmitSuccess(jwtToken);
          setDone(true);
          navigate("/user/home/");
        } else {
          //console.log('check the response');
          setLoading(false);
        }

        if (jwtToken !== undefined) {
          // return <Navigate to="/home" />;
          //console.log(jwtToken, 'jwtToken');
        }
      } else {
        // setNotify({
        //   isOpen: true,
        //   message: 'Login Failed',
        //   type: 'error',
        // });
        if (data.error[0].error_msg === "please register ") {
          setLoading(false);
          setErrorStatus({ ...errorStatus, emailStatus: true });
          setInputFieldErrors({ forEmail: data.error[0].error_msg });
        } else {
          setLoading(false);
          setErrorStatus({ ...errorStatus, passwordStatus: true });
          setInputFieldErrors({
            ...inputFieldErrors,
            forPassword: data.error[0].error_msg,
          });
        }
      }
    }
  };

  const handleTextChangeEmail = (event, value) => {
    //console.log("values", event.target.value);
    // setEmail(event.target.value);
    setInputFieldValues({ ...inputFieldValues, email: event.target.value });
    setEmailValue(event.target.value);
  };
  const handleTextChangePass = (event, value) => {
    //console.log("values", event.target.value);
    // setPassword(event.target.value);
    setInputFieldValues({ ...inputFieldValues, password: event.target.value });
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
    console.log(isChecked, "isChecked");
  };

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        // style={{
        //   // height: "100%",
        //   zIndex: 1,
        //   position: "absolute",
        //   width: "100%",
        // }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: "7%",marginBottom: "7%", Width: "100%", borderRadius: 0 }}
          >
            <CardContent sx={{ padding: "15px 33px 33px 0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={loginImg1}
                      alt="AdminContentPic"
                      className="user-login-loginimage"
                      // width="350"
                      // height="410"
                      // style={{ marginLeft: "25px" }}
                    />
                  </div>
                </Grid>

                <Grid item xs={12} sm={6} md={6} className={classes.textGrid}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <span className="adminLoginHeadingOne">Welcome!</span>
                    <span
                      className="adminLoginHeadingTwo"
                      style={{ marginTop: "9px" }}
                    >
                      ABSYZ Lounge
                    </span>
                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: "14px", marginBottom: "7px" }}
                    >
                      User Login
                    </span>
                  </Box>

                  <Box sx={{ marginTop: 2 }} className="textfield-grid">
                    <Stack direction="column" spacing={3}>
                      <ReusableInputfield
                        variant="outlined"
                        name="email"
                        margin="normal"
                        required
                        type="text"
                        value={emailValue}
                        id="email"
                        fullWidth
                        label="E-Mail"
                        onChange={(e) => handleTextChangeEmail(e, "email")}
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        // className={classes.inputfieldLabel}
                        className="inputfield"
                        error={errorStatus.emailStatus}
                        helperText={inputFieldErrors.forEmail}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={inputFieldValues.password}
                        onChange={(e) => handleTextChangePass(e, "password")}
                        id="password"
                        // id="outlined-basic"
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        // className={classes.inputfieldLabel}
                        className="inputfield"
                        error={errorStatus.passwordStatus}
                        helperText={inputFieldErrors.forPassword}
                      />
                      <div 
                      className="noteinuserlogin"
                        style={{
                          textAlign:"center",
                          marginTop: "5px",
                          
                          fontSize: "12px",
                          fontWeight: "bold",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        Note - Password must contain Special Character(!@#$%^&*)
                        and should be Alphanumeric
                      </div>
                      <Stack
                        direction="row"
                        spacing={2}
                        className="stackclass"
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "space-between",
                          marginLeft: "-10px",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <ReusableRememberMe
                            color="default"
                            size="small"
                            name="lsRememberMe"
                            checked={isChecked}
                            onChange={onChangeCheckbox}
                          />
                          <label
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="loginFieldText"
                          >
                            Remember Me
                          </label>
                        </Stack>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            href="/user/forgotPassword"
                            underline="none"
                            style={{
                              fontSize: "0.8em",
                              color: "#717171",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                            }}
                            className="loginFieldText"
                          >
                            Forgot Password?
                          </Link>
                        </div>
                      </Stack>
                    </Stack>

                    <div
                    className="user-Login-button-div"
                      // style={{
                      //   display: "flex",
                      //   justifyContent: "start",
                      //   paddingTop: "15px",
                      // }}
                    >
                      <ReusableButton
                        size="medium"
                        variant="contained"
                        type="submit"
                        className="adminLoginButton"
                        // buttonName="Login Now"
                        buttonName={
                          !loading ? (
                            "Login Now"
                          ) : (
                            <CircularProgress size={20} />
                          )
                        }
                        style={{
                          backgroundColor: "#fe1c7f",
                          fontFamily: "Poppins",
                          fontSize: "0.8em",
                          fontWeight: 200,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#fff",
                          padding: "12px 35px 12px 35px",
                          borderRadius: "4px",
                          minWidth: "135px",
                        }}
                        onClick={() => {
                          setDone(false);
                          handleSubmit();
                        }}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
          {/* )} */}
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};
