import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import PropTypes from 'prop-types';

import { Grid, Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CountDownTimer from '../quiz/quiz-timer';
import ReactLoading from 'react-loading';
import ReusableButton from '../../../components/reusable-button/reusable-button';
import { DataObject, Panorama } from '@mui/icons-material';
import Header from './Header';
import Questions from './Questions';
export default function QuizQuestions() {
  const location = useLocation();

  const [subcatnamedisplay, setSubCatNameDisplay] = useState();
  // const timelimit = location.state.data.countdown_time;
  // const [question, setQuestion] = useState([]);

  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);

  const [done, setDone] = useState(undefined);
  const [finish, setFinish] = useState(false);
  // console.log(location.state.data, "Location data of two");

  //alert("initialized")

  useEffect(() => {
    //console.log("file nameeee")

    // getAllQuestions();
    // ResultsOfQuiz();
    setSubCatNameDisplay(location.state.data.dp_sub_caty_name);

    // getDeparmentApi();
    // subCategoryData();
  }, []);
  const setData = (data) => {
    //console.log(data);
    setStart(data.start);
  };
  const showRes = (data) => {
    setRes(data.res);
  };
  const setFinishVal = (data) => {
    setFinish(data);
    // //console.log(finish)
  };
  const setCallBack = (data) => {
    setDone(data);
  };
  return (
    <>
      <>
       
        <Box style={{ padding: '36px' }}>
          <Grid container spacing={0}>
            {!done ? (
              <Box
                sx={{ flexGrow: 1 }}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  height: '70vh',
                }}
              >
                {/* {console.log('parent')} */}
                <ReactLoading
                  type={'spinningBubbles'}
                  color={'#fb2485'}
                  height={100}
                  width={100}
                />
              </Box>
            ) : (
              <Header
                show={!res}
                subcatnamedisplay={subcatnamedisplay}
                start={start}
                callback2={setFinishVal}
              />
            )}
            <Questions
              callback={setData}
              showRes={showRes}
              fin={finish}
              loaded={setCallBack}
            />
          </Grid>
        </Box>
      </>
    </>
  );
}
