import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import axios from "axios";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useNavigate } from "react-router-dom";
import ReplayIcon from "@mui/icons-material/Replay";
import quizfail from "../../../assets/images/quizfail.png";
import quizsuccess from "../../../assets/images/quizsuccess.png";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { Link } from "react-router-dom";
import ErrorIcon from "@mui/icons-material/Error";
import StarsIcon from "@mui/icons-material/Stars";
import "./style.css"
export default function QuizResult() {
  const location = useLocation();

  // console.log(location.state, 'Location data of quiz result');
  const percent = parseFloat(location.state.result.pass_score.toFixed(2));

  const scored = parseFloat(location.state.result.percentage);
  return (
    <>
      {scored <= percent ? (
        <Box style={{ paddingRight: "20px",marginBottom: "40px",marginTop:'30px' }}
        className="quiz-results-background-white-class">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-50px",
              }}
            >
              <h4
                style={{
                  fontSize: "1.3750em",
                  fontFamily: "Poppins",
                  color: "white",
                  fontStretch: "normal",
                  letterSpacing: "normal",
                }}
              >
                Quiz
              </h4>
            </Grid>
            <Grid
              item
              xs={12}
              
              style={{
                backgroundColor: "white",
                marginLeft: "15px",
                marginTop: "-20px",
                borderRadius: "40px",
                padding: "0px !important",
                height: "auto",
              }}
            >
              <Grid
                className="card-img"
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  color: "white",
                  height: "95px",
                }}
              >
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {/* {location.state.data.dp_sub_caty_name} */}
                  {location.state.result.dp_sub_caty_name}
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <CircularProgress variant="determinate" value={100} /> */}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className="quiz-results-inner-outer-Grid"
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={4}
                  className="quiz-results-inner-div-failed-image"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "flex-end",
                  //   marginLeft: "20px",
                  // }}
                >
                  <div
                  
                    // style={{
                    //   display: "flex",
                    //   justifyContent: "end",
                    //   marginLeft: "20px",
                    // }}
                  >
                    <img
                      src={quizfail}
                      alt="quizfail"
                      className="quiz-results-inner-failed-image"
                      // width="267"
                      // height="241"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#ec3b3a",
                      }}
                    >
                      <ErrorIcon style={{ height: "64px", width: "64px" }} />
                    </Grid>
                    <Typography
                    className="quiz-results-padding-failed"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center"
                      }}
                    >
                      <b>Sorry, you did not pass the quiz.</b>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                      }}
                    >
                      <b>Your Score:</b>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        textAlign: "center"
                      }}
                    >
                      <b>
                        {location.state.result.correct_answers} of{" "}
                        {location.state.result.total_questions} Correct (
                        {location.state.result.percentage}%)
                      </b>
                    </Typography>
                    <Typography
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        color: "#666666",
                        textAlign : "center"
                      }}
                    >
                      {location.state.result.pass_score}% (at least 1 of
                      3)needed to pass
                    </Typography>
                    
                    <Typography
                    className="quiz-results-padding-failed"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        textAlign : "center"
                      }}
                    >
                      As a result, the answers are unavailable!{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3} sm={3}></Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Grid item xs={1} md={1}></Grid>
                <Grid item xs={1} md={1}></Grid>
                <Grid item xs={2.5} md={8} className="quiz-results-hide-grid"></Grid>
                <Grid item xs={9} md={2}
                className="quiz-results-retake-button">
                  <Link
                    to="/user/home/quizExpand"
                    state={{ data: location.state.result }}
                    style={{ textDecoration: "none" }}
                  >
                    <ReusableButton
                      size="large"
                      variant="contained"
                      className="candidateDltepageDeleteBttn"
                      buttonName="Retake"
                      startIcon={<ReplayIcon />}
                      style={{
                        backgroundColor: "#fb2485",
                        marginRight: "10px",
                        // marginBottom:"25px",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        padding: "8px 28px",
                        borderRadius: "4px",
                        textTransform: "none",
                      }}
                      //  onClick={()=>setQuestIndex(questIndex+1)}
                    ></ReusableButton>
                  </Link>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      ) : (
        <Box style={{  paddingRight: "20px",marginBottom: "40px",marginTop:'30px'  }}
        className="quiz-results-background-white-class">
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-60px",
              }}
            >
              <h4
                style={{
                  fontSize: "1.3750em",
                  fontFamily: "Poppins",
                  color: "white",
                  fontStretch: "normal",
                  letterSpacing: "normal",
                }}
              >
                Quiz
              </h4>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "white",
                marginLeft: "15px",
                marginTop: "-20px",
                borderRadius: "35px",
                padding: "0px !important",
                height: "auto",
              }}
            >
              <Grid
                className="card-img"
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  color: "white",
                  height: "95px",
                }}
              >
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {/* {location.state.data.dp_sub_caty_name} */}
                  {location.state.result.dp_sub_caty_name}

                  {/* {location.state.result.name} */}
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  {/* <CircularProgress variant="determinate" value={100} /> */}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                className="quiz-results-inner-outer-Grid"
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   marginTop: "15px",
                // }}
              >
                <Grid
                  item
                  xs={12}
                  md={3}
                  sm={4}
                  className="quiz-results-inner-div-failed-image"
                  // style={{
                  //   display: "flex",
                  //   justifyContent: "flex-end",
                  //   marginLeft: "20px",
                  // }}
                >
                  <div
                    // style={{
                    //   display: "flex",
                    //   justifyContent: "end",
                    //   marginLeft: "20px",
                    // }}
                  >
                    <img
                      src={quizsuccess}
                      className="quiz-results-inner-failed-image"
                      alt="quizfail"
                      // width="267"
                      // height="241"
                    />
                  </div>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  sm={4}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <Grid item xs={12}>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        color: "#0c9251",
                      }}
                    >
                      <StarsIcon style={{ height: "64px", width: "64px" }} />
                    </Grid>
                    <Typography
                    // className="quiz-results-padding-failed"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        textAlign:"center"
                      }}
                    >
                      <b>Congratulations, you have</b>
                    </Typography>
                    <Typography
                    // className="quiz-results-padding-failed"
                      style={{
                        fontSize: "20px",
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center"
                      }}
                    >
                      <b> successfully completed the quiz.</b>
                    </Typography>
                    <Typography
                    // className="quiz-results-padding-failed"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <b>Your Score:</b>
                    </Typography>
                    <Typography
                    // className="quiz-results-padding-failed"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                    >
                      <b>
                        {location.state.result.correct_answers} of{" "}
                        {location.state.result.total_questions} Correct (
                        {location.state.result.percentage}%)
                      </b>
                    </Typography>
                    <Typography
                    className="quiz-results-padding-failed"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "10px",
                        color: "#666666",
                      }}
                    >
                      Pass Score {location.state.result.pass_score}%
                    </Typography>
                    {/* <Typography style={{fontSize:'14px',display:'flex',justifyContent:'center',marginTop:'20px'}}>
      <b>Elapsed Time: 0 Minutes</b>                                  </Typography> */}
                    <Typography
                    className="quiz-results-padding-failed"
                      style={{
                        fontSize: "14px",
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "20px",
                        textAlign: "center"
                      }}
                    >
                      As a result, the answers are unavailable!{" "}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={12} md={3} sm={3}></Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                }}
              >
                <Grid item xs={1} md={1}></Grid>
                <Grid item xs={1} md={1}>
                  
                </Grid>
                <Grid item xs={2.5} md={8} className="quiz-results-hide-grid"></Grid>

                <Grid item xs={9} md={2}
                className="quiz-results-retake-button"
                >
                  <Link
                    to="/user/home/quizSelection"
                    state={{ data: location.state.data }}
                    style={{ textDecoration: "none" }}
                  >
                    <ReusableButton
                      size="medium"
                      variant="contained"
                      className="candidateDltepageDeleteBttn"
                      buttonName="Retake"
                      startIcon={<ReplayIcon />}
                      style={{
                        backgroundColor: "#fb2485",
                        marginRight: "10px",
                        // marginBottom:"25px",
                        fontSize: "16px",
                        fontWeight: "normal",
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAalign: "center",
                        color: "#fff",
                        padding: "8px 28px",
                        borderRadius: "4px",
                        textTransform: "none",
                      }}
                      //  onClick={()=>setQuestIndex(questIndex+1)}
                    ></ReusableButton>
                  </Link>
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
