import Input from "./input";
import Dropdown from "./Dropdown";
import DatePicker from "./DatePicker";
import Phone from "./phone";
import Button from "./Button";
import LinkField from "./LinkField";
import SearchDropdown from "./SearchDropdown";
import Dropdown1 from "./Dropdown1";
import { ReusableInputfield } from "./inputfield/inputfield";
import Dropdown2 from "./Dropdown2";
import Dropdown3 from "./Dropdown3";

export const Custom = {
  Input,
  Dropdown,
  DatePicker,
  Phone,
  Button,
  LinkField,
  Dropdown1,
  SearchDropdown,
  ReusableInputfield,
  Dropdown2,
  Dropdown3,
};
