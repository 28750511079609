import React, { useState, useEffect } from 'react';
import { DataTable } from '../../../components/reusable-datagrid/reusable-datgrid';
import { Grid, Box, Paper, Typography } from '@material-ui/core';
import ReusableButton from '../../../components/reusable-button/reusable-button';
import { ReusableCards } from '../../../components/reusable-cards/reusable-cards';
// import { DataTable } from '../../../components/reusable-datagrid/reusable-datgrid';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import axios from 'axios';
import { Custom } from '../../../components/Custom/Custom';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ReusableInputfield } from '../../../components/Custom/inputfield/inputfield';
import { MyForm, Form } from '../../../components/MyForm';
import EditIcon from '@mui/icons-material/Edit';
import Notification from '../../../components/Custom/Notification';
import './edits-products.css';
import { makeStyles } from '@material-ui/core';
import API from '../../../api/index';

const api1 = API.Api1;
const api2 = API.Api2;
const styles = makeStyles((theme) => ({
  inputStyle: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '4px',
      marginTop: '8px',
    },
  },
}));
const initialValues = {
  product_name: '',
  product_credits: '',
  product_image: '',
  product_id: '',
  product_status: '',
};

const SheetJSFT = ['jpg', 'png', 'jpeg']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
const inputErrorMsg = {
  product_credits: '',
  product_name: '',
  product_logo: '',
};

const inputFieldErrorStatus = {
  product_name: false,
  product_credits: false,
  product_logo: false,
};

export default function EditsProduct(props) {
  const classes = styles();
  const [products, setProducts] = useState({});
  const { open, id, handleClose, setProductData, productData, getProductData } =
    props;
    const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
    const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
    
  // const [productData,setProductData] = useState(initialValues);
  // console.log(props,"props");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  // const handleOnChange = (e) => {
  //   setProducts({ ...user, [e.target.name]: e.target.value });
  // };

  useEffect(() => {
    getProductById(id);
  }, [id,open]);

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ('productName' in inputValues)
      code.productName = inputValues.productName
        ? ''
        : 'This Field is required';

    if ('productCredits' in inputValues)
      code.productCredits = inputValues.productCredits
        ? ''
        : 'This Field is required';

    // if ('productImage' in inputValues)
    // code.productImage = inputValues.productImage ? '' : 'Image is required';
    setErrors({
      ...code,
    });
    if (inputValues === values)
      //console.log(values,"values")
      return Object.values(code).every((x) => x === '');
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const getProductById = async (id) => {
    console.log(id, 'responseEdit');
    const response = await axios.get(`${api1}/admin/products/${id}`);
    const data = response;
    const finalData = data.data.data[0].product;
    console.log(finalData, 'finaldata');
    setProducts({
      ...products,
      product_name: finalData[0].product_name,
      product_logo: finalData[0].product_logo,
      product_credits: finalData[0].product_credits,
      product_pk: finalData[0].product_pk,
      product_status: finalData[0].product_status,
    });
 
  };
  // console.log(productData,"productData")

  const handleOnChangeOne = (e) => {
    //console.log(e.target.value,"handlechange")
    setProducts({ ...products, product_name: e.target.value });
  };
  const handleOnChangeTwo = (e) => {
    //console.log(e.target.value,"handlechange")
    setProducts({ ...products, product_credits: e.target.value });
  };
  const handleProductImageChange = (e) => {
    setProducts({ ...products, product_logo: e.target.files[0] });
    //console.log('image', e.target.files[0]);
  };
  // const onSubmitEdit = async (id) => {
  //   if (validate()) {
  //     handleClose();
  //   }
  // };
  const onSubmitEdit = async (e) => {
    // e.preventDefault();
    //console.log(values.productCredits, 'credits');
    //console.log(fileMerge, 'image');
    //console.log(values.productName, 'name');

    // if (validate()) {
    console.log(products);
    setErrorStatus({ ...errorStatus, product_credits: false });
    setErrorStatus({ ...errorStatus, product_name: false });
    setErrorStatus({ ...errorStatus, product_logo: false });
    //console.log(typeof fileMerge, 'fileMerge');
    if (products.product_name==="") {
      setErrorStatus({ ...errorStatus, product_name: true });
      setInputFieldErrors({  ...inputFieldErrors,product_name: 'Please enter a product name' });
    } else if (products.product_credits==="") {
      setErrorStatus({ ...errorStatus, product_credits: true });
      setInputFieldErrors({
        ...inputFieldErrors,
        product_credits: 'Please enter  product credits',
      });
    } 
    // else if(!products.product_logo.type){
    //   setErrorStatus({ ...errorStatus, product_logo: true });
    //   setInputFieldErrors({
    //     ...inputFieldErrors,
    //     product_logo: 'Please Add Product Image',
    //   });
    //   setNotify({
    //     isOpen: true,
    //     message: 'Please Add Product Image',
    //     type: 'error',
    //   });
    // }
    
    else {
    var fd = new FormData();
    fd.append('product_name', products.product_name);
    fd.append('logo', products.product_logo);
    fd.append('product_credits', products.product_credits);
    // formData.append('product_status',"Y");
    // Object.entries(fileMerge).map((file, index) => {
    //   //console.log(file, "Files");
    //   formData.append("logo", file[1]);
    // });

    // for (var pair of fd.entries()) {
    //   //console.log(pair[0] + ', ' + pair[1]);
    // }
    const requestOptions = {
      method: 'PUT',
      body: fd,
    };
    //console.log(requestOptions, 'requestion');
    try {
      const response = await fetch(
        `${api1}/admin/products/${products.product_pk}`,
        requestOptions
      );
      //console.log('data', response);
      const resultInJson = await response.json();
      console.log(resultInJson, 'result');
      if (resultInJson.api_status === 200) {
        handleClose();
        getProductData();
      }

      setNotify({
        isOpen: true,
        message: 'Products Updated Successfully',
        type: 'success',
      });

      // //console.log("referred");

      // const requestOptions = {
      //   method: "POST",
      //   body:formData,
      // };

      //   // const response = await fetch(
      //   //   "https://absyzloungeapi.herokuapp.com/admin/products",
      //   //   requestOptions
      //   // );
      //   // console.log(response,"responseData")
    } catch (e) {
      console.log('something went wrong!', e);
    }
    //  }
  }
  };
  return (
    <div>
      {console.log(products, 'productData')}
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <DialogTitle>Upload Products</DialogTitle>
        <DialogContent
          style={{ overflow: 'hidden', padding: '20px !important' }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2}>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <b>
                    <label style={{ marginLeft: 15, fontSize: 15 }}>
                      Product Name
                    </label>
                  </b>
                  <ReusableInputfield
                    name="productName"
                    type="text"
                    className={classes.inputStyle}
                    // label={'Product Name'}
                    value={products.product_name}
                    placeholder="Product Name"
                    size="small"
                    onChange={handleOnChangeOne}
                    error={errorStatus.product_name}
                    helperText={inputFieldErrors.product_name}
                  />
                </div>
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <b>
                    <label style={{ marginLeft: '15px' }}>Upload Image</label>
                  </b>
                  {console.log(products.product_logo,"productsTesting")}
                  <input
                    type="file"
                    name="productImage"
                    className="upload-box2"
                    accept={SheetJSFT}
                  // value={}
                    style={{
                      height: '44px',
                      marginTop: '6px',
                      fontSize: '0.8em',
                      fontFamily: 'Poppins',
                      width: '330px',
                    }}
                    onChange={handleProductImageChange}
                    // error={errorStatus}
                    helperText={inputFieldErrors.product_logo}
                  />
                </div>
                {/* {errorStatus ? (
                                    <p className="error">{`*${errorMessage}`}</p>
                                  ) : (
                                    <p className="error2">
                                      Only .png, .jpg and .jpeg .docx .pdf
                                      format allowed!
                                    </p>
                                  )} */}
              </Grid>
              <Grid item xs={1}></Grid>
              <Grid item xs={5}>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <b>
                    <label
                      style={{
                        marginLeft: 15,
                        fontSize: 15,
                        paddingBottom: 20,
                      }}
                    >
                      Product Credits
                    </label>
                  </b>
                  <ReusableInputfield
                    type="number"
                    name="productCredits"
                    value={products.product_credits}
                    placeholder="Product Credits"
                    size="small"
                    className={classes.inputStyle}
                    onChange={handleOnChangeTwo}
                    error={errorStatus.product_credits}
                    helperText={inputFieldErrors.product_credits}
                  />
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
        <DialogActions>
          <ReusableButton
            onClick={()=>{handleClose()
              setInputFieldErrors(inputErrorMsg);
              setErrorStatus(inputFieldErrorStatus);
            }}
            size="large"
            variant="outlined"
            buttonName="Cancel"
            style={{
              marginRight: '10px',
              color: '#000936',
              border: '1px solid #000936',
              marginBottom: '15px',
              borderRadius: 4,
            }}
          ></ReusableButton>

          <ReusableButton
            onClick={() => onSubmitEdit(id)}
            type="submit"
            size="large"
            variant="contained"
            buttonName="Update"
            style={{
              backgroundColor: '#fb2485',
              marginRight: '30px',
              marginBottom: '15px',
              borderRadius: 4,
              padding: '8px 36px',
            }}
          ></ReusableButton>
        </DialogActions>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
