import React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { CardHeader } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardActions from "@mui/material/CardActions";
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
export const ReusableCardsUser = ({
  className,
  content,
  style,
  title,
  height,
  image,
  alt,
  action,
  imgW,
  h,
  w,
}) => {
  return (
    <>
      <Card className={`${className}`} style={style}>
        <CardHeader
          title={title}
          style={{
            padding: 0,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        />
        <Grid sx={12} style={{width:"100%",display:"flex",justifyContent:"center"}}>
        <Box
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            width: imgW,
           
          }}
        >
          <CardMedia
            style={{
              display: "flex",
              // marginLeft: "120px",
       
              // height: h,
              alignItems: "center",
              justifyContent: "center",
            }}
            component="img"
            // height={230}
            // weight={weight}
            image={image}
            alt={alt}
          />
        </Box>
        </Grid>

        <CardContent
          style={{
            padding: 0,
            marginTop: "10px",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          {content}
        </CardContent>
        <CardActions style={{ padding: 0 }}>{action}</CardActions>
      </Card>
    </>
  );
};
