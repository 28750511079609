import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { Grid, Box, Typography, Button } from '@mui/material';
import { TiTick } from 'react-icons/ti';

import { Checkmark } from 'react-checkmark';
import './style.css';
function QuizLayout(props) {
  const { qnum, color, ans,visit } = props;
  return (
    <div
      className="rect"
      style={ans && visit ? {backgroundColor:'#fb2485'}:ans ? { backgroundColor: 'white' } : { backgroundColor: color }}
    >
      {ans ? <div className='sup'><Checkmark size="small" color="#2087ff" /></div> : null}
      <span className="txt" style={ans && visit ? {color:'#fff'}:ans ? { color: 'black' } : null}>
        {qnum}
      </span>
    </div>
  );
}

export default QuizLayout;
{/* <span class="bi bi-check-circle-fill"></span> */}