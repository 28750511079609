import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import { ReusableInputfield } from "../../../components/Custom/inputfield/inputfield";
import ReusableButton from "../../../components/Custom/button/button";
import { ReusableRememberMe } from "../../../components/Custom/remember-me/remember-me";
import { blue } from "@mui/material/colors";
import AdminBg from "../../../assets/images/adminLoginPageBg.png";
import AdminContentPic from "../../../assets/images/adminLoginPageContentpic.png";
import Rectangle from "../../../assets/images/Rectangle.png";
import "./admin-login.css";
import Notification from "../../../components/Custom/Notification";
import { useNavigate } from "react-router-dom";

import Cookies from "js-cookie";
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;

export const AdminLogin = () => {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [usernameError, setUsernameError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [usernameErrorMsg, setUsernameErrorMsg] = useState("");
  const [passwordErrorMsg, setPasswordErrorMsg] = useState("");
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.checkbox && localStorage.username !== "") {
      // setIsChecked(true)
      //  setUsername(localStorage.username)
      // setPassword(localStorage.password)
      // console.log(localStorage.username,"username")
      // console.log(localStorage.password,"password")
      // console.log(localStorage.checkbox)
    }
  });
  const handleSubmit = async (event) => {
    setUsernameError(false);
    setPasswordError(false);
    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    var passwordValidation =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;
    //console.log(emailValue, 'input VALUE gIVEN');
    //console.log(inputFieldValues.password, 'input VALUE gIVEN');

    if (emailValidation.test(username) === false) {
      setUsernameError(true);
      setUsernameErrorMsg("Enter Correct Email-ID");
    } else if (passwordValidation.test(password) === false) {
      setPasswordError(true);
      setPasswordErrorMsg("Enter Correct Password");
    } else {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        //body: JSON.stringify(getDesignation1),
        body: JSON.stringify({
          email: username,
          password: password,
        }),
      };
      const response = await fetch(`${api1}/admin/login`, requestOptions);
      const onSubmitSuccess = () => {
        Cookies.set("admin_token", "adminToken", {
          expires: 30,
        });
      };

      const data = await response.json();
      console.log(data, "datavalue");
      if (data.api_status === 200) {
        console.log("success");
        // const jwtToken = data.data[0].jwttoken;
        const userdetails = data.data[0].userdetails;

        // const timer = setTimeout(() => {

        navigate("/adminhome/");

        if (isChecked === true && username !== "") {
          localStorage.username = username;
          localStorage.password = password;
          localStorage.checkbox = isChecked;
        }
        if (response.ok === true) {
          onSubmitSuccess();
        } else {
          //console.log('check the response');
        }
      } else {
        setNotify({
          isOpen: true,
          message: "Login Failed",
          type: "error",
        });
        setUsernameError(true);
        setUsernameErrorMsg("Please Enter Admin Email-ID");
        // if (data.error[0].error_msg === 'please register ') {
        //   setErrorStatus({ ...errorStatus, emailStatus: true });
        //   setInputFieldErrors({ forEmail: data.error[0].error_msg });
        // } else {
        //   setErrorStatus({ ...errorStatus, passwordStatus: true });
        //   setInputFieldErrors({
        //     ...inputFieldErrors,
        //     forPassword: data.error[0].error_msg,
        //   });
        // }
      }
    }
    // if(username === "" || username !== "admin@absyz.com"){
    //   setUsernameError(true)
    //   setUsernameErrorMsg("Enter Correct Email-ID")
    // }
    // else if(password === "" || password !== "admin"){
    //   setPasswordError(true)
    //   setPasswordErrorMsg("Enter Correct Password")
    // }
    // else if (username === "admin@absyz.com" && password === "admin") {
    //   // console.log("login successfully");
    //   //alert("login successfully")
    //   Cookies.set('admin_token', "adminToken", {
    //     expires: 30,
    //   });
    //   navigate("/adminhome/")
    //   setUsernameError(false)
    //   setPasswordError(false)
    //   setPasswordErrorMsg(null)
    //   setUsernameErrorMsg(null)
    //   if (isChecked === true && username !== "") {
    //     // console.log("inside second if")
    //     // console.log(isChecked,"isChecked")
    //     // console.log(username,"username")
    //     // console.log(password,"password")
    //     localStorage.username = username;
    //     localStorage.password = password;
    //     localStorage.checkbox = isChecked;
    //   }
    // }
    // else {
    //   // console.log("hit fail");
    //   // alert("incorrent")
    // }
  };

  const handleTextChange = (event, value) => {
    // console.log("values", event.target.value);

    if (value == "username") {
      setUsername(event.target.value);
    } else {
      setPassword(event.target.value);
    }
  };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
    // console.log(isChecked,"isChecked")
  };

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        style={{
          height: "100%",
          zIndex: 1,
          position: "absolute",
          width: "100%",
        }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: "7%", Width: "100%", borderRadius: 0 }}
          >
            <CardContent sx={{ padding: "33px 33px 33px 0px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={Rectangle}
                      alt="AdminContentPic"
                      width="370"
                      height="390"
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <span className="adminLoginHeadingOne">Welcome!</span>
                    <span
                      className="adminLoginHeadingTwo"
                      style={{ marginTop: "9px" }}
                    >
                      ABSYZ Lounge
                    </span>
                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: "17px" }}
                    >
                      Admin Login
                    </span>
                  </Box>

                  <Box sx={{ marginTop: 2 }}>
                    <Stack direction="column" spacing={3}>
                      <ReusableInputfield
                        variant="outlined"
                        name="email"
                        margin="normal"
                        required
                        value={username}
                        //id="email"
                        id="outlined-basic"
                        fullWidth
                        label="E-mail"
                        onChange={(e) => handleTextChange(e, "username")}
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        autoFocus
                        error={usernameError}
                        helperText={usernameErrorMsg}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={password}
                        onChange={(e) => handleTextChange(e, "password")}
                        id="password"
                        // id="outlined-basic"
                        autoComplete="off"
                        inputProps={{
                          autoComplete: "off",
                        }}
                        error={passwordError}
                        helperText={passwordErrorMsg}
                      />

                      <Stack
                        direction="row"
                        spacing={2}
                        style={{
                          margin: 0,
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <ReusableRememberMe
                            color="default"
                            size="small"
                            name="lsRememberMe"
                            checked={isChecked}
                            onChange={onChangeCheckbox}
                          />
                          <label
                            style={{
                              margin: 0,
                              display: "flex",
                              alignItems: "center",
                            }}
                            className="loginFieldText"
                          >
                            Remember Me
                          </label>
                        </Stack>
                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            href="/admin/forgotPassword"
                            underline="none"
                            style={{
                              fontSize: "0.8em",
                              color: "#717171",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                            }}
                            className="loginFieldText"
                          >
                            Forgot Password?
                          </Link>
                        </div> */}
                      </Stack>
                    </Stack>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        paddingTop: "15px",
                      }}
                    >
                      <ReusableButton
                        size="medium"
                        variant="contained"
                        type="submit"
                        className="adminLoginButton"
                        buttonName="Login Now"
                        style={{
                          backgroundColor: "#fe1c7f",
                          fontFamily: "Poppins",
                          fontSize: "0.8em",
                          fontWeight: 200,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          color: "#fff",
                          padding: "12px 35px 12px 35px",
                          borderRadius: "4px",
                        }}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};
