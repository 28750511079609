import React, { useState, useEffect } from "react";
// import { DataTable } from '../../../components/reusable-datagrid/reusable-datgrid';
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
// import { ReusableCardsUser } from '../../../components/reusable-cards/reusable-cards';
import { ReusableCards } from "../../../components/Custom/cards/cards";
import { DataTable } from "../../../components/Custom/datagrid/datagrid";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import axios from "axios";
import { Custom } from "../../../components/Custom/Custom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MyForm, Form } from "../../../components/MyForm";
import IconButton from "@mui/material/IconButton";
import ReactLoading from "react-loading";
import EditIcon from "@mui/icons-material/Edit";
import "./candidate-count.css";
import Editpopup from "./edit-popup";
import Notification from "../../../components/Custom/Notification";
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;
const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  department: "",
  designation: "",
  phone: "",
  dateOfJoin: new Date(),
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const CandidatesList = (props) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [editId, setEditId] = useState();
  const [id, setId] = useState("");
  const [openEdit, setOpenEdit] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [dataid, setdataid] = useState();
  const [openPopup, setOpenPopup] = useState(false);
  const [checked, setChecked] = React.useState(true);
  const [done, setDone] = useState(undefined);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [post, setPost] = React.useState(null);
  const [onboardingCandidates, setOnboardingCandidates] = useState("");
  const [registeredCandidates, setRegisteredCandidates] = useState("");
  const [loadingTable, setLoadingTable] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [datas, setDatas] = useState({
    name: "",
    link: "",
    status: "",
  });
  // const handleClickOpen = () => {
  //   setOpen(true);
  // };
  // const handleClose = () => {
  //   setOpen(false);
  // };

  useEffect(() => {
    // fetch('https://abjsonserver.herokuapp.com/category')
    //   .then((data) => data.json())
    //   .then((data) => setTableData(data));
    getIdValue();
  }, []);
  // console.log(tableData, 'tabledata');
  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("firstname" in inputValues)
      code.firstname = inputValues.firstname ? "" : "First Name is required";
    if ("email" in inputValues)
      code.email =
        inputValues.email && /$^|.+@.+..+/.test(inputValues.email)
          ? userEmailExists(inputValues.email)
            ? "User email already exists"
            : ""
          : "Email is not valid";
    if ("phone" in inputValues)
      code.phone =
        inputValues.phone.length > 9
          ? userPhoneExists(inputValues.phone)
            ? "User already exists"
            : ""
          : "Minimum 10 numbers required";
    if ("department" in inputValues)
      code.department =
        inputValues.department.length !== 0 ? "" : "Department is required";
    if ("designation" in inputValues)
      code.designation = inputValues.designation
        ? ""
        : "Designation is required";
    if ("lastname" in inputValues)
      code.lastname = inputValues.lastname ? "" : "Last Name is required";
    setErrors({
      ...code,
    });
    //console.log(inputValues);
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    validateOnEdit,
  } = MyForm(initialValues, true, validate);
  const userEmailExists = (email) => {
    return tableData.some(function (el) {
      return el.email === email;
    });
  };

  const handleInputChangeNumber = (e) => {
    // console.log(values)
    const { name } = e.target;
    const value = e.target.value.replace(/\D/g, "");

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
  };

  const userPhoneExists = (phone) => {
    return tableData.some(function (el) {
      return el.phone == phone;
    });
  };
  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      editable: true,
      width: 30,
      borderRadius: "14px 0px 0px 0px",
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "center",
    },
    {
      field: "firstname",
      headerName: "Candidate Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
      // valueGetter: (params) =>
      // `${params.getValue("firstname") || ""} ${
      //   params.getValue("lastname") || ""
      // }`
    },
    {
      field: "department",
      headerName: "Department",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "designation",
      headerName: "Designation",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "dateOfJoin",
      headerName: "DOJ",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "Status",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <IconButton aria-label="edit" size="small">
              <EditIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  //fontSize: '14px !important',
                  color: "blue !important",
                }}
                onClick={() => handleEdit(params.id)}
              />
            </IconButton>
          </span>
          <span
          //style={{ display: 'flex', justifyContent: 'start !important' }}
          >
            <IconButton aria-label="delete" size="small">
              {" "}
              <DeleteOutlineIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  //fontSize: '14px !important',
                }}
                onClick={() => handleClickOpenDelModal(params.id)}
              />
            </IconButton>

            <Dialog
              open={openModal}
              className="dialogBox"
              // TransitionComponent={Transition}
              keepMounted
              onClose={handleCloseByCancelModal}
              aria-describedby="alert-dialog-slide-description"
              BackdropProps={{
                style: {
                  background: "#00000022",
                  boxShadow: "0px 0px 0px 0px !important",
                },
              }}
              //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
            >
              <DialogTitle></DialogTitle>
              <DialogContent
                //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText
                  id="alert-dialog-slide-description"
                  style={{ color: "#000" }}
                >
                  Are you sure you want to delete the user?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="candidateDltepageCancelBttn"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "8px",
                    // marginBottom:"25px",
                    textTransform: "none",
                  }}
                  onClick={handleCloseByCancelModal}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="candidateDltepageDeleteBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fb2485",
                    marginRight: "10px",
                    // marginBottom:"25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 28px",
                    borderRadius: "4px",
                    textTransform: "none",
                  }}
                  onClick={() => handleDelete(id)}
                >
                  Delete
                </ReusableButton>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const handleChange = (e) => {
    const value = e.target.value;
    //console.log(value);
    setDatas({
      ...datas,
      [e.target.name]: value,
    });
    setChecked(e.target.checked);
  };
  const handleClickOpenDelModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const handleClickOpen = () => {
    setOpen(true);
    getDepartments();
  };

  const handleEdit = (id) => {
    setOpenEdit(true);
    getDepartments();
    setEditId(id);
  };

  const handleEditClose = () => {
    setOpenEdit(false);
  };

  const handleClose = () => {
    //     {(validate() === true)?
    // setOpen(true)
    // :null
    // console.log("insdie handleclose")}
    setOpen(true);
  };
  const handleCloseByCancel = () => {
    resetForm();

    setOpen(false);
    // console.log('insdie handleclose');
  };
  const getDepartments = async () => {
    await axios({
      method: "get",
      url: `${api1}/admin/departments/`,
      headers: { token: `qwerty`, "Content-Type": "application/json" },
    }).then((response) => {
      //console.log(response.data.data[0].departments)
      const getDepartmentCollection = response.data.data[0].departments;

      const newData = [];
      for (var i = 0; i < getDepartmentCollection.length; i++) {
        //console.log(getDepartmentCollection[i]);
        const dept = {
          id: i,
          title: getDepartmentCollection[i].dp_name,
          dp_pk: getDepartmentCollection[i].dp_pk,
        };
        newData.push(dept);
      }
      //  console.log(newData);
      setDepartments(newData);
    });
  };
  // const getDepartments = async () => {
  //   await axios({
  //     method: 'get',
  //     url: `${api1}/admin/departments/`,
  //     headers: { token: `qwerty`, 'Content-Type': 'application/json' },
  //   })
  //     .then(response => {
  //       console.log(response.data.data[0].departments)
  //       const getDepartmentCollection = response.data.data[0].departments
  //       console.log(getDepartmentCollection)
  //       setDepartments(getDepartmentCollection)
  //     })
  // }
  const getIdValue = async () => {
    await axios({
      method: "post",
      url: `${api1}/admin/userdetails/all/users`,
      headers: { token: `qwerty`, "Content-Type": "application/json" },
    })
      .then(function (response) {
        console.log(response, "hjjkhj");
        if (response.data.api_status === 200) {
          const data = response.data.data.payload;
          const filter = response.data.data.filter;
          // console.log('data', data);
          // console.log('data length', filter);
          setOnboardingCandidates(data.length);
          setRegisteredCandidates(filter["Registered Candidates"]);
          //getDepartments()
          const updatedData = data.map((items, index) => ({
            sno: index + 1,
            id: items.userpk,
            firstname: items.firstname,
            department: items.department, //departments[parseInt(items.department)].title,
            designation: items.designation,
            email: items.email,
            dateOfJoin: items.doj,
            phone: items.phone,
            Status: items.status ? "Registered" : "Not Registered",
          }));
          setTableData(updatedData);
          setDone(true);
          setLoadingTable(false);
        }
        //console.log("axios success", response.data.payload);
        //setTableData(response.data.payload);
        //console.log()
      })
      .catch(function (error) {
        // console.log('axios error', error);
      });
  };

  const handleDelete = async (paramsId) => {
    const result = await axios.delete(`${api1}/admin/userdetails/${paramsId}`);

    console.log(result, "resssssssssssssssssssssssssssss");
    if (result.data.api_status === 200) {
      getIdValue();
      setOpenModal(false);
      setNotify({
        isOpen: true,
        message: "Candidate Deleted Successfully",
        type: "success",
      });
    } else {
      getIdValue();
      setOpenModal(false);
      setNotify({
        isOpen: true,
        message: "Candidate took quizzes cannot be removed",
        type: "error",
      });
    }
    // getIdValue();
    // setOpenModal(false);
    // setNotify({
    //   isOpen: true,
    //   message: 'Candidate deleted successfully',
    //   type: 'success',
    // });

    // const requestOptions = {
    //   method: 'GET',
    //   headers: { token: 'qwerty' },
    // };
    // fetch(
    //   `${api1}/admin/userdetails/${paramsId}`,
    //   requestOptions
    // ).then((response) => {
    //   console.log(response, 'responseofdeleteuser');
    //   const result =  response.json();
    //   console.log(result,"responseofdeleteuserresponseofdeleteuser")
    // if (response.status === 200) {
    //   getIdValue();
    //   setOpenModal(false);
    //   setNotify({
    //     isOpen: true,
    //     message: 'Candidate Deleted Successfully',
    //     type: 'success',
    //   });
    // } else {
    //   getIdValue();
    //   setOpenModal(false);
    //   setNotify({
    //     isOpen: true,
    //     message: 'Candidate Not Deleted Please Try Again',
    //     type: 'error',
    //   });
    // }
    // });
  };

  function convert(dateOfJoin) {
    var date = new Date(dateOfJoin),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }
  // console.log(
  //   convert('Thu Jun 09 2011 00:00:00 GMT+0530 (India Standard Time)')
  // );
  // console.log(dateOfJoinValue);
  // console.log(props)

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setOpen(false);
      setLoadingTable(true);
      const date = convert(values.dateOfJoin);

      const newData = {
        firstname: values.firstname,
        lastname: values.lastname,
        email: values.email,
        department: values.department,
        //values.department,
        designation: values.designation,
        // getDesignation[values.designation - 1].title,
        phone: values.phone,
        doj: date,
      };
      // console.log('handle');
      // console.log(newData,"posteddata");
      const headers = {
        token: "qwerty",
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const config = { headers };
      try {
        const response = await axios.put(
          `${api1}/admin/userdetails/all/users`,
          newData,
          config
        );
        console.log(response, "response of users");
        if (response.status === 200) {
          setLoadingTable(false);
          setNotify({
            isOpen: true,
            message: "Candidate added successfully",
            type: "success",
          });
          const username = `${newData.firstname} ${newData.lastname}`;
          const emailRecp = newData.email;
          const payload = {
            username: username,
            email: emailRecp,
          };
          const headers = {
            "Content-Type": "application/json",
          };
          const config = headers;
          const res = await axios.post(`${api1}/adduser`, payload, config);
          // console.log(res.status, 'response of mail');
        }

        // console.log(response.data);
      } catch (e) {
        // console.log('something went wrong!', e);
        setNotify({
          isOpen: true,
          message: "Candidate cannot be added",
          type: "error",
        });
      }
      getIdValue();
      resetForm();
    }
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box
          component="main"
          className="adminslayout"
          sx={{
            flexGrow: 1,
            padding: "0px 30px 0px 30px !important",
            marginBottom: "220px",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={2} style={{ padding: "30px" }}>
              <Grid item xs={2}></Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "start",
                  alignItems: "center",
                  // paddingRight: '25px',
                }}
              >
                <ReusableCards
                  className="onboardCandidates"
                  // sx={{ minHeight: 55 }}
                  text={"Onboarding Candidates"}
                  value={onboardingCandidates}
                  style={{
                    minHeight: 55,
                    minWidth: "95%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  // paddingLeft: '30px',

                  // paddingRight: '20px',
                }}
              >
                <ReusableCards
                  className="registeredcand"
                  // sx={{ minHeight: 55 }}
                  text={"Registered Candidates"}
                  value={`${registeredCandidates}/${onboardingCandidates}`}
                  style={{
                    minHeight: 55,
                    minWidth: "95%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    borderRadius: "10px",
                  }}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Box>
          <Box sx={{ flexGrow: 1 }} style={{ marginTop: "10px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                style={{ textAlign: "end", paddingRight: "80px" }}
              >
                <h4
                  style={{
                    fontSize: "1.3750em",
                    fontFamily: "Poppins",
                    color: "white",
                    fontStretch: "normal",
                    letterSpacing: "normal",
                  }}
                >
                  Candidate Onboarding
                </h4>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  textAlign: "end",
                  margin: "auto",
                  paddingRight: "10px",
                }}
              >
                {/* <ReusableButton
              size="small"
              style={{ backgroundColor: 'white', color: 'black', textTransform: 'none', padding: '3px 9px',borderRadius:'5px' }}
              variant="outlined"
              onClick={() => setOpenPopup(true)}
              startIcon={<PersonAddIcon />}
              buttonName="Add Candidate"
            /> */}
                <ReusableButton
                  size="small"
                  className="addCandidateBtn"
                  style={{
                    backgroundColor: "white",
                    color: "#000936",
                    textTransform: "none ",
                    padding: "6px 16px",
                    borderRadius: "4px",
                  }}
                  variant="contained !important"
                  onClick={handleClickOpen}
                  startIcon={<PersonAddIcon />}
                  buttonName="Add Candidate"
                />
              </Grid>
            </Grid>
          </Box>

          {loadingTable ? (
            <Box
              sx={{ flexGrow: 1 }}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
              }}
            >
              <ReactLoading
                type={"spinningBubbles"}
                color={"#fb2485"}
                height={100}
                width={100}
              />
            </Box>
          ) : (
            <div
              style={{
                // width: '90%',
                textAlign: "center",
                backgroundColor: "white",
                // marginLeft: '100px',
                // marginRight: '100px',
                borderRadius: "15px",
                marginBottom: "20px",
              }}
            >
              {/* <div style={{marginBottom:'20px',}}>
        <DataTable rows={tableData} columns={columns} pageSize={10} rowHeight={40} autoHeight width="100%" style={{marginBottom:'20px'}} />
        </div> */}
              <Box
                sx={{
                  width: "100%",
                  "& .super-app-theme--header": {
                    backgroundColor: "#f1f1f1",
                    color: "#02215b",
                    fontSize: "16px",
                    paddingBottom: "20px",
                  },
                }}
              >
                <DataTable
                  rows={tableData}
                  columns={columns}
                  pageSize={10}
                  rowHeight={40}
                  rowsPerPageOptions={[10]}
                  autoHeight
                  width="100%"
                  //style={{ marginBottom: '120px'  }}
                  className="dataTableCandidatePage"
                  hideFooterSelectedRowCount={true}
                />
              </Box>
            </div>
          )}
          {/* <MyDialog
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        title="Create New Candidate Record"
        style={{ fontFamily: 'Poppins',fontSize:'24px', marginBottom:'10px',width:'725px',height:'496px',}}
      >
        <CandidateForm />
        <Custom.Button
          // color="outlined"
          variant="outlined"
          text="Cancel"
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginTop: -80,
            // marginLeft: 300,
            marginRight: 30,
            color: '#000936',
            //  backgroundColor: '#000936' 
            bordor: '#000936',
            // paddingBottom: 20          
          }}
          onClick={() => setOpenPopup(false)}
        ></Custom.Button>
      </MyDialog> */}

          <Dialog
            open={open}
            onClose={handleClose}
            style={{ padding: "16px", overflowY: "hidden !important" }}
          >
            <Form onSubmit={handleSubmit}>
              <DialogTitle>Create New Candidate Record</DialogTitle>
              <DialogContent>
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                        >
                          First Name
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Input
                            name="firstname"
                            placeholder="First Name"
                            value={values.firstname}
                            onChange={handleInputChange}
                            error={errors.firstname}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Last Name
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Input
                            name="lastname"
                            placeholder="Last Name"
                            onChange={handleInputChange}
                            value={values.lastname}
                            error={errors.lastname}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Email Address
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Input
                            name="email"
                            value={values.email}
                            placeholder="example@email.com"
                            onChange={handleInputChange}
                            error={errors.email}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Department
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Dropdown
                            name="department"
                            label="Select"
                            value={values.department}
                            onChange={handleInputChange}
                            options={departments}
                            error={errors.department}
                            size="small"
                            style={{
                              height: 44,
                              alignItems: "center",
                              display: "flex",
                            }}
                          ></Custom.Dropdown>
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Designation
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          {/* <Custom.Dropdown
                        name="designation"
                        label="Select"
                        value={values.designation}
                        onChange={handleInputChange}
                        options={getDesignation}
                        error={errors.designation}
                        size="small"
                        style={{ height: 44 }}
                      ></Custom.Dropdown> */}
                          <Custom.Input
                            name="designation"
                            placeholder="Designation"
                            onChange={handleInputChange}
                            value={values.designation}
                            error={errors.designation}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Phone Number
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Phone
                            name="phone"
                            label="0000000000"
                            value={values.phone}
                            onChange={handleInputChangeNumber}
                            error={errors.phone}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid item xs={6}>
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          style={{
                            // marginLeft: 10,
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Joining Date
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.DatePicker
                            name="dateOfJoin"
                            className="common"
                            value={values.dateOfJoin}
                            onChange={handleInputChange}
                            size="small"
                            sx={{ width: "245px !important" }}
                          />
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  onClick={handleCloseByCancel}
                  size="large"
                  variant="outlined"
                  buttonName="Cancel"
                  style={{
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#000936",
                    border: "1px solid #000936",
                    padding: "7px 20px",
                    borderRadius: "4px",
                    marginRight: "10px",
                    marginBottom: "25px",
                    textTransform: "none",
                  }}
                >
                  Cancel
                </ReusableButton>
                <ReusableButton
                  className="addbutton"
                  type="submit"
                  size="large"
                  variant="contained"
                  buttonName="Add"
                  style={{
                    backgroundColor: "#fb2485",
                    marginRight: "30px",
                    marginBottom: "25px",
                    fontSize: "16px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAalign: "center",
                    color: "#fff",
                    padding: "8px 30px",
                    borderRadius: "4px",
                    textTransform: "none",
                  }}
                >
                  Add
                </ReusableButton>
              </DialogActions>
            </Form>
          </Dialog>

          <Editpopup
            open={openEdit}
            id={editId}
            handleClose={handleEditClose}
            getIdValue={getIdValue}
            departments={departments}
            setLoadingTable={setLoadingTable}
          />
          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </>
  );
};

export default CandidatesList;
