import React from 'react';
import { TextField, InputAdornment } from '@material-ui/core';
import LinkIcon from '@mui/icons-material/Link';
import InsertLinkIcon from '@mui/icons-material/InsertLink';
function LinkField(props) {
  const { name, value, label, onChange,error=null } = props;
  return (
    <TextField
   
      variant={'outlined'}
      onChange={onChange}
      type="url"
size="small"
      InputProps={{
        startAdornment: <InputAdornment position="start"> <InsertLinkIcon style={{color:"gray"}}/></InputAdornment>,
      }}
      value={value}
      placeholder={label}
      name={name}
      autoComplete='off'
       {...(error && {error:true,helperText:error})}
    />
  );
}

export default LinkField;