import { Navigate, Outlet } from "react-router-dom";
import Cookie from "js-cookie";

export const ProtectedRoute = () => {
  const token = Cookie.get("jwt_token");

  if (token === undefined) {
    return <Navigate to="/" />;
  }
  return <Outlet />;
};

export const ProtectedAdminRoute = () => {
  const token = Cookie.get("admin_token");

  if (token === undefined) {
    return <Navigate to="/adminlogin" />;
  }
  return <Outlet />;
};
