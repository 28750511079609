import React from "react";
import "./App.css";
import { AdminLogin } from "./pages/admin/admin-login/admin-login";
import QuestionsUpload from "./pages/admin/questions-upload/questions-upload";
// import {AdminNavigation} from "./pages/admin/admin-login/admin-navigation";
import AdminHomePage from "./pages/admin/admin-layout/admin-layout";
import CandidatesList from "./pages/admin/admin-layout/candidates-count";
import Absyziantalk from "./pages/admin/absyzian-talk/absyzian-talk";
import UserAbsyziantalk from "./pages/users/absyzian-talk";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Departments from "./pages/admin/departments/departments";
import { UserSignup } from "./pages/users/user-signup/user-signup";
import { UserLogin } from "./pages/users/user-login/user-login";
import UserHomePage from "./pages/users/user-layout/user-layout";
import { UserDashboard } from "./pages/users/user-dashboard/user-dashboard";
import {
  ProtectedRoute,
  ProtectedAdminRoute,
} from "./pages/users/protected-router/protected-router";
import QuizSelection from "./pages/users/quiz/quiz";
import QuizExpand from "./pages/users/quiz/quiz-expand";
import QuizQuestions from "./pages/users/quiz/quizQuestions";
import QuizResult from "./pages/users/quiz/quiz-result";
import { Lines } from "react-preloaders";
import Products from "./pages/admin/products/products";
import UserProfile from "./pages/users/user-profile/user-profile";
import UserForgotPassword from "./pages/users/user-forgot-password/user-forgot-password";
import ResetPassword from "./pages/users/resetPassword/resetPassword";
import { Navigate } from "react-router-dom";
import AuthVerify from "./components/Custom/AuthVerify.jsx";
import AdminForgotPassword from "./pages/admin/admin-forgor-password/admin-forgot-password";
function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          <Route exact path="/adminlogin" element={<AdminLogin />} />
          {/* <Route
            exact
            path="/admin/forgotPassword"
            element={<AdminForgotPassword />}
          /> */}
          <Route element={<ProtectedAdminRoute />}>
            <Route exact path="/adminhome" element={<AdminHomePage />}>
              <Route exact path="/adminhome/" element={<CandidatesList />} />
              <Route
                exact
                path="/adminhome/uploadquestion"
                element={<QuestionsUpload />}
              />
              <Route
                exact
                path="/adminhome/absyzTalk"
                element={<Absyziantalk />}
              />
              <Route
                exact
                path="/adminhome/departments"
                element={<Departments />}
              />
              <Route exact path="/adminhome/products" element={<Products />} />
              {/* <Route path="*" element={<Navigate to="/adminhome" replace />} /> */}
            </Route>
          </Route>
          {/* </Routes> */}
          {/* </Router>
      <Router> */}
          {/* <Routes> */}
          <Route exact path="/user/signup" element={<UserSignup />} />
          <Route exact path="/" element={<UserLogin />} />
          <Route
            exact
            path="/user/forgotPassword"
            element={<UserForgotPassword />}
          />
          <Route
            exact
            path="/user/reset-password"
            element={<ResetPassword />}
          />
          <Route element={<ProtectedRoute />}>
            <Route exact path="/user/home" element={<UserHomePage />}>
              <Route exact path="/user/home/" element={<UserDashboard />} />
              <Route
                exact
                path="/user/home/userProfile"
                element={<UserProfile />}
              />
              <Route
                exact
                path="/user/home/absyzianTalk"
                element={<UserAbsyziantalk />}
              />
              <Route
                exact
                path="/user/home/quizSelection"
                element={<QuizSelection />}
              />
              <Route
                exact
                path="/user/home/quizExpand"
                element={<QuizExpand />}
              />
              <Route
                exact
                path="/user/home/quizQuestions"
                element={<QuizQuestions />}
              />
              <Route
                exact
                path="/user/home/quizResult"
                element={<QuizResult />}
              />
            </Route>
            <Route path="*" element={<Navigate to="/user/home" replace />} />
          </Route>
        </Routes>
        <AuthVerify />
      </Router>
    </div>
  );
}

export default App;
