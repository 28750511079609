import {React,useState} from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {ReusableCardsUser} from '../../../../components/reusable-cards/reusable-cards';
import ReactLoading from 'react-loading';
import './earned-credits-modal.css';
import { MyForm, Form } from '../../../../components/MyForm';
export const EarnedCreditsModal = (props) => {
    const {open,handleClose,setProductData,productData} = props;
    const [done, setDone] = useState(undefined);
    // console.log(props.productData,"props")
  return (
    <>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="lg"
        // maxHeight="100vh"
        // style={{width:"100%"}}
        >
            {productData.length === 0 ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            width:"1000px",
          }}
        >
          <ReactLoading
            type={'spinningBubbles'}
            color={'#fb2485'}
            height={100}
            width={100}
          />
        </Box>
      ) :<><DialogTitle id="alert-dialog-title" className="dialog-title-div">
      <Box
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <span className="modal-title">Goodies bank</span>
        <span className="modal-subtitle">
          Collect these amazing goodies on your day 1 at ABSYZ
        </span>
      </Box>
    </DialogTitle>
<Form>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <Box sx={{ flexGrow: 1,padding:"20px 45px" }}>
          <Grid container spacing={2}>
            {productData.map((items,value)=>{
            return(
            <Grid item xs={12} sm={6} md={4}>
                <ReusableCardsUser className="products-cards"
                 image={items.product_logo}
                
                 alt="img"
                 w="160px"
                 h="110px"
                //  imgW="150px"
                 content={
                    <span
                      style={{
                        fontFamily: "Poppins",
                        fontSize: "1rem",
                        fontWeight: 600,
                        fontStretch: "normal",
                        fontStyle: "normal",
                        lineHeight: "normal",
                        letterSpacing: "normal",
                        textAlign: "center",
                        color: "#000",
                      }}>
                        {items.product_name}
                    </span>
                  }
                  action={<span className='credits-text'>{items.product_credits} Credits</span>}
                  />
            </Grid>
                )
            })}
          </Grid>
        </Box>
      </DialogContentText>
    </DialogContent>
    </Form>
    </>
    }
      </Dialog>
    </>
  );
}
