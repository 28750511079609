import React, { useState } from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import PersonIcon from "@mui/icons-material/Person";
import quizIcon from "../../../assets/images/quizIcon.png";
import QuizIcon from "@mui/icons-material/Quiz";
import UploadIcon from "@mui/icons-material/Upload";
import GroupIcon from "@mui/icons-material/Group";
import CampaignIcon from "@mui/icons-material/Campaign";
import CategoryIcon from "@mui/icons-material/Category";
import "./menu-items-user.css";
import { NavLink } from "react-router-dom";
import Link from "@mui/material/Link";

import Tooltip from "@mui/material/Tooltip";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
// import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import LogoutIcon from "@mui/icons-material/Logout";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { Outlet } from "react-router-dom";
import referAFriend from "../../../assets/images/referAFriend.png";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import axios from "axios";
import { Custom } from "../../../components/Custom/Custom";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { MyForm, Form } from "../../../components/MyForm";
import IconButton from "@mui/material/IconButton";
import Notification from "../../../components/Custom/Notification";
import referIcon from "../../../assets/images/referIcon.png";
import blogIcon from "../../../assets/images/blogIcon.png";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { makeStyles } from "@material-ui/core";
import API from "../../../api/index";
import { useLocation } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

const api1 = API.Api1;
const api2 = API.Api2;

const styles = makeStyles((theme) => ({
  main: {
    "& .MuiDialog-paper": {
      maxWidth: "1000px",
    },
  },
  // phoneWidth01: {
  //   "& .MuiOutlinedInput-adornedStart": {
  //     width: "283px !important",
  //   },

  //   openDrawer: {
  //     "& .MuiPaper-root": {
  //       backgroundColor: "#000936",
  //     },
  //   },
  // },
}));

const getRelation = [
  { id: "1", title: "Friend" },
  { id: "2", title: "Cousin" },
];
const getvalue = [
  { id: "1", title: "Hyderabad" },
  { id: "2", title: "Bangalore" },
];
const choices = [
  { value: "UI Developer", label: "UI Developer" },
  { value: "Salesforce Developer", label: "Salesforce Developer" },
  { value: "Front End Developer", label: "Front End Developer" },
];

const initialValues = {
  fullname: "",
  email: "",
  department: "",
  phone: "",
  referalrelation: "",
  tech: "",
  jobpos: "",
  uploadresume: "",
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const SheetJSFT = ["pdf", "doc"]
  .map(function (x) {
    return "." + x;
  })
  .join(",");

const mdTheme = createTheme();

export default function MobileMenuItemsUser(props) {
  const { active } = props;
  const classes = styles();
  const [open, setOpen] = useState(false);
  const [openReferPopup, setOpenReferPopup] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [departments, setDepartments] = useState([]);
  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [fileMerge, setFileMerge] = useState();
  const [showValues, setShowValues] = useState(false);
  const [done, setDone] = useState(undefined);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ("fullname" in inputValues)
      code.fullname = inputValues.fullname ? "" : "First Name is required";
    if ("email" in inputValues)
      code.email =
        inputValues.email && /$^|.+@.+..+/.test(inputValues.email)
          ? ""
          : "Email is not valid";
    if ("phone" in inputValues)
      code.phone =
        inputValues.phone.length > 9 ? "" : "Minimum 10 numbers required";
    if ("jobpos" in inputValues) {
      // console.log(inputValues.jobpos);
      code.jobpos =
        inputValues.jobpos.length !== 0 ? "" : "SkillSet is required";
    }
    if ("department" in inputValues)
      code.department =
        inputValues.department.length !== 0
          ? ""
          : "Current Openings is required";
    if ("tech" in inputValues)
      code.tech =
        inputValues.tech.length !== 0 ? "" : "Preferred Location is required";
    if ("referalrelation" in inputValues)
      code.referalrelation =
        inputValues.referalrelation.length !== 0
          ? ""
          : "Referal Relation is required";
    // if ('uploadresume' in inputValues)
    //   code.uploadresume = inputValues.uploadresume ? '' : 'Resume is required';
    setErrors({
      ...code,
    });
    // console.log(inputValues);
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const {
    values,
    setValues,
    handleInputChange,
    errors,
    setErrors,
    resetForm,
    handleValueChange,
  } = MyForm(initialValues, true, validate);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const navigate = useNavigate();

  const onClickLogout = () => {
    Cookies.remove("jwt_token");
    navigate("/");
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setOpenReferPopup(true);
    getDepartments();
  };

  const handleClose = () => {
    //     {(validate() === true)?
    // setOpen(true)
    // :null
    // console.log("insdie handleclose")}
    setOpenReferPopup(true);
  };

  const handleCloseByCancel = () => {
    resetForm();

    setOpenReferPopup(false);
    // console.log('insdie handleclose');
  };

  const getDepartments = async () => {
    await axios({
      method: "get",
      url: `${api1}/admin/departments/`,
      headers: { token: `qwerty`, "Content-Type": "application/json" },
    }).then((response) => {
      //console.log(response.data.data[0].departments)
      const getDepartmentCollection = response.data.data[0].departments;

      const newData = [];
      for (var i = 0; i < getDepartmentCollection.length; i++) {
        //console.log(getDepartmentCollection[i]);
        const dept = {
          id: i,
          title: getDepartmentCollection[i].dp_name,
          dp_pk: getDepartmentCollection[i].dp_pk,
        };
        newData.push(dept);
      }
      setDone(true);
      //  console.log(newData);
      setDepartments(newData);
    });
  };

  const jwtToken = Cookies.get("jwt_token");
  // console.log(jwtToken, 'jwt');

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      // console.log(typeof(fileMerge),'fileMerge');
      // //console.log(newData);
      var formData = new FormData();
      formData.append("re_fullname", values.fullname);
      formData.append("re_email", values.email);
      formData.append("current_openings", values.department);
      formData.append("re_relation", values.referalrelation);
      formData.append("re_location", values.tech);
      formData.append("other_skillset", values.jobpos);
      formData.append("re_number", values.phone);
      // console.log(fileMerge, "files")
      formData.append("resume", fileMerge);

      //   for (var pair of formData.entries()) {
      //     console.log(pair[0]+ ', ' + pair[1]);
      // }

      const headers = {
        // "token": "qwerty",
        "x-auth-token": `${jwtToken}`,
        // 'Access-Control-Allow-Origin': '*',
      };
      //  const config = { headers };
      try {
        const response = await fetch(`${api1}/user/addfrd`, {
          method: "POST",
          body: formData,
          headers: headers,
        });
        const resultInJson = await response.json();
        // console.log(resultInJson,"responseofreferafriend");
        if (resultInJson.api_status === 200) {
          // const fullname = formData.re_fullname;
          //   const number = formData.re_number;
          const url = resultInJson.data.url;
          const payload = JSON.stringify({
            re_fullname: values.fullname,
            re_number: values.phone,

            re_email: values.email,
            current_openings: values.department,
            re_relation: values.referalrelation,
            re_location: values.tech,
            other_skillset: values.jobpos,
            resume: fileMerge,
            url: url,
          });

          const headers = {
            "Content-Type": "application/json",
            "x-auth-token": `${jwtToken}`,
          };
          const config = headers;
          const res = await fetch(`${api1}/user/refer-mail`, {
            method: "POST",
            body: payload,
            headers: config,
          });
          const resultInJson2 = await res.json();
          // console.log(resultInJson2,"responseofreferafriend2");
          // console.log(res, 'response of mail');
          resetForm();
          setOpenReferPopup(false);
          setNotify({
            isOpen: true,
            message: "Referred successfully",
            type: "success",
          });
        }
        // console.log(resultInJson2);
      } catch (e) {
        // console.log('something went wrong!', e);
      }
    }
  };

  const handleChange = (e /*:File*/) => {
    // console.log("file", files.target.files);
    setFileMerge(e.target.files[0]);
    console.log("resume", e.target.files[0]);
    // console.log(files.target.files[0])
    setShowValues(true);
  };

  const menuId = "primary-search-account-menu";

  // To open and close the menu Icon
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <div className="nav">
      <ListItem style={{ padding: "0px" }}>
        <NavLink
          to="/user/home/"
          style={{ width: "100%" }}
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemText
            style={{
              color: "#FFF",
            }}
            className="mobile-view-list-text"
          >
            <ListItemIcon
              style={{
                color: "#FFF",
              }}
            >
              <Tooltip title="Home" placement="right" arrow>
                <HomeIcon />
              </Tooltip>
            </ListItemIcon>

            <span style={{ color: "#FFF" }}>Home</span>
          </ListItemText>
        </NavLink>
      </ListItem>

      <ListItem style={{ padding: "0px", marginTop: "10px" }}>
        <NavLink
          to="/user/home/userProfile"
          style={{ width: "100%" }}
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemText
            style={{ color: "#FFF" }}
            className="mobile-view-list-text"
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Profile" placement="right" arrow>
                <PersonIcon />
              </Tooltip>
            </ListItemIcon>
            <span style={{ color: "#FFF" }}>Profile</span>
          </ListItemText>
        </NavLink>
      </ListItem>

      <ListItem style={{ padding: "0px", marginTop: "10px" }}>
        <NavLink
          to="/user/home/quizSelection"
          style={{ width: "100%" }}
          className={({ isActive }) =>
            isActive ? "link-active" : active ? "link-active" : "link"
          }
        >
          <ListItemText
            style={{ color: "#FFF" }}
            className="mobile-view-list-text"
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              {/* <QuizIcon /> */}
              <Tooltip title="Quiz" placement="right" arrow>
                <img src={quizIcon} alt="quiz icon" width="26" height="28" />
              </Tooltip>
            </ListItemIcon>
            <span style={{ color: "#FFF" }}>Quiz</span>
          </ListItemText>
        </NavLink>
      </ListItem>

      <ListItem style={{ padding: "0px", marginTop: "10px" }}>
        <NavLink
          to="/user/home/absyzianTalk"
          style={{ width: "100%" }}
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemText
            style={{ color: "#FFF !important" }}
            className="mobile-view-list-text"
          >
            <ListItemIcon style={{ color: "#FFF" }}>
              <Tooltip title="Absyzian Talk" placement="right" arrow>
                <CampaignIcon />
              </Tooltip>
            </ListItemIcon>
            <span style={{ color: "#FFF" }}>Absyzian Talk</span>
          </ListItemText>
        </NavLink>
      </ListItem>
      {/* refer friend and lougout button */}
      <Box
        sx={{
          display: { xs: "flex", sm: "none", md: "none" },
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <ListItem style={{ padding: "0px", marginTop: "10px" }}>
          <Link
            href="https://absyz.com/blog/"
            style={{ textDecoration: "none" }}
            className={({ isActive }) => (isActive ? "link-active" : "link")}
          >
            <ListItemText
              style={{ color: "#FFF !important" }}
              className="mobile-view-list-text"
            >
              <ListItemIcon style={{ color: "#FFF" }}>
                <Tooltip title="blog" placement="right" arrow>
                  <img src={blogIcon} alt="blogs icon" width="23" height="23" />
                </Tooltip>
              </ListItemIcon>
              <span
                style={{
                  color: "#FFF",
                  textDecoration: "none",
                  border: "none",
                }}
              >
                Blogs
              </span>
            </ListItemText>
          </Link>
        </ListItem>

        {/* <IconButton
          className="referafriend-button"
          size="medium"
          edge="end"
          aria-label="account of current user"
          aria-controls={menuId}
          aria-haspopup="true"
          //onClick={onClickLogout}
          onClick={handleClickOpen}
          color="inherit"
          sx={{
            padding: "7px 10px 6.7px",
            borderRadius: "4px",
            backgroundColor: "#fb2485",
          }}
          {/* <GroupIcon fontSize="inherit" className="logoutbtn" /> 
          <img src={referIcon} alt="person icon" width="23" height="23" />
          <Typography style={{ paddingLeft: "7px", fontSize: "14px" }}>
            Refer A Friend
          </Typography>
        </IconButton> */}

        <ListItem style={{ padding: "0px", marginTop: "10px" }}>
          <NavLink to="#" className="link">
            <ListItemText
              style={{ color: "#FFF !important" }}
              onClick={handleClickOpen}
              className="mobile-view-list-text"
            >
              <ListItemIcon style={{ color: "#FFF" }}>
                <Tooltip title="Refer A Friend" placement="right" arrow>
                  <img
                    src={referIcon}
                    alt="person icon"
                    width="23"
                    height="23"
                  />
                </Tooltip>
              </ListItemIcon>
              <span style={{ color: "#FFF" }}>Refer A Friend</span>
            </ListItemText>
          </NavLink>
        </ListItem>

        <Dialog
          open={openReferPopup}
          onClose={handleClose}
          style={{
            padding: "0px 0px 0px 0px !important",
            overflowY: "hidden !important",
          }}
          maxWidth={maxWidth}
          fullWidth={fullWidth}
          className={classes.main}
        >
          <Form onSubmit={handleSubmit}>
            {/* <DialogTitle>Create New Candidate Record</DialogTitle> */}
            <DialogContent>
              <Box sx={{ flexGrow: 1, padding: "0px !important" }}>
                <Grid container style={{ padding: "0px !important" }}>
                  <Grid
                    item
                    xs={12}
                    style={{ display: "flex", justifyContent: "flex-end" }}
                  >
                    <CloseIcon onClick={handleCloseByCancel} />
                  </Grid>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div>
                      <img
                        src={referAFriend}
                        alt="ReferAFriendPic"
                        className="userlayout-refer-a-friend"
                      />
                    </div>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={8}
                    md={8}
                    lg={8}
                    className="userlayout-refer-a-friend-grid"
                  >
                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        className="userlayout-grid-inner"
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                        >
                          Name
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Input
                            name="fullname"
                            placeholder="Enter Full Name"
                            value={values.fullname}
                            onChange={handleInputChange}
                            error={errors.fullname}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Phone Number
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Phone
                            name="phone"
                            label="0000000000"
                            value={values.phone}
                            onChange={handleInputChange}
                            error={errors.phone}
                            size="small"
                            className={classes.phoneWidth01}
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Email Address
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Input
                            name="email"
                            value={values.email}
                            placeholder="example@email.com"
                            onChange={handleInputChange}
                            error={errors.email}
                            size="small"
                          />
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Referal Relation
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Dropdown
                            name="referalrelation"
                            label="Select Option"
                            onChange={handleInputChange}
                            options={getRelation}
                            value={values.referalrelation}
                            error={errors.referalrelation}
                            size="small"
                            style={{
                              height: 40,
                              alignItems: "center",
                              display: "flex",
                            }}
                          ></Custom.Dropdown>
                          {/*                               
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="referalrelation"
                                  // value={dp_caty_status}
                                  // label="Select Position"
                                  // label="Select option"
                                  displayEmpty
                                  value={values.referalrelation}
                                  error={errors.referalrelation}
                                  size="large"
                                  style={{
                                    height: 40,
                                    width: 260,
                                    alignItems: 'center',
                                    display: 'flex',
                                  }}
                                  autoWidth
                                  onChange={handleInputChange}
                                >
                                  <MenuItem
                                    style={{
                                      height: 40,
                                      width: 260,
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    value={'Y'}
                                  >
                                    Friend
                                  </MenuItem>
                                  <MenuItem
                                    style={{
                                      height: 40,
                                      width: 260,
                                      alignItems: 'center',
                                      display: 'flex',
                                    }}
                                    value={'N'}
                                  >
                                    Cousin
                                  </MenuItem>
                                </Select> */}
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Current Openings
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Dropdown
                            name="department"
                            label="Select Option"
                            value={values.department}
                            onChange={handleInputChange}
                            options={departments}
                            error={errors.department}
                            size="small"
                            style={{
                              height: 40,
                              alignItems: "center",
                              display: "flex",
                            }}
                          ></Custom.Dropdown>
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Other SkillSet
                          {/* <Tooltip title="Give NA if you don't find option in current openings" arrow>
  <IconButton size="small"><InfoIcon fontSize="inherit"/></IconButton>
</Tooltip> */}
                        </Typography>
                        <span style={{ marginLeft: "-7px", marginTop: "0px" }}>
                          <Custom.Input
                            name="jobpos"
                            placeholder="NA"
                            // defaultValue="NA"
                            onChange={handleInputChange}
                            value={values.jobpos}
                            error={errors.jobpos}
                            size="small"
                          />
                          {/* <Custom.SearchDropdown
                                  options={choices}
                                  label="jobpos"
                                  size="small"
                                  //  value={values.jobpos}
                                  placeholder="Search Job "
                                  style={{ width: 260 }}
                                  onChange={handleValueChange}
                                  isClearable
                                  error={errors.jobpos}
                                /> */}
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                            // marginTop:'7px'
                          }}
                          variant="h6"
                        >
                          Preferred Location
                        </Typography>
                        <span style={{ marginLeft: "-7px" }}>
                          <Custom.Dropdown
                            name="tech"
                            label="Select Option"
                            onChange={handleInputChange}
                            options={getvalue}
                            value={values.tech}
                            error={errors.tech}
                            size="small"
                            style={{
                              height: 40,
                              alignItems: "center",
                              display: "flex",
                            }}
                          ></Custom.Dropdown>
                        </span>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={10}
                      md={6}
                      lg={6}
                      className="userlayout-refer-a-friend-grid-inner"
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        <Typography
                          style={{
                            marginLeft: 0,
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontWeight: 600,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "left",
                            color: "#000",
                          }}
                          variant="h6"
                        >
                          Upload Resume
                        </Typography>
                        <span style={{ marginLeft: "0px" }}>
                          <input
                            type="file"
                            name="uploadresume"
                            className="upload-box1"
                            accept={SheetJSFT}
                            style={{
                              height: 40,
                              marginTop: "8px",
                              fontSize: "0.8em",
                              fontFamily: "Poppins",
                            }}
                            onChange={handleChange}
                          />
                        </span>
                      </div>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      className="userlayout-last-buttons"
                      // style={{
                      //   display: 'flex',
                      //   justifyContent: 'flex-end',
                      //   marginTop: '10px',
                      // }}
                    >
                      <ReusableButton
                        onClick={handleCloseByCancel}
                        size="large"
                        variant="outlined"
                        buttonName="Cancel"
                        style={{
                          fontSize: "16px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAalign: "center",
                          color: "#000936",
                          border: "1px solid #000936",
                          padding: "4px 20px",
                          borderRadius: "4px",
                          marginRight: "10px",
                          marginBottom: "25px",
                          textTransform: "none",
                          height: "42px",
                        }}
                      >
                        Cancel
                      </ReusableButton>
                      <ReusableButton
                        onClick={handleClose}
                        className="addbutton"
                        type="submit"
                        size="large"
                        variant="contained"
                        buttonName="Refer Now"
                        style={{
                          backgroundColor: "#fb2485",
                          marginRight: "20px",
                          marginBottom: "25px",
                          fontSize: "16px",
                          fontWeight: "normal",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAalign: "center",
                          color: "#fff",
                          padding: "8px 20px",
                          borderRadius: "4px",
                          textTransform: "none",
                          height: "42px",
                        }}
                      >
                        Refer
                      </ReusableButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
          </Form>
        </Dialog>

        <ListItem style={{ padding: "0px", marginTop: "10px" }}>
          <NavLink to="/" className="link">
            <ListItemText
              style={{ color: "#FFF !important" }}
              onClick={onClickLogout}
              className="mobile-view-list-text"
            >
              <ListItemIcon style={{ color: "#FFF" }}>
                <Tooltip title="Logout" placement="right" arrow>
                  <LogoutIcon />
                </Tooltip>
              </ListItemIcon>
              <span style={{ color: "#FFF" }}>Logout</span>
            </ListItemText>
          </NavLink>
        </ListItem>
      </Box>
    </div>
  );
}
