import React from 'react';

import { TextField } from '@material-ui/core';

export default function Input(props) {
  const { name, label, value, onChange,size,placeholder,error=null,disabled,InputProps,className, helperText,type,id} = props;
console.log(id)
  return (
    
    <TextField

      type={type}
      id={id}
      variant="outlined"
      label={label}
      name={name}
      value={value}
      onChange={onChange}
      size={size}
      placeholder={placeholder}
      disabled={disabled}
      InputProps={InputProps}
      autoComplete='off'
      className={className} 
      helperText={helperText}
      {...(error && {error:true,helperText:error})}
    />
  );
}
