import React from "react";
import "../user-dashboard.css";

export const EarnedCreditPoints = (props) => {
  const {value} = props;  
  return (
    <>
      {/* <List
        sx={{
          width: "100%",
          height: "20%",
          bgcolor: "background.paper",
          padding: 0,
          borderRadius: "12px",
        }}
      > */}
        <div style={{
              display: "flex",
              justtifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              // padding: "20px",
              borderRadius: "12px",
              backgroundColor:"#fff",
              width:"100%"
            }}>
            <p style={{
                  borderRadius: "0px",
                  fontFamily: "Poppins",
                  fontSize: "17px",
                  fontWeight: "700",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "normal",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#000",
                  display: "flex",
                  justifyContent: "center",
                  marginTop:"28px",
                  padding:"0px 8px 0px 8px",
                  textTransform:"initial"
                }}
                className="plain-text">Number of Earned Credit points</p>
            <p style={{
                  fontFamily: "Poppins",
                  fontSize: "38px",
                  fontWeight: "bold",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "0.87",
                  letterSpacing: "normal",
                  textAlign: "center",
                  color: "#fb2485",

                  // marginTop: "36px",
                }}
                className="quiz-value-text">{value}</p>
        </div>
    </>
  );
};
