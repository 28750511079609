import React from 'react';
import {
  FormControl,
  InputLabel,
  Select ,
  MenuItem,
  FormHelperText,
} from '@material-ui/core';

export default function Dropdown2(props) {
  const { 
    name, 
    label, 
    value,
    size, 
    error = null, 
    onChange,
    style,
    placeholder, 
    options,
    menustyle,
    defaultValue,
    displayEmpty} = props;

  return (
    <FormControl variant="outlined" fullWidth {...(error && { error: true })}>
      <InputLabel>{label} </InputLabel>
      <Select label={label} name={name} defaultValue={defaultValue} displayEmpty={displayEmpty} value={value} size={size} placeholder={placeholder} onChange={onChange} style={style}>
        <MenuItem value="" style={menustyle} disabled selected hidden>All</MenuItem>
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
