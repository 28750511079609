import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core';

export function MyForm(initialValues, validateOnEdit = false, validate) {
  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  const jobpos=values.jobpos;

  const handleInputChange = (e) => {
    // console.log(values)

    const { name, value } = e.target;

    setValues({
      ...values,
      [name]: value,
    });

    if (validateOnEdit) {
      validate({ [name]: value });
    }
  };

  const handleValueChange=(e)=>{
    // console.log(e)
    // console.log(values)
    if(e!==null){
    const {value,label} = e
    setValues({
      ...values,
      jobpos: value,
    });

    if (validateOnEdit) {
      validate({  jobpos: value });
    }
  }
      }
    
  const resetForm = () => {
    setValues(initialValues);
    setErrors({});
  };
  return {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
    resetForm,handleValueChange,
    validateOnEdit
  };
}

const styles = makeStyles((theme) => ({
  main: {
    '& .MuiFormControl-root': {
      width: '90%',
      margin: theme.spacing(1),
    },
  },
}));
export function Form(props) {
  const classes = styles();
  const { children, ...other } = props;
  return (
    <form className={classes.main} autoComplete="off" {...other}>
      {props.children}
    </form>
  );
}
