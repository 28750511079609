import React from 'react';
import { Button as MyButton, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme) => ({
  main: {
    margin: theme.spacing(1),
  },
  label: {
    textTransform: 'none',
  },
}));

export default function Button(props) {
  const { text, size, color, variant,style, onClick, ...other } = props;
  const classes = styles();
  return (
    <MyButton
      variant={variant || 'contained'}
      onClick={onClick}
      size={size || 'large'}
      color={color || 'primary'}
      {...other}
      classes={{ root: classes.main, label: classes.label }}
      style={style}
    >
      {text}
    </MyButton>
  );
}
