import React, { useState, useEffect } from 'react';
import VideoPlayer from '../../components/Custom/VideoThumbnail/VideoPlayer';
import '../../components/Custom/VideoThumbnail/VideoThumbnail.css';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import "./absyzian.css";
import {
  TextField,
  Grid,
  makeStyles,
  Typography,
  Box,
  Button,
  Paper,
} from '@material-ui/core';
import { Lines } from 'react-preloaders';
import ReactLoading from 'react-loading';
import { ClassNames } from '@emotion/react';
import API from '../../api/index';

const api1 = API.Api1;
const api2 = API.Api2;

function AbsyzianTalk(props) {
  const MyTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#fb2485',
      color: 'white',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
    arrow: {
      color: '#fb2485',
    },
  }))(Tooltip);
  const [videodata, setVideoData] = useState([]);
  const [urlData, setUrlData] = useState([]);
  const [thumbnail, setThumbNail] = useState([]);
  const [openPlayer, setOpenPlayer] = useState(false);
  const [playerId, setPlayerId] = useState();
  const [done, setDone] = useState(undefined);

  const ytVidId = (url) => {
    var p =
      /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
    return url.match(p) ? RegExp.$1 : false;
  };

  useEffect(() => {
    getData();
  }, []);

  const getThumbnails = (UrlData) => {
    UrlData.map((items) => {
      if (items.video_status === 'Active') {
        const url = items.video_link;
        setUrlData((arr) => [...arr, url]);
        const videoId = ytVidId(url);
        const image = `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`;
        setThumbNail((arr) => [...arr, image]);
      }
    });
  };
  const getData = async () => {
    await axios({
      method: 'get',
      url: `${api1}/videos`,
      headers: { token: `qwerty`, 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        console.log(response);
        setDone(true);

        if (response.status) {
          const data = response.data.data[0].videos;
          const updatedData = data.map((items, index) => ({
            sno: index + 1,
            id: items.video_pk,
            video_name: items.video_name,
            video_link: items.video_link,
            video_status: items.video_status == 'Y' ? 'Active' : 'InActive',
          }));
          //   //console.log('updatedData', updatedData);
          setVideoData(updatedData);

          getThumbnails(updatedData);
        }
      })
      .catch(function (error) {
        //console.log('axios error', error);
        setDone(true);
        // //console.log(loading);
      });
  };
  const handlePlayerClose = () => {
    setOpenPlayer(false);
  };
  const handlePlayerOpen = (id) => {
    setOpenPlayer(true);

    setPlayerId(id);
  };
  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <ReactLoading
            type={'spinningBubbles'}
            color={'#fb2485'}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box
          sx={{
            flexGrow: 1,
            // padding: '0px 80px 0px 80px !important',
            // marginLeft:'-10px',
            marginRight: '40px',
            marginBottom: '220px',
          }}
          className="box-talk-outer"
        >
          <Box sx={{ flexGrow: 1 }} style={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <h4
                  style={{
                    fontSize: '1.5em',
                    fontFamily: 'Poppins',
                    color: 'white',
                    fontStretch: 'normal',
                    letterSpacing: 'normal',
                  }}
                >
                  ABSYZian's Talk
                </h4>
              </Grid>
            </Grid>
          </Box>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 12, sm: 8, md: 12 }}
            className="outer-class-for-talk"
          >
            {/* {Array.from(Array(6)).map((_, index) => (
              <Grid item xs={12} sm={4} md={4} key={index}>
                <span>xs=2</span>
              </Grid>
            ))} */}
            {thumbnail ? 
            <>
             {thumbnail.map((items, index) => {
           
           return (
            <Grid item xs={12} sm={12} md={4} key={index} sx={{width: "100%"}}>
               <div
                 style={{
                   display: "flex",
                   flexDirection: "column",
                   justifyContent: "center",
                   alignItems: "center",
                   padding:'20px '
                 }}
                 className="card-talk-outer"
               >
                 <Box
                   sx={{
                     textAlign: "center",
                     backgroundColor: "rgba(2, 33, 91, 0.75)",
                     width: "356px",
                     height: "53px",
                     borderTopLeftRadius: "12px",
                     borderTopRightRadius: "12px",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                   }}
                 >
                   <MyTooltip
                     placement="top"
                     title={videodata!== undefined ? videodata[index].video_name : null}
                     arrow
                   >
                     <span
                       style={{
                         flexGrow: 0,
                         fontFamily: "Poppins",
                         fontSize: "16px",
                         fontWeight: 500,
                         fontStretch: "normal",
                         fontStyle: "normal",
                         lineHeight: "normal",
                         letterSpacing: "normal",
                         textAlign: "center",
                         color: "#fff",
                         overflow: "hidden",
                         whiteSpace: "nowrap",
                         textOverflow: "ellipsis",
                         width: "250px",
                         display: "inline-block",
                       }}
                     >
                      {videodata!== undefined ? videodata[index].video_name : null}
                       {/* {videodata[index].video_name} */}
                     </span>
                   </MyTooltip>
                 </Box>

                 <Button
                   key={index}
                   onClick={() => {
                     handlePlayerOpen(videodata[index].id);
                   }}
                   style={{ paddingTop: 0, display: "inline-block" }}
                 >
                   <div className="video-thumbnail-container">
                     <img className="thumbnail" width="auto" src={items} />
                   </div>
                 </Button>

               
               </div>
             </Grid>
           );
         })}
            </>:
            <>
            </>}
            
          </Grid>
          {/* <Grid
         spacing={3} xs={4} md={4}
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "flex-start",
            }}
          >
            {thumbnail.map((items, index) => {
           
              return (
                <Grid item xs={12} sm={6} md={4} lg={4} style={{ padding: "15px" }}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        textAlign: "center",
                        backgroundColor: "rgba(2, 33, 91, 0.75)",
                        width: "356px",
                        height: "53px",
                        borderTopLeftRadius: "12px",
                        borderTopRightRadius: "12px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <MyTooltip
                        placement="top"
                        title={videodata[index].video_name}
                        arrow
                      >
                        <span
                          style={{
                            flexGrow: 0,
                            fontFamily: "Poppins",
                            fontSize: "16px",
                            fontWeight: 500,
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAlign: "center",
                            color: "#fff",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            width: "250px",
                            display: "inline-block",
                          }}
                        >
                          {videodata[index].video_name}
                        </span>
                      </MyTooltip>
                    </Box>

                    <Button
                      key={index}
                      onClick={() => {
                        handlePlayerOpen(videodata[index].id);
                      }}
                      style={{ paddingTop: 0, display: "inline-block" }}
                    >
                      <div className="video-thumbnail-container">
                        <img className="thumbnail" width="auto" src={items} />
                      </div>
                    </Button>

                  
                  </div>
                </Grid>
              );
            })}
          </Grid> */}
          <VideoPlayer
            open={openPlayer}
            id={playerId}
            handleClose={handlePlayerClose}
          />
        </Box>
      )}
    </>
  );
}

export default AbsyzianTalk;
