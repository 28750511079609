import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid, Box, Paper } from '@material-ui/core';
import { MyForm, Form } from '../../../components/MyForm';
import { Custom } from '../../../components/Custom/Custom';
import { makeStyles } from '@material-ui/core';
import ReusableButton from '../../../components/reusable-button/reusable-button';
import Notification from '../../../components/Custom/Notification';
import API from '../../../api/index';
import './products.css';
const api1 = API.Api1;
const api2 = API.Api2;
const SheetJSFT = ['jpg', 'png', 'jpeg']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');
const initialValues = {
  productName: '',
  productCredits: '',
  productImage: '',
};
const styles = makeStyles((theme) => ({
  inputStyle: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '4px',
      marginTop: '8px',
    },
  },
}));
function AddPopUp(props) {
  const { getProductData, open, setOpen } = props;
  const classes = styles();

  const [fileMerge, setFileMerge] = useState();

  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState('');
const [errorStatus,setErrorStatus]=useState(false)
const [errorMessage,setErrorMessage] = useState('')
  const validate = (inputValues = values) => {
    let code = { ...errors };
    console.log(inputValues);
    if ('productName' in inputValues)
      code.productName = inputValues.productName
        ? ''
        : 'Please enter a product name';

    if ('productCredits' in inputValues)
      code.productCredits = inputValues.productCredits
        ? ''
        : 'Please enter product credits';

    if (fileMerge===undefined){
      setErrorStatus(true)
      setErrorMessage("Please Upload Product Image")
    }

    setErrors({
      ...code,
    });
    if (inputValues === values)
      //  console.log(values, 'values');
      return Object.values(code).every((x) => x === '');
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);
  const handleEditClose = () => {
    setOpenEdit(false);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleProductImageChange = (e) => {
    setErrorStatus(false)
       setErrorMessage("")
    setFileMerge(e.target.files[0]);
    console.log('image', e.target.files[0]);
  };
  const handleClickByCancel = (e) => {
      resetForm()
    setOpen(false);
    
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    //console.log(values.productCredits, 'credits');
    console.log(fileMerge, 'image');
    //console.log(values.productName, 'name');
  
     if (validate()) {
      // //console.log("working")
      //console.log(typeof fileMerge, 'fileMerge');
      console.log('called');
      var fd = new FormData();
      fd.append('product_name', values.productName);
      fd.append('logo', fileMerge);
      fd.append('product_credits', values.productCredits);
      fd.append('product_statua', 'Y');
      // formData.append('product_status',"Y");
      // Object.entries(fileMerge).map((file, index) => {
      //   //console.log(file, "Files");
      //   formData.append("logo", file[1]);
      // });

      for (var pair of fd.entries()) {
        console.log(pair[0] + ', ' + pair[1]);
      }
      const requestOptions = {
        method: 'POST',
        body: fd,
      };
      //console.log(requestOptions, 'requestion');
      try {
        const response = await fetch(`${api1}/admin/products`, requestOptions);
        console.log('dataupload', response);
        const resultInJson = await response.json();
        console.log(resultInJson, 'result');
        if (resultInJson.api_status === 200) {
          setOpen(false);
          getProductData();
          setNotify({
            isOpen: true,
            message: 'Uploaded successfully',
            type: 'success',
          });
          resetForm()
         
        }
        else{
          setErrorStatus(true)
          setErrorMessage("Please Upload Product Image")
        }

        
        // //console.log("referred");

        // const requestOptions = {
        //   method: "POST",
        //   body:formData,
        // };

        //   // const response = await fetch(
        //   //   "https://absyzloungeapi.herokuapp.com/admin/products",
        //   //   requestOptions
        //   // );
        //   // console.log(response,"responseData")
      } catch (e) {
        console.log('something went wrong!', e);
      }
     }
    // resetForm()
  };

  return (
      <>
    <Dialog open={open} onClose={handleClose} maxWidth="md">
      {/* <Form onSubmit={handleSubmit}> */}
      <DialogTitle>Upload Products</DialogTitle>
      <DialogContent style={{ overflow: 'hidden', padding: '20px !important' }}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <b>
                  <label style={{ marginLeft: 15, fontSize: 15 }}>
                    Product Name
                  </label>
                </b>
                <Custom.Input
                  name="productName"
                  type="text"
                  className={classes.inputStyle}
                  // label={'Product Name'}
                  placeholder="Product Name"
                  size="small"
                  onChange={handleInputChange}
                  error={errors.productName}
                />
              </div>
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <b>
                  <label style={{ marginLeft: '15px' }}>Upload Image</label>
                </b>
                <input
                  type="file"
                  name="productImage"
                  className="upload-box2"
                  accept={SheetJSFT}
                  style={{
                    height: '44px',
                    marginTop: '8px',
                    fontSize: '0.8em',
                    fontFamily: 'Poppins',
                    width: '330px',
                  }}
                  onChange={handleProductImageChange}
                  error={errors.productImage}
                  //helperText={Errors}
                />
              </div>
               {errorStatus ? (
                                    <p className="error">{`*${errorMessage}`}</p>
                                  ) : 
                                   null
                                  }
            </Grid>
            <Grid item xs={1}></Grid>
            <Grid item xs={5}>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <b>
                  <label
                    style={{
                      marginLeft: 15,
                      fontSize: 15,
                      paddingBottom: 20,
                    }}
                  >
                    Product Credits
                  </label>
                </b>
                <Custom.Input
                  type="number"
                  name="productCredits"
                  placeholder="Product Credits"
                  size="small"
                  className={classes.inputStyle}
                  onChange={handleInputChange}
                  error={errors.productCredits}
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
      <DialogActions>
        <ReusableButton
          onClick={handleClickByCancel}
          size="large"
          variant="outlined"
          buttonName="Cancel"
          style={{
            marginRight: '10px',
            color: '#000936',
            border: '1px solid #000936',
            marginBottom: '15px',
            borderRadius: 4,
          }}
        >
          Cancel
        </ReusableButton>
        <ReusableButton
          onClick={handleSubmit}
          type="submit"
          size="large"
          variant="contained"
          buttonName="Add"
          style={{
            backgroundColor: '#fb2485',
            marginRight: '30px',
            marginBottom: '15px',
            borderRadius: 4,
            padding: '8px 36px',
          }}
        >
          Add
        </ReusableButton>
      </DialogActions>
      {/* </Form> */}
    </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
      </>
  );
}

export default AddPopUp;
