import React from "react";
import Card from "@mui/material/Card";
import './cards.css';

export const ReusableCards = ({ className, value, text, style }) => {
  return (
    <>
      <Card className={`${className}`} style={style}>
        <span style={{
          fontFamily: "Poppins",
          fontSize: "1em",
          fontWeight: "550",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: "normal",
          letterSpacing: "normal",
          textAlign: "left",
          color: "#000",
        }}>{text}</span>
        <span
          style={{
            fontFamily: 'Poppins',
            fontSize: "1.6em",
            fontWeight: "700",
            fontStretch: "normal",
            fontWtyle: "normal",
            lineHeight: 1.41,
            letterSpacing: "normal",
            textAlign: "right",
            color: "#fb2485",
          }}
        >
          {value}
        </span>
      </Card>
    </>
  );
};
