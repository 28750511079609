import React from 'react';
import Button from "@mui/material/Button";
const ReusableButton = ({size,style,variant,onClick,type,className,buttonName,startIcon,component}) => {
  return (
    <>
      <Button size={size} style={style} variant={variant} component={component} onClick={onClick} type={type} className={`${className}`} startIcon={startIcon}>{buttonName}</Button>
    </>
  );
};

export default ReusableButton;
