/* eslint-disable no-lone-blocks */
import React, { useState,useEffect } from "react";
// import { DataTable } from "../../../components/reusable-datagrid/reusable-datgrid";
import { Grid, Box } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
// import { ReusableCards } from "../../../components/reusable-cards/reusable-cards";
// import { DataTable } from '../../../components/reusable-datagrid/reusable-datgrid';
// import PersonAddIcon from "@mui/icons-material/PersonAdd";
import axios from "axios";
import { Custom } from "../../../components/Custom/Custom";
// import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { MyForm, Form } from "../../../components/MyForm";
import EditIcon from "@mui/icons-material/Edit";
import Notification from '../../../components/Custom/Notification';
import './competency-level.css';
// const initialValues = {
//     No_of_questions: '',
//     countdown_time: '',
//     question_credits: '',
//     pass_score: '',
//     attempts: '',
//     competency_level:'',
//   };
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;

export const CompetencyLevel = (props) => {
    // const [basicState,setBasicState] = useState(initialValues);
    
  const { 
    open,openEdit,
    setOpenEdit, 
    competencyLevelModalData, 
    handleClose,
    basicContent,
    intermediateContent,
    advanceContent,
    setBasicContent,
    setIntermediateContent,
    basicState,
    intermediateState,
    advanceState,
    setBasicState,
    setIntermediateState,
    setAdvanceState,
    // getApiDefaultData,
    setAdvanceContent } = props;

    // //console.log(basicContent,"basicContent")
    const [notify, setNotify] = useState({
      isOpen: false,
      message: '',
      type: '',
    });
  

  const handleOnChangeBasicOne = (e) => {
    setBasicState({...basicState ,no_of_questions:e.target.value})
  };
  const handleOnChangeBasicTwo = (e) => {
    setBasicState({...basicState ,countdown_time:e.target.value})
  };
  const handleOnChangeBasicThree = (e) => {
    setBasicState({...basicState ,question_credits:e.target.value})
  };
  const handleOnChangeBasicFour = (e) => {
    setBasicState({...basicState ,pass_score:e.target.value})
  };
  const handleOnChangeBasicFive = (e) => {
    setBasicState({...basicState ,attempts:e.target.value})
  };


// //console.log(competencyLevelModalData,"competencyLevelModalData")


  const handleOnChangeIntermediateOne = (e) => {
    setIntermediateState({...intermediateState ,no_of_questions:e.target.value})
  };
  const handleOnChangeIntermediateTwo = (e) => {
    setIntermediateState({...intermediateState ,countdown_time:e.target.value})
  };
  const handleOnChangeIntermediateThree = (e) => {
    setIntermediateState({...intermediateState ,question_credits:e.target.value})
  };
  const handleOnChangeIntermediateFour = (e) => {
    setIntermediateState({...intermediateState ,pass_score:e.target.value})
  };
  const handleOnChangeIntermediateFive = (e) => {
    setIntermediateState({...intermediateState ,attempts:e.target.value})
  };



  const handleOnChangeAdvanceOne = (e) => {
    setAdvanceState({...advanceState ,no_of_questions:e.target.value})
  };
  const handleOnChangeAdvanceTwo = (e) => {
    setAdvanceState({...advanceState ,countdown_time:e.target.value})
  };
  const handleOnChangeAdvanceThree = (e) => {
    setAdvanceState({...advanceState ,question_credits:e.target.value})
  };
  const handleOnChangeAdvanceFour = (e) => {
    setAdvanceState({...advanceState ,pass_score:e.target.value})
  };
  const handleOnChangeAdvanceFive = (e) => {
    setAdvanceState({...advanceState ,attempts:e.target.value})
  };

  let HolderArray={};
  var arrayResult;
  const handleUpdateData = async () => {

////console.log(basicState,intermediateState,advanceState,"basicStateintermediateStateadvanceState")



HolderArray.a=basicState;
HolderArray.b=intermediateState;
HolderArray.c=advanceState;
////console.log(HolderArray,"HolderArray")

function convertIntObj(obj) {
  const res = {}
  for (const key in obj) {
    res[key] = {};
    for (const prop in obj[key]) {
      const parsed = parseInt(obj[key][prop], 10);
      res[key][prop] = isNaN(parsed) ? obj[key][prop] : parsed;
    }
  }
  return res;
}

var result = convertIntObj(HolderArray);

// ////console.log('Object result', result)

 arrayResult = Object.values(result);

////console.log('Array result', arrayResult)





const requestOptions = {
  method: "PUT",
  headers: {"Content-Type": "application/json"},
  body: JSON.stringify(arrayResult),
  // body: arrayResult,
};
const response = await fetch(`${api1}/level`,requestOptions);
// ////console.log(response, "response");
const data = await response.json();
////console.log(data, "data");
const resultOne = data;
// //console.log(result.api_status,"api_status")
if(resultOne.api_status === 200){
  // resultOne.data.map((item)=>{
  //   alert(item.message)
  // })
  setNotify({
    isOpen: true,
    message: 'Data Updated Successfully',
    type: 'success',
  });
}
else{
  setNotify({
    isOpen: true,
    message: 'Data Not Updated Please Try Again',
    type: 'error',
  });
}
    setOpenEdit(false);
    setBasicContent(true)
    setIntermediateContent(true)
    setAdvanceContent(true)

  }



  const handleBasicEditFunction = () =>{
    //console.log("editing")
    setBasicContent(false)
    setIntermediateContent(true)
    setAdvanceContent(true)
  }

  const handleIntermediateEditFunction = () =>{
    //console.log("editing")
    setBasicContent(true)
    setIntermediateContent(false)
    setAdvanceContent(true)
  }

  const handleAdvanceEditFunction = () =>{
    //console.log("editing")
    setBasicContent(true)
    setIntermediateContent(true)
    setAdvanceContent(false)
  }

  // //console.log(basicState,"basicState")
  
      
  return (
    <>
      <div>
        <Dialog
        maxWidth="md"
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              maxHeight:"100% !important"
            }}}
          style={{ padding: "16px", overflowY: "hidden !important" }}>
          <Form>
            <DialogTitle>Competency Levels</DialogTitle>
            <DialogContent>

              <Box sx={{ flexGrow: 1,padding:"0px 20px 0px 20px" }}>
                <div style={{display:"flex"}}>
                    <span className="BasicContentTitile">Basic Competency Level</span>
                    <EditIcon color="primary" fontSize="small" style={{marginLeft:"8px",cursor:"pointer"}} onClick={handleBasicEditFunction}/>
                </div>
                <Grid container style={{display:"flex",justifyContent:"space-between",alignItems:"end" ,marginTop:"10px"}}>
                  <Grid item xs={2}>
                      <span className="basicComponentTextOne">No. of Questions</span>
                        <Custom.Input
                          name="Questions"
                          className="competencyLevelField"
                          value={basicState.no_of_questions}
                          onChange={handleOnChangeBasicOne}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={basicContent}
                        //   InputProps={{
                        //     readOnly: false,
                        //   }}
                        />
                    </Grid>

                    {/* {`0/${onboardingCandidates}`} */}

                    <Grid item xs={2}>
                      <span className="basicComponentTextTwo">Countdown</span>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                        
                          name="Countdown"
                          className="competencyLevelField"
                          //placeholder="Questions"
                          value={basicContent === true ? `${basicState.countdown_time}:00 mm:ss` : basicState.countdown_time}
                          // value={getcountdown_timeValue()}
                          onChange={handleOnChangeBasicTwo}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={basicContent}
                        />
                      </span>
                    </Grid>


                    <Grid item xs={2}>               
                      <span className="basicComponentTextThree">Credits for each Questions</span>
                        <Custom.Input
                        
                          name="question_credits"
                          className="competencyLevelField"
                          //placeholder="Questions"
                          value={basicContent === true ? `${basicState.question_credits} Credits` : basicState.question_credits}
                          // value={getquestion_creditsValue()}
                          onChange={handleOnChangeBasicThree}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={basicContent}
                        />
                              
                    </Grid>


                    <Grid item xs={2}>
                      <span className="basicComponentTextFour">Pass Percentage</span>
                    
                        <Custom.Input
                          name="Percentage"
                          className="competencyLevelField"
                          //placeholder="Questions"
                          value={basicContent === true ? `${basicState.pass_score}%` : basicState.pass_score}
                          // value={getPercentageValue()}
                          onChange={handleOnChangeBasicFour}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={basicContent}
                        />
                    
                    </Grid>

                    <Grid item xs={2}>
                      <span className="basicComponentTextFive">No. of attempts Allowed</span>
                     
                        <Custom.Input
                        className="competencyLevelField"
                          name="attemptsAllowed"
                          value={basicState.attempts}
                          onChange={handleOnChangeBasicFive}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={basicContent}
                        />
                      
                    </Grid>
                </Grid>
              </Box> 
              
              <hr className="dottedLines"></hr>

              <Box sx={{ flexGrow: 1,marginTop:"12px",padding:"0px 20px 0px 20px" }}>
                <div style={{display:"flex"}}>
                    <span className="BasicContentTitile">Intermediate Competency Level</span>
                    <EditIcon color="primary" fontSize="small" style={{marginLeft:"8px",cursor:"pointer"}} onClick={handleIntermediateEditFunction}/>
                </div>
                <Grid container style={{display:"flex",justifyContent:"space-between",alignItems:"end" ,marginTop:"10px"}}>
                  <Grid item xs={2}>
                      <span className="basicComponentTextOne">No. of Questions</span>
                        <Custom.Input
                        className="competencyLevelField"
                          name="Questions"
                          //placeholder="Questions"
                          value={intermediateState.no_of_questions}
                          onChange={handleOnChangeIntermediateOne}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={intermediateContent}
                        />
                    </Grid>


                    <Grid item xs={2}>
                      <span className="basicComponentTextTwo">Countdown</span>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                        className="competencyLevelField"
                          name="Countdown"
                          //placeholder="Questions"
                          // value={`${intermediateState.countdown_time}:00 mm:ss`}
                          value={intermediateContent === true ? `${intermediateState.countdown_time}:00 mm:ss`: intermediateState.countdown_time}
                          onChange={handleOnChangeIntermediateTwo}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={intermediateContent}
                        />
                      </span>
                    </Grid>


                    <Grid item xs={2}>               
                      <span className="basicComponentTextThree">Credits for each Questions</span>
                        <Custom.Input
                        className="competencyLevelField"
                          name="question_credits"
                          //placeholder="Questions"
                          // value={`${intermediateState.question_credits} question_credits`}
                        value={intermediateContent === true ? `${intermediateState.question_credits} Credits` : intermediateState.question_credits}
                          onChange={handleOnChangeIntermediateThree}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={intermediateContent}
                        />
                              
                    </Grid>


                    <Grid item xs={2}>
                      <span className="basicComponentTextFour">Pass Percentage</span>
                    
                        <Custom.Input
                        className="competencyLevelField"
                          name="Percentage"
                          //placeholder="Questions"
                          value={intermediateContent === true ? `${intermediateState.pass_score}%` : intermediateState.pass_score}
                          onChange={handleOnChangeIntermediateFour}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={intermediateContent}
                        />
                    
                    </Grid>

                    <Grid item xs={2}>
                      <span className="basicComponentTextFive">No. of attempts Allowed</span>
                     
                        <Custom.Input
                        className="competencyLevelField"
                          name="attemptsAllowed"
                          //placeholder="Questions"
                          value={intermediateState.attempts}
                        // value=""
                          onChange={handleOnChangeIntermediateFive}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={intermediateContent}
                        />
                      
                    </Grid>
                </Grid>
              </Box> 

                <hr className="dottedLines"></hr>

              <Box sx={{ flexGrow: 1,marginTop:"12px",padding:"0px 20px 0px 20px" }}>
                <div style={{display:"flex"}}>
                    <span className="BasicContentTitile">Advance Competency Level</span>
                    <EditIcon color="primary" fontSize="small" style={{marginLeft:"8px",cursor:"pointer"}} onClick={handleAdvanceEditFunction}/>
                </div>
                <Grid container style={{display:"flex",justifyContent:"space-between",alignItems:"end" ,marginTop:"10px"}}>
                  <Grid item xs={2}>
                      <span className="basicComponentTextOne">No. of Questions</span>
                        <Custom.Input
                        className="competencyLevelField"
                          name="Questions"
                          //placeholder="Questions"
                          value={advanceState.no_of_questions}
                          onChange={handleOnChangeAdvanceOne}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={advanceContent}
                        />
                    </Grid>


                    <Grid item xs={2}>
                      <span className="basicComponentTextTwo">Countdown</span>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                        className="competencyLevelField"
                          name="Countdown"
                          //placeholder="Questions"
                          value={advanceContent === true ? `${advanceState.countdown_time}:00 mm:ss` : advanceState.countdown_time}
                          onChange={handleOnChangeAdvanceTwo}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={advanceContent}
                        />
                      </span>
                    </Grid>


                    <Grid item xs={2}>               
                      <span className="basicComponentTextThree">Credits for each Questions</span>
                        <Custom.Input
                        className="competencyLevelField"
                          name="question_credits"
                          //placeholder="Questions"
                          value={advanceContent === true ? `${advanceState.question_credits} Credits` : advanceState.question_credits}
                          onChange={handleOnChangeAdvanceThree}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={advanceContent}
                        />
                              
                    </Grid>


                    <Grid item xs={2}>
                      <span className="basicComponentTextFour">Pass Percentage</span>
                        <Custom.Input
                          name="Percentage"
                          className="competencyLevelField"
                          value={advanceContent === true ? `${advanceState.pass_score}%`: advanceState.pass_score}
                          onChange={handleOnChangeAdvanceFour}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={advanceContent}
                        />
                    
                    </Grid>

                    <Grid item xs={2}>
                      <span className="basicComponentTextFive">No. of attempts Allowed</span>
                     
                        <Custom.Input
                        className="competencyLevelField"
                          name="attemptsAllowed"
                          //placeholder="Questions"
                          value={advanceState.attempts}
                          onChange={handleOnChangeAdvanceFive}
                          //   error="{errors.firstname}"
                          size="small"
                          fullWidth
                          disabled={advanceContent}
                        />
                      
                    </Grid>
                </Grid>
              </Box> 


            </DialogContent>
            <DialogActions style={{display:"flex",justifyContent:"end",alignItems:"baseline"}}>
              <ReusableButton
                onClick={handleClose}
                size="medium"
                variant="outlined"
                buttonName="Cancel"
                className="competencyCancelBtn"
                style={{
                  color: "#000936",
                  border: "1px solid #000936",
                  marginRight: "10px",
                  marginBottom:"10px",
                  borderRadius:"4px",
                }}
              />
              <ReusableButton
                //type="submit"
                // onClick={}
                className="competencyUpdateBtn"
                size="medium"
                variant="contained"
                buttonName="Update"
                style={{
                  backgroundColor: "#fb2485",
                  marginRight: "30px",
                  marginBottom:"8px",
                  color:"#fff",
                  borderRadius:"4px",
                }}
                onClick={handleUpdateData}
                />
            </DialogActions>
          </Form>
        </Dialog>

        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};
