import { Grid, Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import InputLabel from "@mui/material/InputLabel";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { Custom } from "../../../components/Custom/Custom";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import axios from "axios";
import { Link as RouterLink } from "react-router-dom";
import Cookies from "js-cookie";
import QuizExpand from "./quiz-expand";
import "./quiz.css";
import ReactLoading from "react-loading";
import { useLocation } from "react-router-dom";
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;

export default function QuizSelection() {
  const [departmentValueDD, setDepartmentValueDD] = useState("");
  const [departmentApiDD, setDepartmentApiDD] = useState([]);
  const [categoryValueDD, setCategoryValueDD] = useState("");
  const [categoryApiDD, setCategoryApiDD] = useState([]);
  const [departName, setDepartName] = useState("");
  const [catName, setCatName] = useState("");
  const [indexValue, setIndexValue] = useState("");
  const [depDependent, setDepDependent] = useState(false);
  const [subCategoryApiDD, setSubCategoryApiDD] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [deptSubCat, setDeptSubCat] = useState([]);
  const [datafromquizquestions, setDataFromQuizQuestions] = useState([]);

  const [depsub, setdepsub] = useState([]);
  ////console.log(tableData, 'dep');
  const [clicked, setClicked] = useState(false);
  const [name, setName] = useState();
  const [done, setDone] = useState(undefined);
  //console.log(categoryValueDD,"categoryValueDD")
  //console.log(departmentValueDD,"departmentValueDD")
  // function allsubcat  () {
  //     ////console.log(depsub,"dep")
  //     depsub.map((data)=>{
  //         ////console.log(data.data[0].department_sub_category,"hhgghgh")
  //     })
  // }
  // allsubcat();

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const location = useLocation();
  //console.log(location.state,"data from quiz questions")
  // console.log(location.state.data,"data from quiz expand")

  // console.log(location.state.results.department_category_name,"data cat")
  // console.log(location.state.results.department_name,"data dept")

  useEffect(() => {
    ////console.log('file nameeee');
    getIdValue();
    getDeparmentApi();
    setDataFromQuizQuestions(location.state);
    // console.log(location.state[0].department_category_name,"data cat")
    // if(location.state !== null){
    //   setDepartName(location.state[0].department_name)
    //   setCatName(location.state[0].department_category_name)
    // }

    // subCategoryData();
  }, []);

  ////console.log(Cookies.get('jwt_token'));

  const jwtToken = Cookies.get("jwt_token");
  // //console.log(jwtToken, 'jwt');
  //   const getIdValue = async () => {
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { token: "qwerty", "Content-Type": "application/json" },
  //       //body: JSON.stringify(getDesignation1),
  //       body: JSON.stringify({
  //         department: null,
  //         category: null,
  //         subCategory: null,
  //         efficiencyLevel: null,
  //       }),
  //     };
  //     const response = await fetch(
  //       "http://143.110.183.48:9090/employeeportal/getAllQuestions",
  //       requestOptions
  //     );
  //     // //console.log(response, "response");
  //     const data = await response.json();
  //     // //console.log(data, "data");
  //     const result = data;

  //   };

  const getIdValue = async () => {
    const response = await axios.get(`${api1}/user/all/sub_categories`, {
      headers: { "x-auth-token": `${jwtToken}` },
    });
    // //console.log(response, 'response');
    //   const data = await response.json();
    //   //console.log(data, "data");
    const result = response;
    // console.log(result, 'resultofquizpage');
    const fulldata = result.data.data[0].sub_categories;
    const subcatdata = result.data.data[0].sub_categories;
    subcatdata.map((data) => {
      //console.log(data.dp_fk2, 'dpartid');
    });
    setDone(true);
    // //console.log(departmentid,"departmentid");
    setTableData(fulldata);
  };

  let array = [];
  const getDeparmentApi = async () => {
    const res = await fetch(`${api1}/user/departments`, {
      headers: { "x-auth-token": `${jwtToken}` },
    });
    const getCon = await res.json();
    // //console.log(getCon.data[0],"getCon");

    Object.entries(getCon.data[0]).map((item) => {
      ////console.log(item, "object")
      array = item[1].map((value) => ({
        id: value.dp_pk,
        title: value.dp_name,
        message: value.dp_status,
      }));
    });
    setDone(true);
    setDepartmentApiDD(array);
    // //console.log(array, "arraydp_name");
  };

  const handleDeptDropdown = (event) => {
    // //console.log(event.target.value, "Dep");
    // //console.log(event,"Dep")
    setCategoryValueDD(``);
    setDepartmentValueDD(event.target.value);
    const selectedValue = event.target.value;

    // departmentApiDD.map((value, index) => {
    //   if (value.id === selectedValue) {
    //     setDepFilter(value.title);
    //   }
    // });

    setIndexValue(departmentValueDD);
    setDepDependent(true);

    let array1 = [];
    let array = [];

    const getCategoryData = async (indexValue) => {
      // //console.log(indexValue,"indexvalue")
      const res = await fetch(`${api1}/user/departments/${indexValue}`, {
        headers: { "x-auth-token": `${jwtToken}` },
      });
      const getCon = await res.json();
      // //console.log(getCon.data[0], "getCon");

      Object.entries(getCon.data[0]).map((item) => {
        array1 = item[1];
      });
      // //console.log(array1,"array1");
      array = array1.map((value, index) => ({
        id: value.dp_caty_pk,
        title: value.dp_caty_name,
        department: value.dp_name,
        foreignkey: value.dp_fk,
      }));
      // //console.log(array,"array")
      setCategoryApiDD(array);
      setDone(true);
    };

    getCategoryData(selectedValue);
  };
  // //console.log(categoryApiDD,"categoryApiDD")
  // //console.log(depFilter,"depFilter")

  const handleCatDropdown = (event) => {
    //console.log(event.target.value, 'Cat');
    const categorySelected = event.target.value;
    setCategoryValueDD(categorySelected);
  };

  const handleFilterSearch = async () => {
    // console.log(
    //   categoryValueDD,
    //   departmentValueDD,

    //   'allselectedvalues'
    // );

    setClicked(true);

    const raw = JSON.stringify({
      department: departmentValueDD,
      dp_category: categoryValueDD,
    });
    const headers = {
      token: "qwerty",
      "Content-Type": "application/json",
      "x-auth-token": `${jwtToken}`,
    };
    const config = { headers };
    try {
      const response = await axios.post(
        `${api1}/user/sub_categories`,
        raw,
        config
      );
      //console.log(response, 'ressssss');
      //console.log(response.status, 'response of users');
      if (response.status === 200) {
        //   setNotify({
        //     isOpen: true,
        //     message: 'Candidate added successfully',
        //     type: 'success',
        //   });
      }
      //console.log(response, 'ressssss');
      //console.log(response.data.data[0].sub_categories);
      setDeptSubCat(response.data.data[0].sub_categories);
    } catch (e) {
      //console.log('something went wrong!', e);
    }
  };
  //console.log(deptSubCat, 'subbbbb');

  // function subCategoryData(){
  //   {tableData.map((data) => {
  //     //console.log(data,"in map")
  //     const subcatname=data.dp_sub_caty_name;
  //     setName(subcatname);
  //     //console.log(subcatname,"sucat name in map")
  //   })}
  // }
  // subCategoryData();

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box style={{ padding: "16px", marginBottom: "150px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-  10px",
                }}
              >
                <h4
                  style={{
                    fontSize: "1.3750em",
                    fontFamily: "Poppins",
                    color: "white",
                    fontStretch: "normal",
                    letterSpacing: "normal",
                  }}
                >
                  Quiz
                </h4>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  backgroundColor: "white",
                  marginLeft: "15px",
                  marginTop: "-20px",
                  borderRadius: "10px",
                  padding: "0px !important",
                }}
              >
                <Grid
                  item
                  xs={12}
                  style={{
                    display: "flex",
                    marginno: "0px !important",
                    padding: "0px !important",
                  }}
                ></Grid>
                <Grid
                  container
                  spacing={2}
                  className="filterGrid"
                  style={{
                    display: "flex",
                    justifyContent: "flex-start",
                    // padding: "10px 0px 10px 0px",
                    marginLeft: "0px",
                    width: "100%",
                    marginBottom: "20px",
                  }}
                >
                  <Grid item xs={10} sm={4} md={4}>
                    <InputLabel
                      sx={{
                        float: "left",
                        fontWeight: "600",
                        marginBottom: "6px",
                      }}
                    >
                      Department
                    </InputLabel>

                    <>
                      <>
                        <Custom.Dropdown2
                          fullWidth
                          displayEmpty
                          // label="select value"
                          name="department"
                          value={departmentValueDD}
                          options={departmentApiDD}
                          size="small"
                          style={{ height: "44px" }}
                          //menustyle={{height:"200px"}}
                          onChange={handleDeptDropdown}
                        />
                      </>
                    </>
                  </Grid>

                  <Grid item xs={10} sm={4} md={4}>
                    <InputLabel
                      sx={{
                        float: "left",
                        fontWeight: "600",
                        marginBottom: "6px",
                      }}
                    >
                      Category
                    </InputLabel>
                    <>
                      <>
                        <Custom.Dropdown2
                          displayEmpty
                          fullWidth
                          name="Category"
                          value={categoryValueDD}
                          options={categoryApiDD}
                          size="small"
                          style={{ height: "44px" }}
                          onChange={handleCatDropdown}
                        />
                      </>
                    </>
                  </Grid>
                  <Grid
                    item
                    xs={4}
                    style={{
                      display: "flex",
                      justifyContent: "start",
                      alignItems: "end",
                    }}
                  >
                    <div style={{ height: "44px" }}>
                      <ReusableButton
                        size="small"
                        variant="contained"
                        className="filterSearchButton-Quiz"
                        buttonName="Search"
                        style={{
                          // height: "44px",
                          color: "#fff",
                          fontFamily: "Poppins",
                          fontSize: "0.8em",
                          fontWeight: 200,
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "left",
                          padding: "13px 23px 13px 24px",
                          borderRadius: "6px",
                        }}
                        onClick={handleFilterSearch}
                      />
                    </div>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="filterGrid"
                    style={{
                      display: "flex",
                      // justifyContent: "flex-start",
                      // padding: "10px 0px 10px 0px",
                      marginLeft: "0px",
                      marginRight: "18px",
                      width: "100%",
                      marginTop: "40px",
                    }}
                  >
                    {
                      clicked === false ? (
                        // && (location.state === null)
                        <>
                          {tableData.map((data) => {
                            // console.log(data, 'in map');
                            const subcatname = data.dp_sub_caty_name;
                            data.depvalue = departmentValueDD;
                            data.categoryvalue = categoryValueDD;

                            // const subcatid =data.
                            // setName(subcatname);
                            //console.log(subcatname, 'sucat name in map');
                            return (
                              <Grid spacing={3} xs={12} sm={6} md={4}>
                                <div style={{ padding: "10px" }}>
                                  <Card
                                    className={data.class_id}
                                    sx={{
                                      height: 120,
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <CardActionArea
                                      component={RouterLink}
                                      state={{ data: data }}
                                      // onClick={()=> //console.log("Check onclick functionality")}
                                      to="/user/home/quizExpand"
                                    >
                                      <CardContent>
                                        <Typography
                                          sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "22px",
                                            fontWeight: "bold",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "20px",
                                            color: "white",
                                          }}
                                        >
                                          {subcatname}
                                        </Typography>

                                        {/* <Typography  color="black">
                              50 Credits
                            </Typography>
                            <Typography  color="black">
                            30 mins Duration                         
                            </Typography> */}
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </div>
                              </Grid>
                            );
                          })}
                        </>
                      ) : clicked === true ? (
                        //  || (location.state === null)
                        <>
                          {deptSubCat.map((data) => {
                            data.depvalue = departmentValueDD;
                            data.categoryvalue = categoryValueDD;
                            //console.log(data, 'Data in map to check');
                            //console.log(data, 'in map');
                            const subcatname = data.dp_sub_caty_name;
                            //console.log(subcatname, 'sucat name in map');
                            return (
                              <Grid spacing={3} xs={12} sm={6} md={4}>
                                <div style={{ padding: "10px" }}>
                                  <Card
                                    className={data.class_id}
                                    sx={{
                                      height: 120,
                                      padding: "10px",
                                      borderRadius: "10px",
                                    }}
                                  >
                                    <CardActionArea
                                      // onClick={() =>
                                      //   console.log('Check onclick functionality')
                                      // }
                                      component={RouterLink}
                                      state={{ data: data }}
                                      // depvalue={{depvaluedd:departmentValueDD}}
                                      // catageoryvalue={{catvaluedd:categoryValueDD}}
                                      to="/user/home/quizExpand"
                                    >
                                      <CardContent>
                                        <Typography
                                          sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "22px",
                                            fontWeight: "bold",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            marginTop: "20px",
                                            color: "white",
                                          }}
                                        >
                                          {subcatname}
                                        </Typography>

                                        {/* <Typography  color="black">
                              50 Credits
                            </Typography>
                            <Typography  color="black">
                            30 mins Duration                         
                            </Typography> */}
                                      </CardContent>
                                    </CardActionArea>
                                  </Card>
                                </div>
                              </Grid>
                            );
                          })}
                        </>
                      ) : null
                      //                   <>
                      //                   {datafromquizquestions.map((data) => {
                      //                     data.depvalue = departmentValueDD;
                      //                     data.categoryvalue = categoryValueDD;
                      //                     console.log(data, 'Data in map from quizquestions');
                      //                     console.log(data.all_sub_categories, 'in map');
                      //                     const departmentname = data.department_name
                      //                     // setDepartName(data.department_name);
                      //                     const categoryname =  data.department_category_name
                      //                     // setCatName(data.department_category_name);
                      //                     const subcatarray = data.all_sub_categories;
                      //                     console.log(departmentname, 'departmentname name in map');
                      //                     console.log(categoryname, 'categoryname name in map');
                      //                     return(
                      //                       <>
                      //                     {subcatarray.map((data) => {
                      //                       console.log(data,"klklkl")
                      //                       console.log(data.dp_sub_caty_name, "6767")

                      //                       return (
                      //                         <Grid spacing={3} xs={4} md={4}>
                      //                           <div style={{ padding: '10px' }}>
                      //                             <Card
                      //                               className="card-img"
                      //                               sx={{
                      //                                 height: 120,
                      //                                 padding: '10px',
                      //                                 borderRadius: '10px',
                      //                               }}
                      //                             >
                      //                               <CardActionArea
                      //                                 onClick={() =>
                      //                                   console.log('Check onclick functionality')
                      //                                 }
                      //                                 component={RouterLink}
                      //                                 state={{ data: data }}
                      //                                 // depvalue={{depvaluedd:departmentValueDD}}
                      //                                 // catageoryvalue={{catvaluedd:categoryValueDD}}
                      //                                 to="/user/home/quizExpand"
                      //                               >
                      //                                 <CardContent>
                      //                                   <Typography
                      //                                     sx={{
                      //                                       fontFamily: 'Poppins',
                      //                                       fontSize: '22px',
                      //                                       fontWeight: 'bold',
                      //                                       fontStretch: 'normal',
                      //                                       fontStyle: 'normal',
                      //                                       lineHeight: 'normal',
                      //                                       letterSpacing: 'normal',
                      //                                       display: 'flex',
                      //                                       justifyContent: 'center',
                      //                                       alignItems: 'center',
                      //                                       marginTop: '20px',
                      //                                       color: 'white',
                      //                                     }}
                      //                                   >
                      //                                     {data.dp_sub_caty_name}
                      //                                   </Typography>

                      //                                   {/* <Typography  color="black">
                      //                             50 Credits
                      //                           </Typography>
                      //                           <Typography  color="black">
                      //                           30 mins Duration
                      //                           </Typography> */}
                      //                                 </CardContent>
                      //                               </CardActionArea>
                      //                             </Card>
                      //                           </div>
                      //                         </Grid>
                      //                       );

                      //                     })}

                      //                     </>

                      // )})}
                      //                 </>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
}
