import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import loginImg1 from '../../../assets/images/loginImg1.png';
import './user-forgot-password.css';
import { ReusableInputfield } from '../../../components/Custom/inputfield/inputfield';
import ReusableButton from '../../../components/Custom/button/button';
import { ReusableRememberMe } from '../../../components/Custom/remember-me/remember-me';
// import { blue } from "@mui/material/colors";
import AdminBg from '../../../assets/images/adminLoginPageBg.png';
import AdminContentPic from '../../../assets/images/adminLoginPageContentpic.png';
import Rectangle from '../../../assets/images/Rectangle.png';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'js-cookie';
import Notification from '../../../components/Custom/Notification';

import Link from '@mui/material/Link';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import API from '../../../api/index';
import axios from 'axios';
import ReactLoading from 'react-loading';


const api1 = API.Api1;
const api2 = API.Api2;
const inputFieldData = {
  email: '',
};

const inputErrorMsg = {
  forEmail: '',
};

const inputFieldErrorStatus = {
  emailStatus: false,
};
const useStyles = makeStyles({
  inputfieldLabel: {
    '& .MuiFormLabel-root': {
      color: 'black',
      fontWeight: 400,
    },
  },
});

function UserForgotPassword(props) {
  const navigate = useNavigate();
  const [done, setDone] = useState(true);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [isChecked, setIsChecked] = useState(false);
  const [emailValue, setEmailValue] = useState('');
  const classes = useStyles();

  const handleSubmit = async () => {
    setDone(false);
    setErrorStatus({ ...errorStatus, emailStatus: false });

    const emailValidation =
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

    // console.log(emailValue, 'input VALUE gIVEN');

    if (emailValidation.test(emailValue) === false) {
      setErrorStatus({ ...errorStatus, emailStatus: true });
      setInputFieldErrors({ forEmail: 'Enter Correct Email-ID' });
    } else {
      setErrorStatus({ ...errorStatus, emailStatus: false });
      setInputFieldErrors({ forEmail: '' });
      const newData = {
        email: emailValue,
      };

      const headers = {
        token: 'qwerty',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      const config = { headers };
      // console.log('called');
      try {
        const response = await axios.post(
          `${api1}/user/reset-email`,
          newData,
          config
        );
         console.log(response, 'response of users');
        if (response.data.api_status === 200) {
          setDone(true);
          navigate('/user/login');
        } else if (response.data.api_status === 404) {
          setDone(true);
          setErrorStatus({ ...errorStatus, emailStatus: true });
          setInputFieldErrors({ forEmail: 'User not registered' });
        }
      } catch (e) {
        // console.log('something went wrong!', e);
      }
    }
  };

  const handleTextChangeEmail = (event, value) => {
    setInputFieldValues({ ...inputFieldValues, email: event.target.value });
    setEmailValue(event.target.value);
  };
  return (
    <div
      component="main"
      className="OverallDiv"
      style={{
        height: '100%',
        zIndex: 1,
        position: 'absolute',
        width: '100%',
      }}
    >
      <>
        {!done ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '100vh',
            }}
          >
            <ReactLoading
              type={'spinningBubbles'}
              color={'#fb2485'}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <>
            <Container component="main" maxWidth="md">
              <Card
                variant="outlined"
                sx={{ marginTop: '7%', Width: '100%', borderRadius: 0 }}
              >
                <CardContent sx={{ padding: '15px 33px 33px 0px' }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                      <div>
                        <img
                          src={loginImg1}
                          alt="AdminContentPic"
                          width="350"
                          height="410"
                          style={{ marginLeft: '25px' }}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} className="mainGrid">
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          alignContent: 'center',
                          marginTop: '75px',
                        }}
                      >
                        <span
                          className="adminLoginHeadingThree"
                          style={{ marginTop: '14px', marginBottom: '7px' }}
                        >
                          Reset Password
                        </span>
                        <span className="para">
                          Enter your email ID and we’ll send a link on your
                          email to reset your password.
                        </span>
                      </Box>

                      <Box sx={{ marginTop: 2 }}>
                        <Stack direction="column" spacing={3}>
                          <ReusableInputfield
                            variant="outlined"
                            name="email"
                            margin="normal"
                            required
                            type="text"
                            value={emailValue}
                            id="email"
                            fullWidth
                            label="E-Mail"
                            onChange={(e) => handleTextChangeEmail(e, 'email')}
                            autoComplete="off"
                            inputProps={{
                              autoComplete: 'off',
                            }}
                            className={classes.inputfieldLabel}
                            error={errorStatus.emailStatus}
                            helperText={inputFieldErrors.forEmail}
                          />
                          <div
                            style={{
                              display: 'flex',
                              flexDirection: 'row',
                              justifyContent: 'space-between',
                            }}
                          >
                            <ReusableButton
                              size="large"
                              variant="outlined"
                              className="candidateDltepageCancelBttn"
                              buttonName="Back to Login"
                              style={{
                                textTransform: 'none',
                                border:"none",
                                backgroundColor: '#fe1c7f',
                                fontFamily: 'Poppins',
                                fontSize: '1em',
                                fontWeight: 600,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                color: '#fff',
                                padding: '12px 35px 12px 35px',
                                borderRadius: '4px',
                              }}
                              onClick={() => {
                                navigate('/');
                              }}
                            />

                            <ReusableButton
                              size="medium"
                              variant="contained"
                              type="submit"
                              className="adminLoginButton"
                              buttonName="Send Link"
                              style={{
                                // fontSize: '16px',
                                // fontWeight: 'normal',
                                // fontStretch: 'normal',
                                // fontStyle: 'normal',
                                // lineHeight: 'normal',
                                // letterSpacing: 'normal',
                                // textAalign: 'center',

                                // border: '1px solid #000936',
                                // padding: '7px 20px',
                                // borderRadius: '4px',
                                // marginRight: '8px',
                                // marginBottom:"25px",
                                textTransform: 'none',

                                backgroundColor: '#fe1c7f',
                                fontFamily: 'Poppins',
                                fontSize: '1em',
                                fontWeight: 600,
                                fontStretch: 'normal',
                                fontStyle: 'normal',
                                lineHeight: 'normal',
                                letterSpacing: 'normal',
                                textAlign: 'left',
                                color: '#fff',
                                padding: '12px 35px 12px 35px',
                                borderRadius: '4px',
                              }}
                              onClick={handleSubmit}
                            />
                          </div>
                        </Stack>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Container>

            <Notification notify={notify} setNotify={setNotify} />
          </>
        )}
      </>
    </div>
  );
}

export default UserForgotPassword;
