import React, { useEffect, useState } from "react";
import { Grid, Paper, Box, Button } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import { WeeklyDoes } from "./weekly-does/weekly-does";
import { NewsLetter } from "./news-letter/news-letter";
import { DashboardCarousel } from "./dashboard-carousel/dashboard-carousel";
import Stack from "@mui/material/Stack";
import { ReusableCardsUser } from "../../../components/reusable-cards/reusable-cards";
import { EarnedCreditPoints } from "./earned-credit-points/earned-credit-points";
import axios from "axios";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
import { EarnedCreditsModal } from "./earned-credits-modal/earned-credits-modal";
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;
export const UserDashboard = () => {
  const [data, setData] = useState({});
  const [done, setDone] = useState(false);
  // const [open, setOpen] =useState(false);
  const [productModal, setProductModal] = useState(false);
  const [productData, setProductData] = useState([]);
  const jwtToken = Cookies.get("jwt_token");

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));
  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const response = await axios.get(`${api1}/user/dashboard`, {
      headers: { "x-auth-token": `${jwtToken}` },
    });
    if (response.data.api_status === 200) {
      const result = response.data.data[0];
      setData(result);
      setDone(true);
    }
    console.log(response);
    //  console.log(data)
  };

  const handleClickOpen = async () => {
    //console.log("clicked")
    setProductModal(true);
    const response = await axios.get(`${api1}/user/products`, {
      headers: { "x-auth-token": `${jwtToken}` },
    });
    console.log(response, "resss");
    const result = response;
    console.log(result.data.data[0].products, "result");
    setProductData(result.data.data[0].products);
  };
  const handleProductModal = () => {
    setProductModal(false);
  };

  return (
    <>
      {done ? (
        <Box style={{ margin: "20px 25px 70px 25px" }}>
          <Grid
            container
            spacing={2}
            style={{ display: "flex", justifyContent: "space-around" }}
          >
            <Grid item xs={12} sm ={12} md={8}>
              <DashboardCarousel />

              <Grid container spacing={2}>
                <Grid item xs={12} sm={4} md={6}>
                  <ReusableCardsUser
                    value="09"
                    title={
                      <span
                        style={{
                          borderRadius: "0px",
                          fontFamily: "Poppins !important",
                          fontSize: "17px",
                          fontWeight: "700",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "center",
                          color: "#000",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "10px",
                        }}
                        className="plain-text"
                      >
                        Explore Quiz
                      </span>
                    }
                    content={
                      <>
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "24px" }}
                          className="explore-quiz-grid"
                        >
                          <Grid item xs={10} style={{ padding: "0px" }}>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: 500,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                // lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#2089d9",
                              }}
                              className="explore-quiz-text"
                            >
                              Salesforce Quiz
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              justifyContent: "end",
                              display: "flex",
                              padding: "0px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                // lineHeight: "2.05",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#fb2485",
                              }}
                            >
                              {data !== undefined
                                ? data.dp_quizz_count[0]
                                  ? data.dp_quizz_count[0].salesforce
                                    ? data.dp_quizz_count[0].salesforce.count
                                    : "0"
                                  : "0"
                                : "0"}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "24px" }}
                          className="explore-quiz-grid"
                          // style={{marginTop:"10px"}}
                        >
                          <Grid item xs={10} style={{ padding: "0px" }}>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: 500,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                // lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#2089d9",
                              }}
                              className="explore-quiz-text"

                            >
                              Digital Quiz
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              justifyContent: "end",
                              display: "flex",
                              padding: "0px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                // lineHeight: "2.05",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#fb2485",
                              }}
                            >
                              {data !== undefined
                                ? data.dp_quizz_count[0]
                                  ? data.dp_quizz_count[0].digital
                                    ? data.dp_quizz_count[0].digital.count
                                    : "0"
                                  : "0"
                                : "0"}
                            </span>
                          </Grid>
                        </Grid>

                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: "24px" }}
                          className="explore-quiz-grid"
                        >
                          <Grid item xs={10} style={{ padding: "0px" }}>
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: 500,
                                fontStretch: "normal",
                                fontStyle: "normal",
                                // lineHeight: "normal",
                                letterSpacing: "normal",
                                textAlign: "left",
                                color: "#2089d9",
                              }}
                              className="explore-quiz-text"
                            >
                              General Quiz
                            </span>
                          </Grid>
                          <Grid
                            item
                            xs={2}
                            style={{
                              justifyContent: "end",
                              display: "flex",
                              padding: "0px",
                            }}
                          >
                            <span
                              style={{
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontWeight: "bold",
                                fontStretch: "normal",
                                fontStyle: "normal",
                                // lineHeight: "2.05",
                                letterSpacing: "normal",
                                textAlign: "right",
                                color: "#fb2485",
                              }}
                            >
                              {data !== undefined
                                ? data.dp_quizz_count[0]
                                  ? data.dp_quizz_count[0].general
                                    ? data.dp_quizz_count[0].general.count
                                    : "0"
                                  : "0"
                                : "0"}
                            </span>
                          </Grid>
                        </Grid>
                      </>
                    }
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      padding: "8px 35px 20px 35px",
                      borderRadius: "12px",
                      height: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <ReusableCardsUser
                    value="09"
                    title={
                      <span
                        style={{
                          borderRadius: "0px",
                          fontFamily: "Poppins !important",
                          fontSize: "17px",
                          fontWeight: "700",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "normal",
                          letterSpacing: "normal",
                          textAlign: "center",
                          color: "#000",
                          display: "flex",
                          justifyContent: "center",
                          marginTop: "28px",
                        }}
                        className="plain-text"
                      >
                        Number of Quiz Completed
                      </span>
                    }
                    content={
                      <span
                        style={{
                          fontFamily: "Poppins",
                          fontSize: "38px",
                          fontWeight: "bold",
                          fontStretch: "normal",
                          fontStyle: "normal",
                          lineHeight: "0.87",
                          letterSpacing: "normal",
                          textAlign: "center",
                          color: "#fb2485",
                          marginTop: "45px",
                          marginBottom:'20px'
                        }}
                        className="quiz-value-text"
                      >
                        {data.quizz_count}
                      </span>
                    }
                    style={{
                      display: "flex",
                      // justtifyContent: 'center',
                      // justifyContent: "space-evenly",
                      alignItems: "center",
                      flexDirection: "column",
                      // padding: '20px 20px',
                      borderRadius: "12px",
                      height: "100%",
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Button
                    style={{ padding: 0, textDecoration: "none",width:'-webkit-fill-available' }}
                    onClick={handleClickOpen}
                  >
                    <EarnedCreditPoints value={data.total_credits} />
                  </Button>
                </Grid>
              </Grid>

              {/* <Grid container spacing={2} style={{ marginTop: '10px' }}></Grid> */}
            </Grid>

            {/* <Grid item xs={1}>

          </Grid> */}

            <Grid xs={12} sm ={12} md={4} className="weekly-does-grid">
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={12}>
                  <WeeklyDoes />
                </Grid>
                <Grid item xs={12} sm={6} md={12}>
                  <NewsLetter />
                </Grid>
                {/* <Grid item xs={12}>
                
              </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          <EarnedCreditsModal
            open={productModal}
            handleClose={handleProductModal}
            productData={productData}
            setProductData={setProductData}
          />
        </Box>
      ) : (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      )}
    </>
  );
};
