import React, { useState, useEffect } from "react";
import Dialog from "@mui/material/Dialog";
import axios from "axios";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { makeStyles } from "@material-ui/core";
import ReactPlayer from "react-player";
import API from "../../../api/index";
import "./VideoThumbnail.css";

const api1 = API.Api1;
const api2 = API.Api2;
const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "340px",
    width: "700px",
    display: "flex",
    alignItems: "center",
  },
}));

const VideoPlayer = (props) => {
  const classes = useStyles();
  const { open, id, handleClose } = props;
  const [url, seturl] = useState("");
  useEffect(() => {
    if (open) getVideoById(id);
  }, [id]);
  const getVideoById = async (id) => {
    const response = await axios.get(`${api1}/videos/${id}`);
    // console.log(id);
    // console.log(response, 'res');

    const data = response.data.data[0].videos[0];
    // console.log(data);

    seturl(data.video_link);
  };
  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ overflowY: "hidden !important" }}
        classes={{ paper: classes.dialogPaper }}
      >
        <ReactPlayer
          playing={true}
          controls
          width="100%"
          height="340px"
          url={url}
        />
      </Dialog>
    </div>
  );
};

export default VideoPlayer;
