import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import axios from "axios";
import Cookies from "js-cookie";
import { Custom } from "../../../components/Custom/Custom";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import IconButton from "@mui/material/IconButton";
import "./user-profile.css";
import API from "../../../api/index";
import { makeStyles } from "@material-ui/core";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import ReactLoading from 'react-loading';
const api1 = API.Api1;
const api2 = API.Api2;
const styles = makeStyles((theme) => ({
  phoneWidth: {
    "& .MuiOutlinedInput-adornedStart": {
      // width: "280px !important",
    },
  },
  dateWidth: {
    "& .MuiOutlinedInput-adornedEnd ": {
      // width: "280px !important",
    },
  },
}));
export default function UserProfile() {
  const classes = styles();
  const [dept, setDept] = useState();
  const [designation, setDesignation] = useState();
  const [email, setEmail] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [phone, setPhone] = useState();
  const [joiningdate, setJoiningDate] = useState();
  const [done, setDone] = useState(undefined);

  const jwtToken = Cookies.get("jwt_token");

  const getUserDetails = async () => {

    axios
      .get(`${api1}/user/profile`, {
        headers: { "x-auth-token": `${jwtToken}` },
      })
      .then(function (response) {
        const result = response;
        setDept(result.data.data[0].userprofile[0].department);
        setDesignation(result.data.data[0].userprofile[0].designation);
        setEmail(result.data.data[0].userprofile[0].email);
        setFirstName(result.data.data[0].userprofile[0].firstname);
        setLastName(result.data.data[0].userprofile[0].lastname);
        setPhone(result.data.data[0].userprofile[0].phone);
        setJoiningDate(result.data.data[0].userprofile[0].joiningdate);
        setDone(true)
      });
  };
  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <Box 
    // style={{ padding: "36px", marginBottom: "50px" }}
    className="user-profile-outerBox">
      <Grid
        container
        className="user-profile-outerGrid"
        // style={{
        //   backgroundColor: "white",
        //   marginLeft: "100px",
        //   marginTop: "20px",
        //   borderRadius: "10px",
        //   padding: "0px !important",
        //   height: "70vh",
        //   width: "70vw",
        // }}
      >
        {!done? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height: '70vh',
            }}
          >
            <ReactLoading
              type={'spinningBubbles'}
              color={'#fb2485'}
              height={100}
              width={100}
            />
          </Box>
        ):(<>
            <Grid item xs={12} sm={5} md={3} lg={3}
            className="user-profile-primary-grid"
            >
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "40px",
                  marginLeft:"7%"
                }}
              >
                <Typography>
                  User Profile -{" "}
                  <b>
                    {" "}
                    {firstName} {lastName}
                  </b>
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                className="user-profile-profile-pic"
                // style={{
                //   display: "flex",
                //   justifyContent: "center",
                //   marginTop: "20px",
                // }}
              >
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="label"
                >
                  <input hidden accept="image/*" type="file" />
                  <AccountBoxIcon
                    style={{ height: "230px", width: "215px", color: "#02215b" }}
                  />
                </IconButton>
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={7}
              md={9}
              lg={9}
              className="user-profile-secondary-grid"
              // style={{
              //   paddingTop: "30px",
              //   paddingLeft: "30px",
              //   display: "flex",
              //   flexWrap: "wrap",
              // }}
            >
              <Grid item xs={12}></Grid>
              <Grid item xs={12}></Grid>

              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* <div > */}
                <Typography
                className="user-profile-inner-grid-typography"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                >
                  First Name
                </Typography>
                <span className="user-profile-input-fields">
                  <Custom.Input
                    disabled
                    name="firstname"
                    // placeholder="First Name"
                    value={firstName}
                    defaultValue="Hello World"
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    fullWidth
                  />
                </span>
                {/* </div> */}
              </Grid>
              <Grid item 
              xs={12}
              md={6}
              className="user-profile-inner-grid"
              >
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Typography
                  className="user-profile-inner-grid-typography"
                    style={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontWeight: 600,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#000",
                    }}
                    variant="h6"
                  >
                    Last Name
                  </Typography>
                  <span>
                    <Custom.Input
                    disabled
                      name="lastname"
                      placeholder="Last Name"
                      value={lastName}
                      InputProps={{
                        readOnly: true,
                      }}
                      size="small"
                    />
                  </span>
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
                <Typography
                className="user-profile-inner-grid-typography"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6"
                >
                  Email Address
                </Typography>
                <span>
                  <Custom.Input
                  disabled
                    name="email"
                    value={email}
                    InputProps={{
                      readOnly: true,
                    }}
                    placeholder="example@email.com"
                    size="small"
              
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                className="user-profile-inner-grid-typography"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6"
                >
                  Department
                </Typography>
                <span>
                  <Custom.Input
                  disabled
                    name="department"
                    value={dept}
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                    style={{
                      height: 44,
                      alignItems: "center",
                      display: "flex",
                    }}
                  ></Custom.Input>
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                className="user-profile-inner-grid-typography"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6"
                >
                  Designation
                </Typography>
                <span>
                  <Custom.Input
                  disabled
                  name="designation"
                  placeholder="Designation"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={designation}
                  size="small"
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                className="user-profile-inner-grid-typography"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6"
                >
                  Phone Number
                </Typography>
                <span>
                  <Custom.Phone
                  disabled
                    // className={classes.phoneWidth}
                    name="phone"
                    label="0000000000"
                    value={phone}
                    InputProps={{
                      readOnly: true,
                    }}
                    size="small"
                  />
                </span>
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <Typography
                className="user-profile-inner-grid-typography"
                  style={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    color: "#000",
                  }}
                  variant="h6">
                  Joining Date
                </Typography>
                <span>
                  <Custom.DatePicker
                    disabled
                    // className={classes.dateWidth}
                    name="dateOfJoin"
                    value={joiningdate}
                    size="small"
                    InputProps={{
                      readOnly: true,
                    }}
                
                  />
                </span>
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid
                item
                xs={12}
                md={6}
                className="user-profile-inner-grid"
                style={{ display: "flex", flexDirection: "column" }}
              >
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <QuestionMarkIcon
                    style={{
                      marginTop: "13px",
                      marginRight: "3px",
                      color: "white",
                      width: "20px",
                      height: "20px",
                      margin: " 2px 7px 2px 0",
                      backgroundColor: "#fb2485",
                      borderRadius: "10px",
                    }}
                  />
                  <Typography
                  className="user-profile-inner-grid-typography"
                    style={{
                      marginTop: 0,
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontWeight: 300,
                      fontStretch: "normal",
                      fontStyle: "normal",
                      lineHeight: "normal",
                      letterSpacing: "normal",
                      textAlign: "left",
                      color: "#000",
                    }}
                    variant="h6"
                  >
                    Contact Admin/HR for support
                  </Typography>
                </div>
              </Grid>
            </Grid>
        </>)}
      </Grid>
    </Box>
  );
}
