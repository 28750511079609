import React, { useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import PropTypes from "prop-types";

import CircularProgress from "@mui/material/CircularProgress";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import axios from "axios";
import Cookies from "js-cookie";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import CardActionArea from "@mui/material/CardActionArea";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { VolunteerActivismOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import ReactLoading from "react-loading";
import QuizQuestions from "./quizQuestions";
import expback from "../../../assets/images/expback.png";
import Tooltip from '@mui/material/Tooltip';
import "./quiz.css";
import API from "../../../api/index";

import { makeStyles } from "@material-ui/core";
const api1 = API.Api1;
const api2 = API.Api2;
const styles = makeStyles((theme) => ({
  cardMedia: {
    "& .MuiCardMedia-root": {
      width: "280px !important",
    },
  },
}));

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="white">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function QuizExpand(props) {
  const classes = styles();
  const [subcatimportdata, setSubCatImportData] = useState();
  const [subCatId, setSubCatId] = useState();
  const [levelId, setLevelId] = useState();
  const [levelId01, setLevelId01] = useState();
  const [duration, setDuration] = useState();
  const [done, setDone] = useState(undefined);
  const [countdown, setCountdown] = useState();
  const [statuss, setStatus] = useState();

  const [progress, setProgress] = useState(10);

  // setSubCatId
  const location = useLocation();

  // console.log(location.state.data, "Location data of quiz expand");
  // console.log(props,"testvalue");

  // console.log(subcatdata,"sub")
  const [cardData, setCardData] = useState([]);
  // const{data}=props;
  // console.log(data,"subcategoryname")
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const navigate = useNavigate();

  const handleQuestions = (id,param) => {
    // console.log(param,"param")
    localStorage.removeItem('minsecs');
    localStorage.setItem(
      "countdownfromapi",
      param
    );
     location.state.data.levelid=id;
     location.state.data.countdown_time=param;
     //console.log(id,"iddddd")
    setLevelId01(id);
    setDuration(param);
    // Cookies.remove("jwt_token");
    // navigate("/user/home/quizQuestions");
    
    
  };

  // setSubCatImportData(subcatdata);
  useEffect(() => {
    const subcatdata = location.state.data.dp_sub_caty_name;
    const subcatid = location.state.data.dp_sub_caty_pk;
    // //console.log(subcatdata, "Subcatdata")
    //console.log(subcatid, "subcatid")

    GetCompetencyLevels();
    // getDeparmentApi();
    setSubCatImportData(subcatdata);
    setSubCatId(subcatid);

    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 0 : prevProgress + 10
      );
    }, 800);
    return () => {
      clearInterval(timer);
    };
  }, []);

  const jwtToken = Cookies.get("jwt_token");
  // //console.log(jwtToken,"jwt")



    const GetCompetencyLevels = async () => {
      const raw = JSON.stringify({
        dp_id:location.state.data.dp_fk2,
        dp_caty_id:location.state.data.dp_caty_fk,
        dp_sub_caty_id:location.state.data.dp_sub_caty_pk ,
    })
      const headers = {
        token: 'qwerty',
        'Content-Type': 'application/json',
        "x-auth-token" : `${jwtToken}`
      };
      //console.log(raw,"rawdata");
      const config = { headers };
      try {
        const response = await axios.post(
            `${api1}/user/competency_levels/`,raw,
            config
          );
          // console.log(response, "response of levels");
   
          const result = response;
          //console.log(result.data.data[0].competency_levels,'result of levels');
          const fulldata = result.data.data[0].competency_levels;
          const levelid = result.data.data[0].competency_levels.competency_pk;
          const countdown_time =
            result.data.data[0].competency_levels.countdown_time;
          //console.log(levelid,"iddddd")
          setDone(true);
          setCardData(fulldata);
          setCountdown(result.data.data[0].competency_levels[0].countdown_time);
          setStatus(result.data.data[0].competency_levels[0].status);
          //console.log(countdown_time,"countdown_time")
        } catch (e) {
          //console.log('something went wrong!', e);
          // setRes(true);
        }
    };
      

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box style={{ padding: "36px", marginBottom: "100px", height: "100%" }}>
          <Grid container spacing={0}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "-50px",
              }}
            >
              <h4
                style={{
                  fontSize: "1.3750em",
                  fontFamily: "Poppins",
                  color: "white",
                  fontStretch: "normal",
                  letterSpacing: "normal",
                }}
              >
                Quiz
              </h4>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                backgroundColor: "white",
                marginLeft: "15px",
                marginTop: "-20px",
                borderRadius: "40px !important",
                padding: "0px !important",
                height: "100%",
                marginBottom: "100px",
              }}
              className="quiz-alignment-grid"
            >
              <Grid
                className="card-exp-img"
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                  color: "white",
                  height: "95px",
                }}
              >
                <Grid item xs={2}></Grid>
                <Grid
                  item
                  xs={8}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "18px",
                    fontFamily: "Poppins",

                    fontWeight: "bold",
                    fontStretch: "normal",
                    fontStyle: "normal",
                  }}
                >
                  {subcatimportdata}
                </Grid>

                <Grid
                  item
                  xs={2}
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    color: "orange",
                  }}
                >
                  {/* <CircularProgressWithLabel   value={30} color="inherit" /> */}
                </Grid>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "15px",
                  // padding:'0px !important'
                }}
              >
                <Grid item xs={1}></Grid>
                <Grid item xs={12} className="quiz-header-text">
                  Welcome to the {subcatimportdata} Quiz. This Quiz consists of
                  3 levels, i.e., Basic, Intermediate, and Advanced. You can
                  start at the level that you wish to take up first, you keep
                  answering the questions until the test finishes.You will be
                  rewarded with ABSYZ Credits for each correct answer. You can
                  take each quiz a maximum of upto 3 times to earn maximum ABSYZ
                  Credits.
                  <br />
                  <br />
                  If you earn sufficient amount of ABSYZ Credits, you can choose
                  your goodie(s) from the Goodie Bank, and the same can be
                  collected during the induction week.
                </Grid>
                <Grid item xs={1}></Grid>
              </Grid>
              <Grid item xs={12} style={{ display: "flex" }}>
                <Grid
                  container
                  spacing={2}
                  // className="filterGrid"
                  style={{
                    display: "flex",
                    // justifyContent: "flex-start",
                    // padding: "10px 0px 10px 0px",
                    // marginLeft: "100px",
                    marginRight: "160px",
                    width: "100%",
                    // marginTop: "40px",
                  }}
                  className="card-grid-outer"

                >
                  {cardData.map((data) => {
                    const levelname = data.competency_level;
                    const levelid = data.competency_pk;
                    const countdown_time = data.countdown_time;
                    const questioncredits = data.question_credits;
                    const noofquestions = data.no_of_questions;
                    const totalCredits = questioncredits * noofquestions;
                    const cardstatus = data.status;
                    const queststatus = data.present_status;
                    data.deptid = location.state.data.dp_fk2;
                    data.caegorytid = location.state.data.dp_caty_fk;
                    // console.log(data,"dataaa");

                    return (
                      <>
                        {cardstatus === true ? (
                          <>
                          {queststatus === 0 ?
                           <>
                           <Grid item xs={1}></Grid>
                           <Grid item xs={12} sm={4} md={3}   className="quiz-grid-content" style={{display:{sm:'flex'},justifyContent:{sm:'center'}}} >
                             <div style={{ padding: "10px" }} className="card-grid-outer">
                               {/* <Tooltip title="NO Questions Available" placement="top" arrow> */}
                               <Card
                                 sx={{
                                   minWidth: 280,
                                   maxHeight: 240,
                                   borderTopLeftRadius: "10px",
                                   borderTopRightRadius: "10px",
                                 }}
                               >
                                 <CardActionArea
                                   // component={RouterLink}
                                   //  to="/user/home/quizQuestions"
                                   state={{ data: location.state.data }}
                                   // onClick={()=>handleQuestions(levelid,countdown_time)}
                                 >
                                   <div style={{ position: "relative" }}>
                                     <div
                                       className="cardmed"
                                       sx={{
                                         backgroundBlendMode:
                                           "luminosity !important",
                                       }}
                                     >
                                       <CardMedia
                                         component="img"
                                         width="280 !important"
                                         height="120"
                                         className="cardmed"
                                         // image={expback}
                                       />
                                     </div>
                                     <div
                                       style={{
                                         position: "absolute",
                                         color: "white",
                                         fontFamily: "Poppins",
                                         fontSize: "16px",
                                         fontWeight: "bold",
                                         fontStretch: "normal",
                                         fontStyle: "normal",
                                         lineHeight: "normal",
                                         letterSpacing: "normal",
                                         top: 10,
                                         left: "50%",
                                         transform: "translateX(-50%)",
                                         textAlign:"center",
                                        //  width:'250px'
                                        marginTop:'10px'
                                       }}
                                       className="level-text"
                                     >
                                       {levelname}
                                     </div>
                                     <div
                                       style={{
                                         position: "absolute",
                                         color: "white",
                                         fontFamily: "Poppins",
                                         fontSize: "16px",
                                         fontWeight: "bold",
                                         fontStretch: "normal",
                                         fontStyle: "normal",
                                         lineHeight: "normal",
                                         letterSpacing: "normal",
                                         top: 30,
                                         left: "50%",
                                         transform: "translateX(-50%)",
                                         textAlign:"center",
                                         width:"280px",
                                        // display:'flex',
                                        justifyContent:'center',
                                        marginTop:'5px'
                                       }}
                                       className="level-text-01"
                                     >
                                       {subcatimportdata} Quiz
                                       <div className="quiz-not-class">
                                       <small style={{color:'black'}}>(Quiz Not Available)</small>
                                       </div>
                                     </div>
                                     
                                     

                                     <div
                                       style={{
                                         position: "absolute",
                                         color: "white",
                                         fontFamily: "Poppins",
                                         fontSize: "10px",
                                         fontStretch: "normal",
                                         fontStyle: "normal",
                                         lineHeight: "normal",
                                         letterSpacing: "normal",
                                         top: 100,
                                         left: "5%",
                                         textAlign:"center"
                                         // transform: "translateX(-50%)",
                                       }}
                                     >
                                       {totalCredits} Credits
                                     </div>
                                     <div
                                       style={{
                                         position: "absolute",
                                         color: "white",
                                         fontFamily: "Poppins",
                                         fontSize: "10px",
                                         fontStretch: "normal",
                                         fontStyle: "normal",
                                         lineHeight: "normal",
                                         letterSpacing: "normal",
                                         top: 100,
                                         right: "5%",
                                         textAlign:"center"
                                         // transform: "translateX(-50%)",
                                       }}
                                     >
                                       {countdown_time} minutes
                                     </div>
                                     <CardContent>
                                       <Typography
                                         sx={{
                                           fontFamily: "Poppins",
                                           fontSize: "14px",
                                           // fontWeight: 'bold',
                                           fontStretch: "normal",
                                           fontStyle: "normal",
                                           lineHeight: "normal",
                                           letterSpacing: "normal",
                                           display: "flex",
                                           justifyContent: "center",
                                           color: "black",
                                         }}
                                         className="quiz-card-below-content"
                                       >
                                         This quiz consists of 10 questions and
                                         you can earn a maximum of{" "}
                                         {totalCredits} ABSYZ Credits.{" "}
                                       </Typography>
                                     </CardContent>
                                   </div>
                                 </CardActionArea>
                               </Card>
                               {/* </Tooltip> */}
                             </div>
                           </Grid>
                         </>
                        :
                        <>
                         <Grid item xs={1}></Grid>
                            <Grid item xs={12} sm={4} md={3} className="quiz-grid-content" >
                              <div style={{ padding: "10px" }} className="card-grid-outer">
                                {/* <Tooltip title="Absyzian Talk" placement="right" arrow> */}
                                <Card
                                  sx={{
                                    minWidth: 280,
                                    maxHeight: 240,
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                >
                                  <CardActionArea
                                    component={RouterLink}
                                    to="/user/home/quizQuestions"
                                    state={{ data: location.state.data }}
                                    onClick={() =>
                                      handleQuestions(levelid, countdown_time)
                                    }
                                  >
                                    <div style={{ position: "relative" }}>
                                      <CardMedia
                                        component="img"
                                        width="280 !important"
                                        height="120"
                                        // className={classes.cardMedia}
                                        image={expback}
                                      />
                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 10,
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          marginTop:'10px'
                                        }}
                                        className="level-text"
                                      >
                                        {levelname}
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 30,
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          width:"280px",
                                          display:'flex',
                                          justifyContent:'center',
                                          marginTop:'5px'
                                        }}
                                        className="level-text-01"
                                      >
                                        {subcatimportdata} Quiz
                                      </div>

                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 100,
                                          left: "5%",
                                          // transform: "translateX(-50%)",
                                        }}
                                      >
                                        {totalCredits} Credits
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 100,
                                          right: "5%",
                                          // transform: "translateX(-50%)",
                                        }}
                                      >
                                        {countdown_time} minutes
                                      </div>
                                      <CardContent>
                                        <Typography
                                          sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            // fontWeight: 'bold',
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            display: "flex",
                                            justifyContent: "center",
                                            color: "black",
                                          }}
                                          className="quiz-card-below-content"
                                        >
                                          This quiz consists of 10 questions and
                                          you can earn a maximum of{" "}
                                          {totalCredits} ABSYZ Credits.{" "}
                                        </Typography>
                                      </CardContent>
                                    </div>
                                  </CardActionArea>
                                </Card>
                                {/* </Tooltip> */}
                              </div>
                            </Grid>
                        </>
                        }
                           
                          </>
                        ) : (
                          <>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={12} sm={4} md={3}  className="quiz-grid-content">
                              <div style={{ padding: "10px" }} className="card-grid-outer">
                                {/* <Tooltip title="Attempts Exceeded" placement="top" arrow> */}
                                <Card
                                  sx={{
                                    minWidth: 280,
                                    maxHeight: 240,
                                    borderTopLeftRadius: "10px",
                                    borderTopRightRadius: "10px",
                                  }}
                                >
                                  <CardActionArea
                                    // component={RouterLink}
                                    //  to="/user/home/quizQuestions"
                                    state={{ data: location.state.data }}
                                    // onClick={()=>handleQuestions(levelid,countdown_time)}
                                  >
                                    <div style={{ position: "relative" }}>
                                      <div
                                        className="cardmed"
                                        style={{
                                          backgroundBlendMode:
                                            "luminosity !important",
                                        }}
                                      >
                                        <CardMedia
                                          component="img"
                                          width="280 !important"
                                          height="120"
                                          className="cardmed"
                                          // image={expback}
                                        />
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 10,
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          marginTop:'10px'
                                        }}
                                        className="level-text"
                                      >
                                        {levelname}
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "16px",
                                          fontWeight: "bold",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 30,
                                          left: "50%",
                                          transform: "translateX(-50%)",
                                          width:"280px",
                                          display:'grid',
                                          justifyContent:'center',
                                          marginTop:'5px',
                                        
                                        }}
                                        className="level-text-01"
                                      >
                                        {subcatimportdata} Quiz
                                        <div className="attempts-class">
                                       <small style={{color:'black'}}>(Attempts Exceeded)</small>
                                       </div>
                                      </div>

                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 100,
                                          left: "5%",
                                          // transform: "translateX(-50%)",
                                        }}
                                      >
                                        {totalCredits} Credits
                                      </div>
                                      <div
                                        style={{
                                          position: "absolute",
                                          color: "white",
                                          fontFamily: "Poppins",
                                          fontSize: "10px",
                                          fontStretch: "normal",
                                          fontStyle: "normal",
                                          lineHeight: "normal",
                                          letterSpacing: "normal",
                                          top: 100,
                                          right: "5%",
                                          // transform: "translateX(-50%)",
                                        }}
                                      >
                                        {countdown_time} minutes
                                      </div>
                                      <CardContent>
                                        <Typography
                                          sx={{
                                            fontFamily: "Poppins",
                                            fontSize: "14px",
                                            // fontWeight: 'bold',
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            display: "flex",
                                            justifyContent: "center",
                                            color: "black",
                                          }}
                                          className="
                                          quiz-card-below-content"
                                        >
                                          This quiz consists of 10 questions and
                                          you can earn a maximum of{" "}
                                          {totalCredits} ABSYZ Credits.{" "}
                                        </Typography>
                                      </CardContent>
                                    </div>
                                  </CardActionArea>
                                </Card>
                                {/* </Tooltip> */}
                              </div>
                            </Grid>
                          </>
                        )}
                      </>
                    );
                  })}
                </Grid>
              </Grid>

              <>
                {/* {console.log(statuss,"statusfordisabling")} */}
                {statuss === true ? (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                        marginBottom: "25px",
                      }}
                    >
                      <Grid item xs={1} sm={3} md={1}></Grid>
                      <Grid item xs={10} sm={3} md={1}>
                        <Link
                          to="/user/home/quizSelection"
                          state={{ data: location.state.data }}
                          style={{ textDecoration: "none" }}
                        >
                          <ReusableButton
                            size="large"
                            variant="outlined"
                            className="candidateDltepageCancelBttn"
                            buttonName="Cancel"
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAalign: "center",
                              color: "#000936",
                              border: "1px solid #000936",
                              padding: "7px 20px",
                              borderRadius: "4px",
                              marginRight: "8px",
                              // marginBottom:"25px",
                              textTransform: "none",
                            }}
                            //   onClick={handleCloseByCancelModal}
                            onClick={() => {
                              // console.log("incancel");
                              localStorage.removeItem("minsecs");
                              localStorage.removeItem("countdownfromapi");
                            }}
                          />
                        </Link>
                      </Grid>
                      <Grid item xs={10} sm={12}></Grid>
                      <Grid item xs={10} sm={3} md={1}>
                        <Link
                          to="/user/home/quizQuestions"
                          state={{ data: location.state.data }}
                          style={{ textDecoration: "none" }}
                        >
                          <ReusableButton
                            size="large"
                            variant="contained"
                            className="candidateNextepageDeleteBttn"
                            buttonName="Next"
                            style={{
                              backgroundColor: "#fb2485",
                              // marginRight:"10px",
                              // marginBottom:"25px",
                              fontSize: "16px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAalign: "center",
                              color: "#fff",
                              padding: "8px 28px",
                              borderRadius: "4px",
                              textTransform: "none",
                            }}
                            component={RouterLink}
                            to="/user/home/quizQuestions"
                            state={{ data: location.state.data }}
                            onClick={() => handleQuestions(1, countdown)}
                          ></ReusableButton>
                        </Link>
                      </Grid>
                      <Grid item xs={1} sm={3} md={1}></Grid>
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        marginTop: "15px",
                        marginBottom: "25px",
                      }}
                    >
                      <Grid item xs={1} sm={3} md={1}></Grid>
                      <Grid item xs={10} sm={3} md={1}>
                        <Link
                          to="/user/home/quizSelection"
                          state={{ data: location.state.data }}
                          style={{ textDecoration: "none" }}
                        >
                          <ReusableButton
                            size="large"
                            variant="outlined"
                            className="candidateDltepageCancelBttn"
                            buttonName="Cancel"
                            style={{
                              fontSize: "16px",
                              fontWeight: "normal",
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                              textAalign: "center",
                              color: "#000936",
                              border: "1px solid #000936",
                              padding: "7px 20px",
                              borderRadius: "4px",
                              marginRight: "8px",
                              // marginBottom:"25px",
                              textTransform: "none",
                            }}
                            //   onClick={handleCloseByCancelModal}
                          />
                        </Link>
                      </Grid>
                      <Grid item xs={10} sm={12}></Grid>
                      <Grid item xs={10} sm={3} md={1}>
                        {/* <Link to ="/user/home/quizQuestions" state={{data:location.state.data}} style={{textDecoration:'none'}}> */}
                        <ReusableButton
                          size="large"
                          variant="contained"
                          className="candidateNextepageDeleteBttn"
                          buttonName="Next"
                          disabled
                          style={{
                            backgroundColor: "rgb(100 82 83)",
                            // marginRight: "10px",
                            // marginBottom:"25px",
                            fontSize: "16px",
                            fontWeight: "normal",
                            fontStretch: "normal",
                            fontStyle: "normal",
                            lineHeight: "normal",
                            letterSpacing: "normal",
                            textAalign: "center",
                            color: "#fff",
                            padding: "8px 28px",
                            borderRadius: "4px",
                            textTransform: "none",
                          }}
                          component={RouterLink}
                          to="/user/home/quizQuestions"
                          state={{ data: location.state.data }}
                          onClick={() => handleQuestions(1, countdown)}
                        ></ReusableButton>
                        {/* </Link> */}
                      </Grid>
                      <Grid item xs={1} sm={3} md={1}></Grid>
                    </Grid>
                  </>
                )}
              </>
            </Grid>
          </Grid>
        </Box>
      )}
    </>
  );
}
