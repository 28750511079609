import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ListSubheader from '@mui/material/ListSubheader';
import axios from 'axios';
import './weekly-does.css';
import '../news-letter/style.css';
import API from '../../../../api/index';

const api1 = API.Api1;
const api2 = API.Api2;
const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec',
];
export const WeeklyDoes = () => {
  const [weeklyData, setWeeklyData] = useState([]);
  useEffect(() => {
    getWeeklyData();
  }, []);
  const getWeeklyData = async () => {
    await axios({
      method: 'POST',
      url: `${api2}/employeeportal/getMailChimpHtml`,
      headers: { token: `qwerty`, 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        // console.log(response,"responsedddd")
        if (response.status) {
          const data = response.data.weeklyZineHtmlData;
          console.log(data);
          let ndata = [];
          data.map((items, index) => {
            // console.log(index)
            let year, month, dt;
            const d = new Date(items.create_time);
            year = d.getFullYear();
            month = d.getMonth();
            dt = d.getDate();

            if (dt < 10) {
              dt = '0' + dt;
            }

            const date = monthNames[month] + ' ' + dt;

            if (
              items.settings.from_name === 'ABSYZ HR' &&
              items.settings.title !== ''
            ) {
              let updatedData = {
                date: date,
                title: items.settings.title,
                url: items.archive_url,
              };

              ndata.push(updatedData);
              // console.log(ndata)
              setWeeklyData(ndata);
            }
          });
        }
      })
      .catch(function (error) {
        // console.log('axios error', error);
      });
  };
  return (
    <>
      <List
        style={{ maxHeight: '20em', overflow: 'auto' }}
        sx={{
          width: '100%',

          bgcolor: 'background.paper',
          padding: 0,
          borderRadius: '12px',
        }}
      >
        <ListSubheader
          component="div"  
          id="nested-list-subheader"
          className="subHeader"
          sx={{ borderTopLeftRadius: '12px', borderTopRightRadius: '12px' }}
        >
          <span
            style={{
              flexGrow: 0,
              fontFamily: 'Poppins',
              fontSize: '20px',
              fontWeight: 500,
              fontStretch: 'normal',
              fontStyle: 'normal',
              lineHeight: 'normal',
              letterSpacing: 'normal',
              textAlign: 'center',
              color: '#000',
            }}
            className="weekly-does-text"
          >
            Weekly Does
          </span>
        </ListSubheader>
        {weeklyData.map((value, index) => (
          <>
            <ListItem
              sx={{ paddingTop: '4px', paddingBottom: '4px' }}
              key={index} // disableGutters
              secondaryAction={
                <div className="TagContainer">
                  {index === 0 ? (
                    <div className="new">
                      <span className="Tag">New</span>
                    </div>
                  ) : null}

                  <ListItemText primary={value.date} className="overflow-text2" />
                </div>
              }
            >
              <a href={value.url} target="_blank">
                <ListItemText primary={value.title} className="overflow-text" />
              </a>
            </ListItem>
            <Divider
              style={{
                marginTop: '9px',
                marginBottom: '9px',
                marginLeft: '15px',
                marginRight: '15px',
                backgroundColor: '#bfbfbf',
              }}
            />
          </>
        ))}
      </List>
    </>
  );
};
