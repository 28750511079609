import React, { useState, useEffect } from "react";
import { Grid, Box, Typography } from "@mui/material";
import CircularProgressWithLabel from "./CircularProgressWithLabel";
import { useLocation } from "react-router-dom";
function Header(props) {
  const { show, subcatnamedisplay, start, callback2 } = props;
  //console.log(start);
  // console.log(props,"propsdataofheader")
  const location = useLocation();

  // console.log(location.state.data.countdown_time,"location data");

const timelimit = localStorage.getItem('countdownfromapi');

//  location.state.data.countdown_time
// console.log(timelimit,"fomlocalstorage");
const [timeForTest, setTimeForTest] = useState(timelimit);

  const [MinSecs, setMinSecs] = useState();

  
  useEffect(()=>{
    if(localStorage.getItem('minsecs')){
      // console.log(JSON.parse(localStorage.getItem('minsecs')).Minutes, "Abhi check with timer");
      setMinSecs({
        minutes: JSON.parse(localStorage.getItem('minsecs')).Minutes ,
        seconds: JSON.parse(localStorage.getItem('minsecs')).Seconds,
      })
    }
    else{
      setMinSecs({
        minutes: `${timeForTest}`,
        seconds: 0,
      })
    }
    // console.log(MinSecs.size, "Check size of array");

  }, [])
  // console.log(MinSecs,"MinSecsMinSecs")

const setFinVal=(data)=>{
  callback2(data)
  //console.log(data)
}
  return (
    <>
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "-50px",
        }}
      >
        <h4
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "-50px",
          }}
        >
          Quiz
        </h4>
      </Grid>
      <Grid
        className="card-img"
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: "white",
          height: "95px",
        }}
      >
        <Grid item xs={1}></Grid>
        <Grid
          item
          xs={9}
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "18px",
            fontFamily: "Poppins",
            fontWeight: "bold",
            fontStretch: "normal",
            fontStyle: "normal",
          }}
        >
          {subcatnamedisplay}
        </Grid>
        <Grid
          item
          xs={2}
          style={{
            display: "flex",
            justifyContent: "center",
            color: "orange",
            marginRight:'10px'
          }}
        >
          {show && MinSecs ? (
            <CircularProgressWithLabel
              MinSecs={MinSecs}
              start={start}
              value={100}
              color="inherit"
              callback={setFinVal}
            />
          ) : null}
        </Grid>
      </Grid>
    </>
  );
}

export default Header;
