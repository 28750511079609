import React, { useEffect } from 'react';

const CountDownTimer = ({ MinSecs, res, parentcallback }) => {
  // console.log(MinSecs,"incountdown")

  const { minutes = 0, seconds = 60 } = MinSecs;
 
  const [[mins, secs], setTime] = React.useState([minutes, seconds]);

  const tick = () => {
    if (mins === 0 && secs === 0) reset();
    else if (secs === 0) {
      setTime([mins - 1, 59]);
      
    } else {
      setTime([mins, secs - 1]);
      
    }
  };


  useEffect(() => {
    const timmer = {Minutes : mins, Seconds : secs}
    // console.log(timmer,"Timmer check for abhi minutes");
    // console.log(window.localStorage.getItem('minsecs'),"minsssss")
    // window.localStorage.setItem('minsecs', JSON.stringify(timmer));
    window.localStorage.setItem('minsecs', JSON.stringify(timmer));
  
  
  }, [[mins,secs]]);

  const reset = () => setTime([parseInt(0), parseInt(0)]);

  React.useEffect(() => {
    // console.log("useeffect")
    if (res) {
      //console.log(res);
      //console.log([mins, secs]);

      const timerId = setInterval(() =>{tick()
        parentcallback([mins, secs])
    
    }, 1000);
      return () => clearInterval(timerId);
    }
  });
//   useEffect(()=>{
//     tick();
//   })

  return (
    <div>
      <p style={{ fontSize: '10px' }}>{`${mins
        .toString()
        .padStart(2, '0')}:${secs.toString().padStart(2, '0')}`}</p>
    </div>
  );
};

export default CountDownTimer;
