import React from 'react';
import { FormControl, FormHelperText, makeStyles } from '@material-ui/core';
import Select from 'react-select';

// const styles = makeStyles((theme) => ({
//    main: {
//   //   '& .MuiInputLabel-formControl': {
//   //  top:"-8px"

//   //   },
//   '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
//     transform: "translate(14px, -6px) scale(0.75)",

// },

//     '& .MuiInputLabel-outlined': {

//     transform: "translate(14px,10px) scale(1)"

// }
//   },
// }));
export default function SearchDropdown(props) {
  const {
    options,
    size,
    placeholder,
    style,
    onChange,
    value,
    error = null,
  name
  } = props;
  //   const classes = styles();
  return (
    <FormControl variant="outlined" fullWidth {...(error && { error: true })}>
      <Select
      name={name}
        options={options}
        size={size}
        placeholder={placeholder}
         onChange={onChange}
        isClearable
        // onChange={opt => console.log(opt)}
        error={error}
        value={value}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}
