import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import UploadIcon from "@mui/icons-material/Upload";
import GroupIcon from "@mui/icons-material/Group";
import CampaignIcon from "@mui/icons-material/Campaign";
import CategoryIcon from "@mui/icons-material/Category";
import Inventory2Icon from '@mui/icons-material/Inventory2';
import "./menu-items.css";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import Tooltip from '@mui/material/Tooltip';
export const MenuItems = (
  <div className="nav">
    <ListItem style={{ padding: "0px" }}>
      <ListItemText style={{ color: "#FFF" }}>
        <NavLink
          to="/adminhome/"
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemIcon style={{ color: "#FFF" }}>
          <Tooltip title="Home" placement="right" arrow>
            <GroupIcon />
            </Tooltip>
          </ListItemIcon>
          <span style={{ color: "#FFF" }}>Candidates</span>
        </NavLink>
      </ListItemText>
    </ListItem>

    <ListItem style={{ padding: "0px" }}>
      <ListItemText style={{ color: "#FFF" }}>
        <NavLink
          to="/adminhome/uploadquestion"
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemIcon style={{ color: "#FFF" }}>
          <Tooltip title="Upload Questions" placement="right" arrow>
            <UploadIcon />
            </Tooltip>
          </ListItemIcon>
          <span style={{ color: "#FFF" }}>Upload Question</span>
        </NavLink>
      </ListItemText>
    </ListItem>

    <ListItem style={{ padding: "0px" }}>
      <ListItemText style={{ color: "#FFF" }}>
        <NavLink
          to="/adminhome/absyzTalk"
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemIcon style={{ color: "#FFF" }}>
          <Tooltip title="Absyzian Talk" placement="right" arrow>
            <CampaignIcon />
           </Tooltip>
         </ListItemIcon>
          <span style={{ color: "#FFF" }}>Absyzian Talk</span>
        </NavLink>
      </ListItemText>
    </ListItem>

    <ListItem style={{ padding: "0px" }}>
      <ListItemText style={{ color: "#FFF" }}>
        <NavLink
          to="/adminhome/departments"
          className={({ isActive }) => (isActive ? "link-active" : "link")}
        >
          <ListItemIcon style={{ color: "#FFF" }}>
          <Tooltip title="Departments" placement="right" arrow>
            <CategoryIcon />
            </Tooltip>
          </ListItemIcon>
          <span style={{ color: "#FFF" }}>Departments</span>
        </NavLink>
      </ListItemText>
    </ListItem>

    <ListItem style={{ padding: "0px" }}>
      <ListItemText style={{ color: "#FFF" }}>
        <NavLink
          to="/adminhome/products"
          className={({ isActive }) => (isActive ? "link-active" : "link")}>
          <ListItemIcon style={{ color: "#FFF" }}>
          <Tooltip title="Goodies Bank" placement="right" arrow>
            <Inventory2Icon />
            </Tooltip>
          </ListItemIcon>
          <span style={{ color: "#FFF" }}>Products</span>
        </NavLink>
      </ListItemText>
    </ListItem>
  </div>
);
