import React, { useState, useEffect } from "react";
import Typography from "@mui/material/Typography";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Box, Paper } from "@material-ui/core";
import { styled } from "@mui/material/styles";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import Notification from "../../../components/Custom/Notification";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./departments.css";
import Edit from "@mui/icons-material/Edit";
import EditDepartment from "./editdepartmentpopup";
import EditCategory from "./editcategorypopup";
import EditSubCategory from "./editsubcategorypopup";
import SubCategoryAdd from "./subcategoryadd";
import { padding } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import API from "../../../api/index";
import ReactLoading from "react-loading";
import { Custom } from "../../../components/Custom/Custom";
import { FormHelperText } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const api1 = API.Api1;
const api2 = API.Api2;

const dpDropdownValues = [
  {
    id: "Y",
    title: "Active",
  },
  {
    id: "N",
    title: "Inactive",
  },
];

const dpAddNewDropdownValues = [
  {
    id: "Y",
    title: "Yes",
  },
  {
    id: "N",
    title: "No",
  },
];

const inputErrorMsg = {
  forDepname: "",
  forDetStatus: "",
};

const inputFieldErrorStatus = {
  depNameStatus: false,
  depStatusStatus: false,
};

const inputErrorMsgNewCategory = {
  forDepname: "",
  forDetStatus: "",
};

const inputFieldErrorStatusNewcategory = {
  depNameStatus: false,
  depStatusStatus: false,
};

function Departments() {
  const [cards, setCards] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  const [openCategory, setOpenCategory] = useState(false);
  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [departid, setDepartid] = useState("");
  const [categoryid, setCategoryid] = useState("");
  const [departid01, setDepartid01] = useState("");
  const [categoryid01, setCategoryid01] = useState("");
  const [maxwidth, setMaxWidth] = useState("xs");
  const [fullWidth, setFullWidth] = useState(true);
  const [done, setDone] = useState(undefined);
  const [deptDatas, setDeptDatas] = useState({
    deptname: "",
    deptstatus: "",
  });
  const [catDatas, setCatDatas] = useState({
    catname: "",
    catstatus: "",
  });
  const [subCatDatas, setSubCatDatas] = useState({
    subcatname: "",
    subcatstatus: "",
  });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [dp_name, setDp_Name] = useState("");
  const [dp_status, setDp_Status] = useState("");
  const [dp_caty_name, setDp_Caty_Name] = useState("");
  const [dp_caty_status, setDp_Caty_Status] = useState("");
  const [dp_sub_caty_name, setDp_Sub_Caty_Name] = useState("");
  const [dp_sub_caty_status, setDp_Sub_Caty_Status] = useState("");
  const [expanded, setExpanded] = useState("panel1");
  const [id, setId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [id01, setId01] = useState("");
  const [openCatModal, setOpenCatModal] = useState(false);
  const [id02, setId02] = useState("");
  const [openSubCatModal, setOpenSubCatModal] = useState(false);
  const [openDeptEdit, setOpenDeptEdit] = useState(false);
  const [editDeptId, setEditDeptId] = useState();
  const [openCatEdit, setOpenCatEdit] = useState(false);
  const [editCatId, setEditCatId] = useState();
  const [openSubCatEdit, setOpenSubCatEdit] = useState(false);
  const [editSubCatId, setEditSubCatId] = useState();
  const [openSubCatAdd, setOpenSubCatAdd] = useState(false);
  const [addCatId, setAddCatId] = useState();
  const [addDeptId, setAddDeptId] = useState();
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [errorStatusNewCat, setErrorStatusNewCat] = useState(
    inputFieldErrorStatusNewcategory
  );
  const [errorMsgNewCat, setErrormsgNewCat] = useState(
    inputErrorMsgNewCategory
  );
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [loadingCatDialog, setLoadingCatDialog] = useState(false);
  //  const [age, setAge] = React.useState('');

  //   const handleChangeSelect = (event) => {
  //     setAge(event.target.value);
  //   };
  const [intialrendrer, setintialrendrer] = useState(0);
  // console.log('Initialrenderer', intialrendrer);

  const handleChangeExpansion = (panel) => (event, newExpanded) => {
    // console.log('Inside handleChange');
    setExpanded(newExpanded ? panel : false);
    setintialrendrer(1);
  };

  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    borderRadius: "10px",
    // backgroundColor:'black',
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={
        <ArrowBackIosNewIcon
          style={{ fontSize: "0.9rem", color: "white !important" }}
          color="white"
        />
      }
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === "dark"
        ? "rgba(255, 255, 255, .05)"
        : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(270deg)",
    },
    "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
  }));

  //category states
  //   const [catDatas,setCatDatas] = useState({
  //     catname:"",
  //     catst
  //   });

  useEffect(() => {
    getAllData();
    // console.log(dp_sub_caty_name, 'dp_sub_caty_name');
  }, []);

  const getAllData = async () => {
    await fetch(`${api1}/admin/departments/alldepartments`)
      .then((res) => res.json())
      .then(setCards)
      .then(() => {
        setDone(true);
      });
  };

  const handleChange = (e) => {
    const value = e.target.value;
    // console.log(value);
    setDeptDatas({
      ...deptDatas,
      [e.target.name]: value,
    });
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setDp_Name("");
    setDp_Status("");
  };

  const handleClickOpenCategory = (event, param) => {
    // console.log(event);
    // console.log(param);
    setDepartid(param);
    setOpenCategory(true);
  };

  const handleClickOpenSubCategory = (event, param1, param2) => {
    // console.log(event);
    // console.log(param1, 'catid');
    // console.log(param2, 'deptid');
    setAddCatId(param1);
    setAddDeptId(param2);
    setOpenSubCatAdd(true);
  };
  //   console.log(categoryid)

  const handleCloseCategory = () => {
    setOpenCategory(false);
    setDp_Caty_Name("");
    setDp_Caty_Status("");
  };
  const handleCloseSubCategory = () => {
    setOpenSubCategory(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (dp_name === "") {
      setErrorStatus({ ...errorStatus, depNameStatus: true });
      setInputFieldErrors({ forDepname: "Enter Department Name" });
    } else if (dp_status === "") {
      setErrorStatus({
        ...errorStatus,
        depStatusStatus: true,
        depNameStatus: false,
      });
      setInputFieldErrors({
        ...inputFieldErrors,
        forDetStatus: "Please Select Status",
      });
    } else {
      setLoadingDialog(true);
      setErrorStatus({
        ...errorStatus,
        depStatusStatus: false,
        depNameStatus: false,
      });
      const userData = {
        dp_name: dp_name,
        dp_status: dp_status,
      };
      // console.log(userData, 'userdata');
      await axios
        .post(`${api1}/admin/departments`, userData)
        .then((response) => {
          console.log(response, "res");
          // console.log(userData, 'userdata');
          if (response.data.api_status === 200) {
            setLoadingDialog(false);
            handleClose();
            getAllData();
            setNotify({
              isOpen: true,
              message: "Department added successfully",
              type: "success",
            });
          } else if (response.data.api_status === 502) {
            setLoadingDialog(false);
            setNotify({
              isOpen: true,
              message: "The value cannot be accepted(duplicate value found)",
              type: "error",
            });
          } else {
            setLoadingDialog(false);
            setNotify({
              isOpen: true,
              message: "Department cannot be added",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //editing department

  const handleEditDepartment = (id) => {
    // console.log(id, 'EditId');
    setOpenDeptEdit(true);
    setEditDeptId(id);
  };

  const handleEditDeptClose = () => {
    setEditDeptId("");
    setOpenDeptEdit(false);
  };

  // Deleting Department

  const handleClickOpenDelModal = (depid) => {
    setId(depid);
    // console.log(depid, 'On click Icon');

    // setExpanded(true);
    //handleChangeExpansion('panel1',true);
    setOpenModal(true);
  };
  const handleCloseByCancelModal = (depid) => {
    setOpenModal(false);
  };

  const handleDeleteDepartment = async (id) => {
    setOpenModal(false);
    //  alert("clickled")
    //  setdataid(id);
    // console.log(id, 'Delete id');
    // console.log(openModal, 'delete modal open');
    const response = await axios.delete(`${api1}/admin/departments/${id}`);
    // console.log(response, 'delete response');
    // if(response.status === 200){
    getAllData();

    setNotify({
      isOpen: true,
      message: "Department deleted successfully",
      type: "success",
    });
    //  }
  };

  const handleSubmitCategory = async (e) => {
    e.preventDefault();
    if (dp_caty_name === "") {
      setErrorStatusNewCat({ ...errorStatusNewCat, depNameStatus: true });
      setErrormsgNewCat({ forDepname: "Enter Category Name" });
    } else if (dp_caty_status === "") {
      setErrorStatusNewCat({
        ...errorStatusNewCat,
        depNameStatus: false,
        depStatusStatus: true,
      });
      setErrormsgNewCat({
        ...inputFieldErrors,
        forDetStatus: "Please Select Status",
      });
    } else {
      setLoadingCatDialog(true);
      setErrorStatusNewCat({
        ...errorStatusNewCat,
        depNameStatus: false,
        depStatusStatus: false,
      });
      const userData01 = {
        dp_caty_name: dp_caty_name,
        dp_caty_status: dp_caty_status,
        dp_fk: departid,
      };
      // console.log(userData01);
      await axios
        .post(`${api1}/admin/dp_categories`, userData01)
        .then((response) => {
          console.log(response, "resofcategory");
          // console.log(userData01, 'userdata01');
          if (response.data.api_status === 200) {
            setLoadingCatDialog(false);
            handleCloseCategory();
            getAllData();
            setNotify({
              isOpen: true,
              message: "Category added successfully",
              type: "success",
            });
          } else if (response.data.api_status === 502) {
            setLoadingCatDialog(false);
            setNotify({
              isOpen: true,
              message: "The value cannot be accepted(duplicate value found)",
              type: "error",
            });
          } else {
            setLoadingCatDialog(false);
            setNotify({
              isOpen: true,
              message: "Category cannot be added",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  //editing category

  const handleEditCategory = (id) => {
    // console.log(id, 'EditId');
    setOpenCatEdit(true);
    setEditCatId(id);
  };

  const handleEditCatClose = () => {
    setEditCatId("");
    setOpenCatEdit(false);
  };
  function UserGreeting() {
    // if(initialrender == 0)
    return (
      <Accordion
        expanded={expanded === "panel1"}
        onChange={handleChangeExpansion("panel1")}
        style={{
          borderBottomLeftRadius: "0px !important",
          borderTopLeftRadius: "10px !important",
          borderTopRightRadius: "10px !important",
          borderBottomRightRadius: "0px !important",
        }}
      ></Accordion>
    );
  }

  //deleting category
  const handleClickOpenDelCatModal = (catid) => {
    setId01(catid);
    // console.log(catid);

    // setExpanded(true);
    //handleChangeExpansion('panel1',true);
    setOpenCatModal(true);
  };
  const handleCloseByCancelCatModal = () => {
    setOpenCatModal(false);
  };

  const handleDeleteCategory = async (id01) => {
    //  alert("clickled")
    // setdataid(id01);
    // console.log(id01, 'Delete catid');
    await axios
      .delete(`${api1}/admin/dp_categories/${id01}`)
      .then((response) => {
        getAllData();
        setOpenCatModal(false);
        setNotify({
          isOpen: true,
          message: "Category deleted successfully",
          type: "success",
        });
      });
  };

  //adding subcategory

  const handleAddSubCatClose = () => {
    setOpenSubCatAdd(false);
  };

  // const handleAddSubCat = (id) => {
  //   console.log(id, "EditId")
  //   setOpenSubCatAdd(true);
  //   setAddCatId(id);
  // };

  // const handleSubmitSubCategory = async (e) => {
  //   // setOpen(false);
  //   e.preventDefault();
  //   const userData02 = {
  //     dp_sub_caty_name: dp_sub_caty_name,
  //     dp_sub_caty_status: dp_sub_caty_status,
  //     dp_fk2: departid01,
  //     dp_caty_fk: categoryid01,
  //   };
  //   console.log(userData02);
  //   await axios
  //     .post("${api1}/admin/sub_dept", userData02)
  //     .then((response) => {
  //       console.log(response, "resofsubcat");
  //       console.log(userData02, "userdata02");
  //       if (response.status === 200){
  //         setOpenSubCategory(false);
  //         getAllData();

  //       setNotify({
  //         isOpen: true,
  //         message: ' Sub-Category added successfully',
  //         type: 'success',
  //       });
  //       } else {
  //         setNotify({
  //           isOpen: true,
  //           message: 'Sub-Category cannot be added',
  //           type: 'error',
  //         });
  //       }

  //     })
  //     .catch((error) => {
  //       if (error.response) {
  //         console.log(error.response);
  //         console.log("server responded");
  //       } else if (error.request) {
  //         console.log("network error");
  //       } else {
  //         console.log(error);
  //       }
  //     });
  // };

  //editing subcategory

  const handleEditSubCategory = (id) => {
    console.log(id, "EditsubcatId");
    setOpenSubCatEdit(true);
    setEditSubCatId(id);
  };

  const handleEditSubCatClose = () => {
    setEditSubCatId("");
    setOpenSubCatEdit(false);
  };

  //deleting sub category

  const handleClickOpenDelSubCatModal = (subcatid) => {
    setId02(subcatid);
    // console.log(subcatid);

    // setExpanded(true);
    //handleChangeExpansion('panel1',true);
    setOpenSubCatModal(true);
  };
  const handleCloseByCancelSubCatModal = () => {
    setOpenSubCatModal(false);
  };

  const handleDeleteSubCategory = async (id02) => {
    //  alert("clickled")
    // setdataid(id);
    // console.log(id02, 'Delete subcatid');
    await axios.delete(`${api1}/admin/sub_dept/${id02}`).then((response) => {
      getAllData();
      setOpenSubCatModal(false);
      setNotify({
        isOpen: true,
        message: "Sub-Category deleted successfully",
        type: "success",
      });
    });
  };

  //   console.log(cards[0].dept.dp_id);

  //console.log(cat_name,"catname");

  console.log(dp_status, "departmentStatus");

  //const cat = catData.dept[0].department_category;
  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <ReactLoading
            type={"spinningBubbles"}
            color={"#fb2485"}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <>
          <Box style={{ padding: "16px", marginBottom: "150px" }}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                style={{ textAlign: "end", paddingRight: "80px" }}
              >
                <h4
                  style={{
                    fontSize: "1.3750em",
                    fontFamily: "Poppins",
                    color: "white",
                    fontStretch: "normal",
                    letterSpacing: "normal",
                  }}
                >
                  Departments
                </h4>
              </Grid>
              <Grid
                item
                xs={4}
                style={{
                  textAlign: "end",
                  margin: "auto",
                  paddingRight: "10px",
                }}
              >
                <ReusableButton
                  size="small"
                  // maxWidth={maxwidth} fullWidth={fullWidth}

                  style={{
                    backgroundColor: "white",
                    color: "black",
                    textTransform: "none",
                    color: "#000936",
                    borderRadius: "5px",
                  }}
                  variant="contained"
                  onClick={handleClickOpen}
                  //   type={type}
                  //    className={`${className}`}
                  startIcon={<AddIcon />}
                  buttonName="Add New Department"
                />
                <Dialog
                  open={open}
                  onClose={handleClose}
                  style={{ padding: "16px" }}
                  maxWidth={maxwidth}
                  fullWidth={fullWidth}
                >
                  {/* <Demo/> */}
                  {/* <DialogTitle>Upload ABSYZian's Talk Link</DialogTitle> */}

                  {/* <CandidateForm/> */}
                  <form onSubmit={handleSubmit}>
                    <DialogTitle style={{ marginLeft: "-8px" }}>
                      Add New Department
                    </DialogTitle>
                    <DialogContent>
                      <Box sx={{ flexGrow: 1, padding: "16px !important" }}>
                        <Grid container>
                          <Grid item xs={12} md={12}>
                            <div>
                              <b>
                                <label
                                  style={{
                                    marginBottom: "4px",
                                    justifyContent: "start",
                                    display: "flex",
                                  }}
                                >
                                  Department Name
                                </label>
                              </b>
                            </div>
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <ReusableInputfield
                              style={{
                                justifyContent: "center",
                                display: "flex",
                              }}
                              type="text"
                              name="deptname"
                              value={dp_name}
                              placeholder="Department name"
                              size="medium"
                              onChange={
                                (e) => setDp_Name(e.target.value)
                                // ,
                                // setErrorStatus({
                                //   ...errorStatus,
                                //   depNameStatus: false,
                                // })
                              }
                            />
                            {errorStatus.depNameStatus && (
                              <FormHelperText style={{ color: "red" }}>
                                {inputFieldErrors.forDepname}
                              </FormHelperText>
                            )}
                          </Grid>
                          <Grid item xs={12} md={12}>
                            <div>
                              <b>
                                <label
                                  style={{
                                    marginBottom: "4px",
                                    justifyContent: "start",
                                    display: "flex",
                                    paddingTop: "10px",
                                  }}
                                >
                                  Status
                                </label>
                              </b>
                            </div>
                          </Grid>
                          <Grid item xs={4} md={4}>
                            {/* <ReusableInputfield
                        style={{ justifyContent: 'center', display: 'flex' }}
                        type="text"
                        name="deptstatus"
                        value={dp_status}
                        placeholder="Give Y/N"
                        size="medium"
                        onChange={(e) => setDp_Status(e.target.value)}
                      /> */}
                            {/* <InputLabel id="demo-controlled-open-select-label">
                              Select Option
                            </InputLabel> */}
                            {/* <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              name="deptstatus"
                              value={dp_status}
                              label="Age"
                              size="large"
                              style={{
                                justifyContent: "start",
                                display: "flex",
                              }}
                              defaultValue={"Y"}
                              autoWidth
                              onChange={(e) => setDp_Status(e.target.value)}
                            >
                              <MenuItem value={"Y"} selected>
                                Active
                              </MenuItem>
                              <MenuItem value={"N"}>Inactive</MenuItem>
                            </Select> */}
                            <Custom.Dropdown3
                              name="deptstatus"
                              value={dp_status}
                              options={dpDropdownValues}
                              // label="Status"
                              defaultValue="Y"
                              onChange={
                                (e) => setDp_Status(e.target.value)
                                // setErrorStatus({
                                //   ...errorStatus,
                                //   depStatusStatus: false,
                                // })
                              }
                              style={{
                                justifyContent: "start",
                                display: "flex",
                                width: "100%",
                              }}
                              error={errorStatus.depStatusStatus}
                              errorMsg={inputFieldErrors.forDetStatus}
                            />
                          </Grid>
                        </Grid>
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <ReusableButton
                        onClick={handleClose}
                        size="large"
                        variant="outlined"
                        buttonName="Cancel"
                        style={{
                          color: "#000936",
                          border: "1px solid #000936",
                          marginRight: "10px",
                          marginBottom: "20px",
                        }}
                      >
                        Cancel
                      </ReusableButton>
                      <ReusableButton
                        type="submit"
                        size="large"
                        variant="contained"
                        buttonName={
                          loadingDialog ? <CircularProgress size={25} /> : "Add"
                        }
                        style={{
                          backgroundColor: "#000936",
                          marginRight: "30px",
                          marginBottom: "20px",
                        }}
                      ></ReusableButton>
                    </DialogActions>
                  </form>
                </Dialog>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={3}
              style={{
                marginTop: "10px",
                paddingLeft: "24px !important",
                paddingRight: "24px !important",
              }}
            >
              {cards.map((data, key) => {
                //console.log(data.dept[0].department_category);
                const depid = data.dept[0].dp_id;
                const cat_name = data.dept[0].department_category;
                //console.log(cat_name);
                return (
                  <Grid
                    // spacing={3}
                    item
                    xs={3}
                    key={key}
                    style={{
                      paddingLeft: "24px !important",
                      paddingRight: "24px !important",
                    }}
                    className="accordion-outer"
                  >
                    {/* <UserGreeting> */}
                    {intialrendrer == 0 ? (
                      <>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChangeExpansion("panel1")}
                          style={{
                            borderBottomLeftRadius: "0px !important",
                            borderTopLeftRadius: "10px !important",
                            borderTopRightRadius: "10px !important",
                            borderBottomRightRadius: "0px !important",
                          }}
                        >
                          <AccordionSummary
                            style={{
                              color: "white",
                              backgroundColor: "#fb2485",
                              borderBottomLeftRadius: "0px !important",
                              borderTopLeftRadius: "10px !important",
                              borderTopRightRadius: "10px !important",
                              borderBottomRightRadius: "0px !important",
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Grid container spacing={2}>
                              <Grid item xs={8}>
                                <Typography>{data.dept[0].name}</Typography>
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton
                                  aria-label="edit"
                                  size="small"
                                  onClick={() => handleEditDepartment(depid)}
                                >
                                  <EditIcon fontSize="inherit" />
                                </IconButton>
                              </Grid>
                              <Grid item xs={2}>
                                <IconButton
                                  aria-label="delete"
                                  size="small"
                                  onClick={() => handleClickOpenDelModal(depid)}
                                >
                                  <DeleteOutlineIcon fontSize="inherit" />
                                </IconButton>
                                <Dialog
                                  open={openModal}
                                  className="dialogBox"
                                  // TransitionComponent={Transition}
                                  style={{ padding: "16px !important" }}
                                  keepMounted
                                  onClose={handleCloseByCancelModal}
                                  aria-describedby="alert-dialog-slide-description"
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "transparent",
                                      boxShadow: "0px 0px 0px 0px !important",
                                      boxShadow: "none !important",
                                    },
                                  }}
                                  //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                >
                                  <Box
                                    sx={{
                                      flexGrow: 1,
                                      padding: "16px !important",
                                    }}
                                  >
                                    <DialogContent
                                    //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                    >
                                      <DialogContentText
                                        id="alert-dialog-slide-description"
                                        sx={{ color: "#000", padding: "16px" }}
                                      >
                                        Are you sure you want to delete the
                                        department?
                                      </DialogContentText>
                                    </DialogContent>
                                  </Box>
                                  <DialogActions>
                                    <ReusableButton
                                      size="large"
                                      variant="outlined"
                                      className="candidateDltepageCancelBttn"
                                      buttonName="Cancel"
                                      style={{
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAalign: "center",
                                        color: "#000936",
                                        border: "1px solid #000936",
                                        padding: "7px 20px",
                                        borderRadius: "4px",
                                        marginRight: "8px",
                                        // marginBottom:"25px",
                                        textTransform: "none",
                                      }}
                                      onClick={handleCloseByCancelModal}
                                    />
                                    <ReusableButton
                                      size="large"
                                      variant="contained"
                                      className="candidateDltepageDeleteBttn"
                                      buttonName="Delete"
                                      style={{
                                        backgroundColor: "#fb2485",
                                        marginRight: "10px",
                                        // marginBottom:"25px",
                                        fontSize: "16px",
                                        fontWeight: "normal",
                                        fontStretch: "normal",
                                        fontStyle: "normal",
                                        lineHeight: "normal",
                                        letterSpacing: "normal",
                                        textAalign: "center",
                                        color: "#fff",
                                        padding: "8px 28px",
                                        borderRadius: "4px",
                                        textTransform: "none",
                                      }}
                                      onClick={() => handleDeleteDepartment(id)}
                                    >
                                      Delete
                                    </ReusableButton>
                                  </DialogActions>
                                </Dialog>
                              </Grid>
                            </Grid>
                          </AccordionSummary>

                          {cat_name.map((eachdata, key) => {
                            //console.log(eachdata.dpc_name, 'dpcname');
                            //console.log(eachdata.dpc_id, 'dpcid');
                            const catid = eachdata.dpc_id;
                            const subcat_name =
                              eachdata.department_sub_category;
                            //console.log(subcat_name);

                            //console.log(catid);
                            return (
                              //category goes here
                              <Accordion key={key}>
                                <AccordionSummary
                                  style={{ backgroundColor: "#f1f1f1" }}
                                  expandIcon={<ExpandMoreIcon />}
                                  aria-controls="panel1a-content"
                                  id="panel1a-header"
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={8}>
                                      <Typography>
                                        {eachdata.dpc_name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <IconButton
                                        aria-label="edit"
                                        size="small"
                                        onClick={() =>
                                          handleEditCategory(catid)
                                        }
                                      >
                                        <EditIcon fontSize="inherit" />
                                      </IconButton>
                                    </Grid>
                                    <Grid item xs={2}>
                                      <IconButton
                                        aria-label="delete"
                                        size="small"
                                      >
                                        <DeleteOutlineIcon
                                          fontSize="inherit"
                                          onClick={() =>
                                            handleClickOpenDelCatModal(catid)
                                          }
                                        />
                                      </IconButton>
                                      <Dialog
                                        open={openCatModal}
                                        className="dialogBox"
                                        // TransitionComponent={Transition}
                                        keepMounted
                                        onClose={handleCloseByCancelCatModal}
                                        aria-describedby="alert-dialog-slide-description"
                                        BackdropProps={{
                                          style: {
                                            backgroundColor: "transparent",
                                            boxShadow:
                                              "0px 0px 0px 0px !important",
                                          },
                                        }}
                                        //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                      >
                                        <DialogTitle></DialogTitle>
                                        <DialogContent
                                        //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                        >
                                          <DialogContentText
                                            id="alert-dialog-slide-description"
                                            style={{ color: "#000" }}
                                          >
                                            Are you sure you want to delete the
                                            category?
                                          </DialogContentText>
                                        </DialogContent>
                                        <DialogActions>
                                          <ReusableButton
                                            size="large"
                                            variant="outlined"
                                            className="candidateDltepageCancelBttn"
                                            buttonName="Cancel"
                                            style={{
                                              fontSize: "16px",
                                              fontWeight: "normal",
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "normal",
                                              textAalign: "center",
                                              color: "#000936",
                                              border: "1px solid #000936",
                                              padding: "7px 20px",
                                              borderRadius: "4px",
                                              marginRight: "8px",
                                              // marginBottom:"25px",
                                              textTransform: "none",
                                            }}
                                            onClick={
                                              handleCloseByCancelCatModal
                                            }
                                          />
                                          <ReusableButton
                                            size="large"
                                            variant="contained"
                                            className="candidateDltepageDeleteBttn"
                                            buttonName="Delete"
                                            style={{
                                              backgroundColor: "#fb2485",
                                              marginRight: "10px",
                                              // marginBottom:"25px",
                                              fontSize: "16px",
                                              fontWeight: "normal",
                                              fontStretch: "normal",
                                              fontStyle: "normal",
                                              lineHeight: "normal",
                                              letterSpacing: "normal",
                                              textAalign: "center",
                                              color: "#fff",
                                              padding: "8px 28px",
                                              borderRadius: "4px",
                                              textTransform: "none",
                                            }}
                                            onClick={() =>
                                              handleDeleteCategory(id01)
                                            }
                                          >
                                            Delete
                                          </ReusableButton>
                                        </DialogActions>
                                      </Dialog>
                                    </Grid>
                                  </Grid>
                                </AccordionSummary>

                                {subcat_name.map((eachsubdata, key) => {
                                  // console.log(
                                  //   eachsubdata.dpsc_name,
                                  //   'dpscname'
                                  // );
                                  // console.log(eachsubdata.dpsc_id, 'dpscid');
                                  const subcatid = eachsubdata.dpsc_id;
                                  // console.log(subcatid);
                                  return (
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1a-content"
                                      id="panel1a-header"
                                      key={key}
                                    >
                                      <Grid container spacing={2}>
                                        <Grid item xs={8}>
                                          <Typography>
                                            {eachsubdata.dpsc_name}
                                          </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <IconButton
                                            aria-label="edit"
                                            size="small"
                                            onClick={() =>
                                              handleEditSubCategory(subcatid)
                                            }
                                          >
                                            <EditIcon fontSize="inherit" />
                                          </IconButton>
                                        </Grid>
                                        <Grid item xs={2}>
                                          <IconButton
                                            aria-label="delete"
                                            size="small"
                                          >
                                            <DeleteOutlineIcon
                                              fontSize="inherit"
                                              onClick={() =>
                                                handleClickOpenDelSubCatModal(
                                                  subcatid
                                                )
                                              }
                                            />
                                          </IconButton>
                                          <Dialog
                                            open={openSubCatModal}
                                            className="dialogBox"
                                            // TransitionComponent={Transition}
                                            keepMounted
                                            onClose={
                                              handleCloseByCancelSubCatModal
                                            }
                                            aria-describedby="alert-dialog-slide-description"
                                            BackdropProps={{
                                              style: {
                                                backgroundColor: "transparent",
                                                boxShadow:
                                                  "0px 0px 0px 0px !important",
                                              },
                                            }}
                                            //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                          >
                                            <DialogTitle></DialogTitle>
                                            <DialogContent
                                            //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                            >
                                              <DialogContentText
                                                id="alert-dialog-slide-description"
                                                style={{ color: "#000" }}
                                              >
                                                Are you sure you want to delete
                                                the sub-category?
                                              </DialogContentText>
                                            </DialogContent>
                                            <DialogActions>
                                              <ReusableButton
                                                size="large"
                                                variant="outlined"
                                                className="candidateDltepageCancelBttn"
                                                buttonName="Cancel"
                                                style={{
                                                  fontSize: "16px",
                                                  fontWeight: "normal",
                                                  fontStretch: "normal",
                                                  fontStyle: "normal",
                                                  lineHeight: "normal",
                                                  letterSpacing: "normal",
                                                  textAalign: "center",
                                                  color: "#000936",
                                                  border: "1px solid #000936",
                                                  padding: "7px 20px",
                                                  borderRadius: "4px",
                                                  marginRight: "8px",
                                                  // marginBottom:"25px",
                                                  textTransform: "none",
                                                }}
                                                onClick={
                                                  handleCloseByCancelSubCatModal
                                                }
                                              />
                                              <ReusableButton
                                                size="large"
                                                variant="contained"
                                                className="candidateDltepageDeleteBttn"
                                                buttonName="Delete"
                                                style={{
                                                  backgroundColor: "#fb2485",
                                                  marginRight: "10px",
                                                  // marginBottom:"25px",
                                                  fontSize: "16px",
                                                  fontWeight: "normal",
                                                  fontStretch: "normal",
                                                  fontStyle: "normal",
                                                  lineHeight: "normal",
                                                  letterSpacing: "normal",
                                                  textAalign: "center",
                                                  color: "#fff",
                                                  padding: "8px 28px",
                                                  borderRadius: "4px",
                                                  textTransform: "none",
                                                }}
                                                onClick={() =>
                                                  handleDeleteSubCategory(id02)
                                                }
                                              >
                                                Delete
                                              </ReusableButton>
                                            </DialogActions>
                                          </Dialog>
                                        </Grid>
                                      </Grid>
                                    </AccordionSummary>
                                  );
                                })}
                                <ReusableButton
                                  size="large"
                                  maxWidth={maxwidth}
                                  fullWidth={fullWidth}
                                  style={{
                                    display: "flex",
                                    justifyContent: "start",
                                    boxShadow: "none",
                                    backgroundColor: "white",
                                    color: "black",
                                    textTransform: "none",
                                    color: "#000936",
                                    borderRadius: "0px !important",
                                  }}
                                  variant="contained"
                                  //  onClick={handleClickOpenSubCategory}
                                  onClick={(event) =>
                                    handleClickOpenSubCategory(
                                      event,
                                      catid,
                                      depid
                                    )
                                  }
                                  // onClick={() => handleEditDepartment(depid)}
                                  //   type={type}
                                  //    className={`${className}`}
                                  startIcon={<AddIcon />}
                                  buttonName="Add New Sub-Category"
                                />
                                {/* <Dialog open={openSubCategory} onClose={handleCloseSubCategory} style={{ padding: '16px !important' }} maxWidth={maxwidth} fullWidth={fullWidth}
            BackdropProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "0px 0px 0px 0px !important",
                padding: '16px !important'
              },
            }}
            >
                <form onSubmit={handleSubmitSubCategory}>
                  <DialogTitle>Add New Sub Category</DialogTitle>
                  <DialogContent>


                    <Box sx={{ flexGrow: 1 }}>

                      <Grid container >
                        <Grid item xs={12} md={12}>

                          <div>
                            <b><label style={{ marginBottom: '4px', justifyContent: 'start', display: 'flex' }}>Sub Category Name</label></b>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ReusableInputfield
                            style={{ justifyContent: 'center', display: 'flex' }}
                            type="text"
                            name="subcatname"
                            value={dp_sub_caty_name}
                            placeholder="Sub-Category name"
                            size="medium"
                            onChange={(e) => setDp_Sub_Caty_Name(e.target.value)}
                          />

                        </Grid>
                        <Grid item xs={12} md={12}>

                          <div>
                            <b><label style={{ marginBottom: '4px', justifyContent: 'start', display: 'flex' }}>Status</label></b>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ReusableInputfield
                            style={{ justifyContent: 'center', display: 'flex' }}
                            type="text"
                            name="subcatstatus"
                            value={dp_sub_caty_status}
                            placeholder="Give Y/N"
                            size="medium"
                            onChange={(e) => setDp_Sub_Caty_Status(e.target.value)}
                          />

                        </Grid>
                      </Grid>
                    </Box>

                  </DialogContent>
                  <DialogActions>
                    <ReusableButton onClick={handleCloseSubCategory} size="large" variant="outlined" buttonName="Cancel" style={{ color: '#000936', border: '1px solid #000936', marginRight: "10px", marginBottom: "20px" }} >Cancel</ReusableButton>
                    <ReusableButton
                      //  onClick={handleCloseCategory}
                      type="submit" size="large" variant="contained" buttonName="Add" style={{ backgroundColor: '#000936', marginRight: "30px", marginBottom: "20px" }}>Add</ReusableButton>
                  </DialogActions>
                </form>
              </Dialog> */}
                              </Accordion>
                            );
                          })}
                        </Accordion>
                      </>
                    ) : (
                      <Accordion
                        style={{
                          borderBottomLeftRadius: "0px !important",
                          borderTopLeftRadius: "10px !important",
                          borderTopRightRadius: "10px !important",
                          borderBottomRightRadius: "0px !important",
                        }}
                      >
                        <AccordionSummary
                          style={{
                            color: "white",
                            backgroundColor: "#fb2485",
                            borderBottomLeftRadius: "0px !important",
                            borderTopLeftRadius: "10px !important",
                            borderTopRightRadius: "10px !important",
                            borderBottomRightRadius: "0px !important",
                          }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Grid container spacing={2}>
                            <Grid item xs={8}>
                              <Typography>{data.dept[0].name}</Typography>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                aria-label="edit"
                                size="small"
                                onClick={() => handleEditDepartment(depid)}
                              >
                                <EditIcon fontSize="inherit" />
                              </IconButton>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton
                                aria-label="delete"
                                size="small"
                                onClick={() => handleClickOpenDelModal(depid)}
                              >
                                <DeleteOutlineIcon fontSize="inherit" />
                              </IconButton>
                              <Dialog
                                open={openModal}
                                className="dialogBox"
                                // TransitionComponent={Transition}
                                sx={{ padding: "16px !important" }}
                                keepMounted
                                onClose={handleCloseByCancelModal}
                                aria-describedby="alert-dialog-slide-description"
                                BackdropProps={{
                                  style: {
                                    backgroundColor: "transparent",
                                    boxShadow: "0px 0px 0px 0px !important",
                                    boxShadow: "none !important",
                                  },
                                }}
                                //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                              >
                                <DialogTitle></DialogTitle>
                                <DialogContent
                                //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                >
                                  <DialogContentText
                                    id="alert-dialog-slide-description"
                                    style={{
                                      color: "#000",
                                      padding: "16px !important",
                                    }}
                                  >
                                    Are you sure you want to delete the
                                    department?
                                  </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                  <ReusableButton
                                    size="large"
                                    variant="outlined"
                                    className="candidateDltepageCancelBttn"
                                    buttonName="Cancel"
                                    style={{
                                      fontSize: "16px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "normal",
                                      letterSpacing: "normal",
                                      textAalign: "center",
                                      color: "#000936",
                                      border: "1px solid #000936",
                                      padding: "7px 20px",
                                      borderRadius: "4px",
                                      marginRight: "8px",
                                      // marginBottom:"25px",
                                      textTransform: "none",
                                    }}
                                    onClick={handleCloseByCancelModal}
                                  />
                                  <ReusableButton
                                    size="large"
                                    variant="contained"
                                    className="candidateDltepageDeleteBttn"
                                    buttonName="Delete"
                                    style={{
                                      backgroundColor: "#fb2485",
                                      marginRight: "10px",
                                      // marginBottom:"25px",
                                      fontSize: "16px",
                                      fontWeight: "normal",
                                      fontStretch: "normal",
                                      fontStyle: "normal",
                                      lineHeight: "normal",
                                      letterSpacing: "normal",
                                      textAalign: "center",
                                      color: "#fff",
                                      padding: "8px 28px",
                                      borderRadius: "4px",
                                      textTransform: "none",
                                    }}
                                    onClick={() => handleDeleteDepartment(id)}
                                  >
                                    Delete
                                  </ReusableButton>
                                </DialogActions>
                              </Dialog>
                            </Grid>
                          </Grid>
                        </AccordionSummary>

                        {cat_name.map((eachdata, key) => {
                          //console.log(eachdata.dpc_name, 'dpcname');
                          //console.log(eachdata.dpc_id, 'dpcid');
                          const catid = eachdata.dpc_id;
                          const subcat_name = eachdata.department_sub_category;
                          //console.log(subcat_name);

                          //console.log(catid);
                          return (
                            //category goes here
                            <Accordion key={key}>
                              <AccordionSummary
                                style={{ backgroundColor: "#f1f1f1" }}
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <Grid container spacing={2}>
                                  <Grid item xs={8}>
                                    <Typography>{eachdata.dpc_name}</Typography>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <IconButton
                                      aria-label="edit"
                                      size="small"
                                      onClick={() => handleEditCategory(catid)}
                                    >
                                      <EditIcon fontSize="inherit" />
                                    </IconButton>
                                  </Grid>
                                  <Grid item xs={2}>
                                    <IconButton
                                      aria-label="delete"
                                      size="small"
                                    >
                                      <DeleteOutlineIcon
                                        fontSize="inherit"
                                        onClick={() =>
                                          handleClickOpenDelCatModal(catid)
                                        }
                                      />
                                    </IconButton>
                                    <Dialog
                                      open={openCatModal}
                                      className="dialogBox"
                                      // TransitionComponent={Transition}
                                      keepMounted
                                      onClose={handleCloseByCancelCatModal}
                                      aria-describedby="alert-dialog-slide-description"
                                      BackdropProps={{
                                        style: {
                                          backgroundColor: "transparent",
                                          boxShadow:
                                            "0px 0px 0px 0px !important",
                                        },
                                      }}
                                      //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                    >
                                      <DialogTitle></DialogTitle>
                                      <DialogContent
                                      //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                      >
                                        <DialogContentText
                                          id="alert-dialog-slide-description"
                                          style={{ color: "#000" }}
                                        >
                                          Are you sure you want to delete the
                                          category?
                                        </DialogContentText>
                                      </DialogContent>
                                      <DialogActions>
                                        <ReusableButton
                                          size="large"
                                          variant="outlined"
                                          className="candidateDltepageCancelBttn"
                                          buttonName="Cancel"
                                          style={{
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAalign: "center",
                                            color: "#000936",
                                            border: "1px solid #000936",
                                            padding: "7px 20px",
                                            borderRadius: "4px",
                                            marginRight: "8px",
                                            // marginBottom:"25px",
                                            textTransform: "none",
                                          }}
                                          onClick={handleCloseByCancelCatModal}
                                        />
                                        <ReusableButton
                                          size="large"
                                          variant="contained"
                                          className="candidateDltepageDeleteBttn"
                                          buttonName="Delete"
                                          style={{
                                            backgroundColor: "#fb2485",
                                            marginRight: "10px",
                                            // marginBottom:"25px",
                                            fontSize: "16px",
                                            fontWeight: "normal",
                                            fontStretch: "normal",
                                            fontStyle: "normal",
                                            lineHeight: "normal",
                                            letterSpacing: "normal",
                                            textAalign: "center",
                                            color: "#fff",
                                            padding: "8px 28px",
                                            borderRadius: "4px",
                                            textTransform: "none",
                                          }}
                                          onClick={() =>
                                            handleDeleteCategory(id01)
                                          }
                                        >
                                          Delete
                                        </ReusableButton>
                                      </DialogActions>
                                    </Dialog>
                                  </Grid>
                                </Grid>
                              </AccordionSummary>

                              {subcat_name.map((eachsubdata, key) => {
                                //console.log(eachsubdata.dpsc_name, 'dpscname');
                                //console.log(eachsubdata.dpsc_id, 'dpscid');
                                const subcatid = eachsubdata.dpsc_id;
                                //console.log(subcatid);
                                return (
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                    key={key}
                                  >
                                    <Grid container spacing={2}>
                                      <Grid item xs={8}>
                                        <Typography>
                                          {eachsubdata.dpsc_name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <IconButton
                                          aria-label="edit"
                                          size="small"
                                          onClick={() =>
                                            handleEditSubCategory(subcatid)
                                          }
                                        >
                                          <EditIcon fontSize="inherit" />
                                        </IconButton>
                                      </Grid>
                                      <Grid item xs={2}>
                                        <IconButton
                                          aria-label="delete"
                                          size="small"
                                        >
                                          <DeleteOutlineIcon
                                            fontSize="inherit"
                                            onClick={() =>
                                              handleClickOpenDelSubCatModal(
                                                subcatid
                                              )
                                            }
                                          />
                                        </IconButton>
                                        <Dialog
                                          open={openSubCatModal}
                                          className="dialogBox"
                                          // TransitionComponent={Transition}
                                          keepMounted
                                          onClose={
                                            handleCloseByCancelSubCatModal
                                          }
                                          aria-describedby="alert-dialog-slide-description"
                                          BackdropProps={{
                                            style: {
                                              backgroundColor: "transparent",
                                              boxShadow:
                                                "0px 0px 0px 0px !important",
                                            },
                                          }}
                                          //BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                        >
                                          <DialogTitle></DialogTitle>
                                          <DialogContent
                                          //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
                                          >
                                            <DialogContentText
                                              id="alert-dialog-slide-description"
                                              style={{ color: "#000" }}
                                            >
                                              Are you sure you want to delete
                                              the sub-category?
                                            </DialogContentText>
                                          </DialogContent>
                                          <DialogActions>
                                            <ReusableButton
                                              size="large"
                                              variant="outlined"
                                              className="candidateDltepageCancelBttn"
                                              buttonName="Cancel"
                                              style={{
                                                fontSize: "16px",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                                letterSpacing: "normal",
                                                textAalign: "center",
                                                color: "#000936",
                                                border: "1px solid #000936",
                                                padding: "7px 20px",
                                                borderRadius: "4px",
                                                marginRight: "8px",
                                                // marginBottom:"25px",
                                                textTransform: "none",
                                              }}
                                              onClick={
                                                handleCloseByCancelSubCatModal
                                              }
                                            />
                                            <ReusableButton
                                              size="large"
                                              variant="contained"
                                              className="candidateDltepageDeleteBttn"
                                              buttonName="Delete"
                                              style={{
                                                backgroundColor: "#fb2485",
                                                marginRight: "10px",
                                                // marginBottom:"25px",
                                                fontSize: "16px",
                                                fontWeight: "normal",
                                                fontStretch: "normal",
                                                fontStyle: "normal",
                                                lineHeight: "normal",
                                                letterSpacing: "normal",
                                                textAalign: "center",
                                                color: "#fff",
                                                padding: "8px 28px",
                                                borderRadius: "4px",
                                                textTransform: "none",
                                              }}
                                              onClick={() =>
                                                handleDeleteSubCategory(id02)
                                              }
                                            >
                                              Delete
                                            </ReusableButton>
                                          </DialogActions>
                                        </Dialog>
                                      </Grid>
                                    </Grid>
                                  </AccordionSummary>
                                );
                              })}
                              <ReusableButton
                                size="large"
                                maxWidth={maxwidth}
                                fullWidth={fullWidth}
                                style={{
                                  display: "flex",
                                  justifyContent: "start",
                                  boxShadow: "none",
                                  backgroundColor: "white",
                                  color: "black",
                                  textTransform: "none",
                                  color: "#000936",
                                }}
                                variant="contained"
                                //  onClick={handleClickOpenSubCategory}
                                onClick={(event) =>
                                  handleClickOpenSubCategory(
                                    event,
                                    catid,
                                    depid
                                  )
                                }
                                // onClick={() => handleEditDepartment(depid)}
                                //   type={type}
                                //    className={`${className}`}
                                startIcon={<AddIcon />}
                                buttonName="Add New Sub-Category"
                              />
                              {/* <Dialog open={openSubCategory} onClose={handleCloseSubCategory} style={{ padding: '16px !important' }} maxWidth={maxwidth} fullWidth={fullWidth}
            BackdropProps={{
              style: {
                backgroundColor: "transparent",
                boxShadow: "0px 0px 0px 0px !important",
                padding: '16px !important'
              },
            }}
            >
                <form onSubmit={handleSubmitSubCategory}>
                  <DialogTitle>Add New Sub Category</DialogTitle>
                  <DialogContent>


                    <Box sx={{ flexGrow: 1 }}>

                      <Grid container >
                        <Grid item xs={12} md={12}>

                          <div>
                            <b><label style={{ marginBottom: '4px', justifyContent: 'start', display: 'flex' }}>Sub Category Name</label></b>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ReusableInputfield
                            style={{ justifyContent: 'center', display: 'flex' }}
                            type="text"
                            name="subcatname"
                            value={dp_sub_caty_name}
                            placeholder="Sub-Category name"
                            size="medium"
                            onChange={(e) => setDp_Sub_Caty_Name(e.target.value)}
                          />

                        </Grid>
                        <Grid item xs={12} md={12}>

                          <div>
                            <b><label style={{ marginBottom: '4px', justifyContent: 'start', display: 'flex' }}>Status</label></b>
                          </div>
                        </Grid>
                        <Grid item xs={12} md={12}>
                          <ReusableInputfield
                            style={{ justifyContent: 'center', display: 'flex' }}
                            type="text"
                            name="subcatstatus"
                            value={dp_sub_caty_status}
                            placeholder="Give Y/N"
                            size="medium"
                            onChange={(e) => setDp_Sub_Caty_Status(e.target.value)}
                          />

                        </Grid>
                      </Grid>
                    </Box>

                  </DialogContent>
                  <DialogActions>
                    <ReusableButton onClick={handleCloseSubCategory} size="large" variant="outlined" buttonName="Cancel" style={{ color: '#000936', border: '1px solid #000936', marginRight: "10px", marginBottom: "20px" }} >Cancel</ReusableButton>
                    <ReusableButton
                      //  onClick={handleCloseCategory}
                      type="submit" size="large" variant="contained" buttonName="Add" style={{ backgroundColor: '#000936', marginRight: "30px", marginBottom: "20px" }}>Add</ReusableButton>
                  </DialogActions>
                </form>
              </Dialog> */}
                            </Accordion>
                          );
                        })}
                      </Accordion>
                    )}
                    {/* </UserGreeting> */}
                    <ReusableButton
                      size="large"
                      maxWidth={maxwidth}
                      fullWidth={fullWidth}
                      style={{
                        display: "flex",
                        justifyContent: "start",
                        backgroundColor: "#f1f1f1",
                        color: "black",
                        textTransform: "none",
                        color: "#000936",
                        borderBottomLeftRadius: "10px !important",
                        borderBottomRightRadius: "10px !important",
                        borderTopLeftRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                      variant="contained"
                      //  onClick={handleClickOpenCategory(depid)}
                      onClick={(event) => handleClickOpenCategory(event, depid)}
                      //   type={type}
                      //    className={`${className}`}
                      startIcon={<AddIcon />}
                      buttonName="Add New Category"
                    />
                    <Dialog
                      open={openCategory}
                      onClose={handleCloseCategory}
                      sx={{ paddingTop: "16px", paddingLeft: "16px" }}
                      BackdropProps={{
                        style: {
                          backgroundColor: "transparent",
                          boxShadow: "0px 0px 0px 0px !important",
                        },
                      }}
                      overlayStyle={{ backgroundColor: "transparent" }}
                      maxWidth={maxwidth}
                      fullWidth={fullWidth}
                    >
                      <form onSubmit={handleSubmitCategory}>
                        <DialogTitle style={{ marginLeft: "-8px" }}>
                          Add New Category
                        </DialogTitle>
                        <DialogContent>
                          <Box sx={{ flexGrow: 1, padding: "16px !important" }}>
                            <Grid container>
                              <Grid item xs={12} md={12}>
                                <div>
                                  <b>
                                    <label
                                      style={{
                                        marginBottom: "4px",
                                        justifyContent: "start",
                                        display: "flex",
                                      }}
                                    >
                                      Category Name
                                    </label>
                                  </b>
                                </div>
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <ReusableInputfield
                                  style={{
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                  type="text"
                                  name="catname"
                                  value={dp_caty_name}
                                  placeholder="Category name"
                                  size="medium"
                                  onChange={(e) =>
                                    setDp_Caty_Name(e.target.value)
                                  }
                                />
                                {errorStatusNewCat.depNameStatus && (
                                  <FormHelperText style={{ color: "red" }}>
                                    {errorMsgNewCat.forDepname}
                                  </FormHelperText>
                                )}
                              </Grid>
                              <Grid item xs={12} md={12}>
                                <div>
                                  <b>
                                    <label
                                      style={{
                                        marginBottom: "4px",
                                        justifyContent: "start",
                                        display: "flex",
                                        paddingTop: "10px",
                                      }}
                                    >
                                      Status
                                    </label>
                                  </b>
                                </div>
                              </Grid>
                              <Grid item xs={4} md={4}>
                                {/* <ReusableInputfield
                            style={{
                              justifyContent: "center",
                              display: "flex",
                            }}
                            type="text"
                            name="catstatus"
                            value={dp_caty_status}
                            placeholder="Give Y/N"
                            size="medium"
                            onChange={(e) => setDp_Caty_Status(e.target.value)}
                          /> */}
                                {/* <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  name="catstatus"
                                  value={dp_caty_status}
                                  label="Age"
                                  size="large"
                                  style={{
                                    justifyContent: "start",
                                    display: "flex",
                                  }}
                                  defaultValue={"Y"}
                                  autoWidth
                                  onChange={(e) =>
                                    setDp_Caty_Status(e.target.value)
                                  }
                                >
                                  <MenuItem value={"Y"}>Yes</MenuItem>
                                  <MenuItem value={"N"}>No</MenuItem>
                                </Select> */}
                                <Custom.Dropdown3
                                  name="catstatus"
                                  value={dp_caty_status}
                                  options={dpAddNewDropdownValues}
                                  // label="Status"
                                  defaultValue="Y"
                                  onChange={(e) =>
                                    setDp_Caty_Status(e.target.value)
                                  }
                                  style={{
                                    justifyContent: "start",
                                    display: "flex",
                                    width: "100%",
                                  }}
                                  error={errorStatusNewCat.depStatusStatus}
                                  errorMsg={errorMsgNewCat.forDetStatus}
                                />
                              </Grid>
                            </Grid>
                          </Box>
                        </DialogContent>
                        <DialogActions>
                          <ReusableButton
                            onClick={handleCloseCategory}
                            size="large"
                            variant="outlined"
                            buttonName="Cancel"
                            style={{
                              color: "#000936",
                              border: "1px solid #000936",
                              marginRight: "10px",
                              marginBottom: "20px",
                            }}
                          >
                            Cancel
                          </ReusableButton>
                          <ReusableButton
                            // onClick={handleCloseCategory}
                            type="submit"
                            size="large"
                            variant="contained"
                            buttonName={
                              loadingCatDialog ? (
                                <CircularProgress size={25} />
                              ) : (
                                "Add"
                              )
                            }
                            style={{
                              backgroundColor: "#000936",
                              marginRight: "30px",
                              marginBottom: "20px",
                            }}
                          >
                            Add
                          </ReusableButton>
                        </DialogActions>
                      </form>
                    </Dialog>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Notification notify={notify} setNotify={setNotify} />
          <EditDepartment
            open={openDeptEdit}
            id={editDeptId}
            handleClose={handleEditDeptClose}
            getAllData={getAllData}
          />
          <EditCategory
            open={openCatEdit}
            id={editCatId}
            handleClose={handleEditCatClose}
            getAllData={getAllData}
          />
          <EditSubCategory
            open={openSubCatEdit}
            id={editSubCatId}
            handleClose={handleEditSubCatClose}
            getAllData={getAllData}
          />
          <SubCategoryAdd
            open={openSubCatAdd}
            categoryid01={addCatId}
            departid01={addDeptId}
            handleClose={handleAddSubCatClose}
            getAllData={getAllData}
          />
        </>
      )}
    </>
  );
}

export default Departments;
