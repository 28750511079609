import React, { useState } from "react";
import {
  styled,
  createTheme,
  ThemeProvider,
  alpha,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import InputBase from "@mui/material/InputBase";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
//import AccountCircle from "@mui/icons-material/AccountCircle";
import LogoutIcon from '@mui/icons-material/Logout';
import MailIcon from "@mui/icons-material/Mail";
import MoreIcon from "@mui/icons-material/MoreVert";
import Container from "@mui/material/Container";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
// import { mainListItems } from "./listItems";
import {MenuItems} from "../menu-items/menu-items"
import './admin-layout.css'
import Departments from "../departments/departments";
import { Outlet } from "react-router-dom";
import { makeStyles } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import UserHomePage from "../../users/user-layout/user-layout";
 import Cookies from "js-cookie";
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;


const drawerWidth = 240;
const styles = makeStyles((theme) => ({
  main: {
    '& .MuiDialog-paper': {
      maxWidth: '1000px',
    },
  },
  phoneWidth01: {
    '& .MuiOutlinedInput-adornedStart': {
      width: '283px !important',
    },

   
    },
    openDrawer: {
      '& .MuiPaper-root': {
        backgroundColor: '#000936',
      },
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

const mdTheme = createTheme();

function AdminHomePage(props) {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const navigate = useNavigate();
  const classes = styles();
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  // const handleMobileMenuClose = () => {
  //   setMobileMoreAnchorEl(null);
  // };

  // const handleMenuClose = () => {
  //   setAnchorEl(null);
  //   handleMobileMenuClose();
  // };

  // const handleMobileMenuOpen = (event) => {
  //   setMobileMoreAnchorEl(event.currentTarget);
  // };

  const onClickLogout = () => {
    Cookies.remove("admin_token");
    navigate("/adminlogin");
    setAnchorEl(null);
    
  };

  const menuId = "primary-search-account-menu";
  

  

  // To open and close the menu Icon
  const toggleDrawer = () => {
    setOpen(!open);
  };

  return (
    <ThemeProvider theme={mdTheme}>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="absolute" style={{ backgroundColor: '#000936' }} open={open}>
          <Toolbar
            sx={{
              pr: "24px", // keep right padding when drawer closed
            }}
            className="column"
          >
            <IconButton
              edge="start"
              color="inherit"
              size="large"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                // marginRight: "12px",
                paddingRight:"15px !important",
                ...(open && { display: "none" }),
              }}
            >
              <MenuIcon className="menuiconBtn"/>
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ display: { xs: "none", sm: "block" } }}
              style={{ alignSelf: 'start', textAlign: 'start', color: 'white', fontFamily: 'Poppins-Bold', fontWeight: '500', fontSize: 25,marginTop:'11px',marginLeft:'40px' }}>
              ABSYZ Lounge
            </Typography>
            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={onClickLogout}
                color="inherit"
              >
                <LogoutIcon className="logoutbtn"/>
              </IconButton>
            </Box>
            {/* <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box> */}
          </Toolbar>
        </AppBar>
    

        <Drawer variant="permanent" open={open} >
          <Toolbar style={{ backgroundColor: '#000936',display:"flex",justifyContent:"end" }}
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              px: [1],
               backgroundColor: '#000936'
            }}
          >
            {/* <img
              alt="logo"
              src="https://sp-ao.shortpixel.ai/client/to_auto,q_lossless,ret_img,w_126,h_61/https://absyz.com/wp-content/uploads/2020/06/absyz-mobile-logo-1.png"
              style={{
                width: "130px",
                marginLeft: "45px",
                textAlign: "center",
                backgroundColor:'white'
              }}
            /> */}
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon style={{
                color:'white'
              }} />
            </IconButton>
          </Toolbar>
          <Divider style={{backgroundColor:"#000035"}}/>
          <List component="nav" className={classes.openDrawer} style={{ backgroundColor: '#000936',height:"100%" }}>{MenuItems}</List>
        </Drawer>
        <Box
          component="main"
          // className='layout-size'
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "hidden !important",
          }}
        >
          <Toolbar />
          <Container className="adminlayout" maxWidth="xxl" sx={{minheight:"40vh",padding:"0px !important"}}>
            <Outlet />
            {/* <Departments/> */}
            {/* <UserHomePage/> */}
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

export default AdminHomePage;