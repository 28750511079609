import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import PropTypes from 'prop-types';

import {
  Grid,
  Box,
  Typography,
  Button,
  DialogContentText,
} from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import CircularProgressWithLabel from './CircularProgressWithLabel';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import CountDownTimer from '../quiz/quiz-timer';
import ReactLoading from 'react-loading';
import ReusableButton from '../../../components/reusable-button/reusable-button';
import { DataObject, Panorama } from '@mui/icons-material';
import Header from './Header';
import QuizLayout from './quizLayout';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import './style.css';
import API from '../../../api/index';

const api1 = API.Api1;
const api2 = API.Api2;
function Questions(props) {
  const { callback, showRes, fin, loaded } = props;
  const location = useLocation();
  const [subcatnamedisplay, setSubCatNameDisplay] = useState();
  const [question, setQuestion] = useState([]);
  const [optionsOpted, setOptionsOpted] = useState('');
  const [value, setValue] = useState();
  const [questIndex, setQuestIndex] = useState(0);
  const [questId, setQuestId] = useState();
  const [quizId, setQuizId] = useState();
  const [results, setResults] = useState();
  const [done, setDone] = useState(undefined);
  const [res, setRes] = useState(false);
  const [start, setStart] = useState(false);
  const [visited, setVisited] = useState(0);
  const [answered, setAnswered] = useState([]);

  //console.log("subcatname",location.state.data.dp_sub_caty_pk)
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  // //console.log("quizid",quizId)

  const locationdataquestions = location.state.data;

  useEffect(() => {
    //console.log('file nameeee');

    getAllQuestions();
    // ResultsOfQuiz();
    setSubCatNameDisplay(location.state.data.dp_sub_caty_name);

    // getDeparmentApi();
    // subCategoryData();
  }, []);

  //const {res}=props
  const jwtToken = Cookies.get('jwt_token');

  const handleChange = (event, index) => {
    setValue(event.target.value);
    setOptionsOpted(event.target.value);
    //     //console.log(value, 'val');
    //    //console.log("called",index)
    //    var i= visited.indexOf(index);
    //    while (i > -1) {
    //        visited.splice(i, 1);
    //        i = visited.indexOf(index);
    //    }
    setAnswered([...answered, index]);
    //console.log(visited, 'visit');
    //console.log(answered, 'ans');
  };
  useEffect(() => {
    if (fin) {
      setShowModal(true);
      //apiCall(quizId, question[visited].question_pk);
    }
  }, [fin]);

  const getAllQuestions = async () => {
    let raw = 0;
    //alert("called")
    if (location.state.data.categoryvalue != '') {
      //console.log(location.state.data.categoryvalue, 'fgfgf');
      raw = JSON.stringify({
        department: location.state.data.depvalue,
        subCategory: location.state.data.dp_sub_caty_pk,
        category: location.state.data.categoryvalue,
        efficiencyLevel: location.state.data.levelid,
      });
    } else {
      //console.log(location.state.data.categoryvalue, 'fgfgfg');
      //console.log(location.state.data.dp_fk2, 'lll');
      raw = JSON.stringify({
        department: location.state.data.dp_fk2,
        subCategory: location.state.data.dp_sub_caty_pk,
        category: location.state.data.dp_caty_fk,
        efficiencyLevel: location.state.data.levelid,
      });
      //console.log(raw, 'rawdata1');
      setDone(true);
    }

    const headers = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw, 'rawdata');
    const config = { headers };
    try {
      const response = await axios.post(`${api1}/user/questions`, raw, config);
      console.log(response,"response of questions api")
      
      setQuestion(response.data.data[0].questions);
      setQuizId(response.data.data[0].quizz_id);
      setQuestId(response.data.data[0].questions[0].question_pk);
      setOptionsOpted(response.data.data[0].questions[0].userOption);
      setAnswered(response.data.data[0].userOptionIdx);

      setStart(true);

      //console.log(question, 'questionssssss');
      setDone(true);
      loaded(true);
      // if(response.data.api_status === 200){
      //   localStorage.setItem(
      //     "countdownfromapi",
      //     response.data.data[0].countdown_time
      //   );
      // }
      //console.log(question, 'questionssssss');
    } catch (e) {
      //console.log('something went wrong!', e);
      setRes(true);
      loaded(true);
    }
  };
  // //console.log(question, 'questionssssss after func');

  function getUniqueListBy(arr, key) {
    return [...new Map(arr.map((item) => [item[key], item])).values()];
  }

  const arr1 = getUniqueListBy(question, 'question');
  ////console.log(arr1, 'arr1');
  const navigate = useNavigate();

  const handleHome = async (e, id) => {
    e.preventDefault();
    //console.log(id,"subid")

    // const response =
    try {
      await axios
        .get(`${api1}/user/all/sub_categories/${id}`, {
          headers: { 'x-auth-token': `${jwtToken}` },
        })
        .then((response) => {
          //console.log(response, 'response of subcatapi')
          //console.log(response.data.data[0])
          let results = response.data.data[0];

          // setDone(true);
          //console.log(results.department_category_name, "caty_name")
          location.state.data.department_category_name =
            results.department_category_name;
          location.state.data.department_name = results.department_name;
          location.state.data.department_category_id =
            results.department_category_id;
          location.state.data.department_id = results.department_id;
          location.state.data.all_sub_categories = results.all_sub_categories;
          navigate('/user/home/quizSelection', { state: [results] });
          localStorage.removeItem('countdownfromapi'); 
        });
    } catch (e) {
      //console.log(e,"error");
    }
  };

const onClickCancel = async (e,id) =>{
  e.preventDefault();
  // console.log(id,"quizid in clickcancel")
  localStorage.removeItem('minsecs');
  localStorage.removeItem('countdownfromapi'); 
  const responseofdelete = await axios.delete(`${api1}/user/quizz-cancel/${id}`,{
    headers: { 'x-auth-token': `${jwtToken}` },
  });
  // console.log("responseofdelete",responseofdelete);
  // console.log(locationdataquestions,"locationdataquestionslocationdataquestions")
  navigate('/user/home/quizSelection', { state: { locationdataquestions} });

}

  const onClickPrevious = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");
    setVisited(param1 - 1);
    const raw = {
      user_option: optionsOpted,
      question_id: param,
    };
    const headers = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw, 'raueditdata');
    await axios
      .put(`${api1}/user/questionsValidation/${id}`, raw, { headers })
      .then((response) => {
        //console.log(id, 'deptId for edit');
        //console.log(response, 'edit Response');
        // setOptionsOpted('');
        setQuestIndex(questIndex - 1);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log('error', error));
    //getting the previous options
    const raw2 = {
      question_id: question[param1 - 1].question_pk,
    };
    const headers2 = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw2, 'raueditdata2');
    await axios
      .post(`${api1}/user/questionsValidation/${id}`, raw2, {
        headers: headers2,
      })
      .then((response) => {
        //console.log(id, 'deptId for edit');
        //console.log(response, 'get Response of the previous value');

        setOptionsOpted(response.data.data[0].option[0].user_option);
        setQuestIndex(questIndex - 1);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log('error', error));
    setShow(false);
    //console.log(optionsOpted, 'optedval');
  };

  const onClickNext = async (e, id, param1, param) => {
    e.preventDefault();
    setOptionsOpted("");

    //console.log(id, 'quizId for next button');
    //console.log(param, 'param is question id of current');
    //console.log(param1 + 1, 'param1 is index');
    //console.log(question[param1 + 1].question_pk, ' questionid for next');

    //console.log(question[param1 + 1], 'question array index');
    setVisited(param1 + 1);
    const raw = {
      user_option: optionsOpted,
      question_id: param,
    };
    const headers = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw, 'raueditdata');
    await axios
      .put(`${api1}/user/questionsValidation/${id}`, raw, { headers })
      .then((response) => {
        // console.log(id, 'deptId for edit');
        // console.log(response, 'edit Response');
        // setOptionsOpted('');
        setQuestIndex(questIndex + 1);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log('error', error));
    const raw2 = {
      question_id: question[param1 + 1].question_pk,
    };
    const headers2 = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw2, 'raueditdata2');
    await axios
      .post(`${api1}/user/questionsValidation/${id}`, raw2, {
        headers: headers2,
      })
      .then((response) => {
        // //console.log(id, 'deptId for edit');
        // //console.log(response, 'get Response of the next value');
        // //console.log(
        //   response.data.data[0].option[0].user_option,
        //   'user selected value'
        // );
        setOptionsOpted(response.data.data[0].option[0].user_option);
        setQuestIndex(questIndex + 1);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log('error', error));
    setShow(false);

    //console.log(optionsOpted, 'optedvalfornext');
  };
  const onClickSubmit = async (e, id, param) => {
    e.preventDefault();
    //console.log(id, 'id  for submit');
    //console.log(param, 'param is question id');
  
    apiCall(id, param);
  };

  const apiCall = async (id, param) => {
    //console.log('called', id, param);
    const raw = {
      user_option: optionsOpted,
      question_id: param,
    };
    const headers = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw, 'raueditdata');
    await axios
      .put(`${api1}/user/questionsValidation/${id}`, raw, { headers })
      .then((response) => {
        //console.log(response, 'last submitted Response');
        // setOptionsOpted('');
        // setQuestIndex(questIndex+1)
        // if(response.status=== 200){
        const ResultsOfQuiz = async () => {
          const response = await axios.get(
            `${api1}/user/questionsValidation/result/${id}`,
            { headers: { 'x-auth-token': `${jwtToken}` } }
          );
          console.log(response, 'response of resultsapi2');

          let result = response.data.data[0];
          //console.log(result, 'response of results');
          setResults(result);
          //console.log(results, 'reso ');
          setDone(true);

          result.dp_sub_caty_name = location.state.data.dp_sub_caty_name;
          result.dp_caty_fk = location.state.data.dp_caty_fk;
          result.dp_fk2 = location.state.data.dp_fk2;
          result.dp_sub_caty_pk = location.state.data.dp_sub_caty_pk;
          result.dp_sub_caty_status = location.state.data.dp_sub_caty_status;
          result.levelid = location.state.data.levelid;
          result.countdown_time = location.state.data.countdown_time;
          result.categoryvalue = location.state.data.categoryvalue;
          result.depvalue = location.state.data.depvalue;
          // result.name = location.state.data.dp_sub_caty_name;
          setShow(false);
          navigate('/user/home/quizResult', { state: { result } });
          localStorage.removeItem('minsecs');
          localStorage.removeItem('countdownfromapi'); 
          // //console.log(result.data.data[0].competency_levels,'result of levels');

          // setCardData(fulldata);
          // }
        };
        ResultsOfQuiz();
      })
      .catch((error) => console.log('error', error));
  };
  const handleQClick = async (index, e, id, param1, param) => {
    // setQuestIndex(index);
    setOptionsOpted("");

    setVisited(index);
    e.preventDefault();
    //console.log('index', index); //current index
    //console.log('id', id); //
    //console.log('param1', param1);
    //console.log('param', param);
    // //console.log(visited[visited.length - 1]); //index of last visited question

    // console.log(
    //   question[index].question_pk,
    //   ' questionid for new visited question'
    // );

    // console.log(question[index], 'question array index');
    // console.log(question[visited].question_pk, 'previous question id');
    const raw = {
      user_option: optionsOpted,
      question_id: question[visited].question_pk,
    };
    const headers = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw, 'raueditdata');
    await axios
      .put(`${api1}/user/questionsValidation/${id}`, raw, { headers })
      .then((response) => {
        setOptionsOpted('');
        setQuestIndex(index);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log('error', error));

    const raw2 = {
      question_id: question[index].question_pk,
    };
    const headers2 = {
      token: 'qwerty',
      'Content-Type': 'application/json',
      'x-auth-token': `${jwtToken}`,
    };
    //console.log(raw2, 'raueditdata2');
    await axios
      .post(`${api1}/user/questionsValidation/${id}`, raw2, {
        headers: headers2,
      })
      .then((response) => {
        setOptionsOpted(response.data.data[0].option[0].user_option);
        setQuestIndex(index);
        if (response.status === 200) {
        }
      })
      .catch((error) => console.log('error', error));
    setShow(false);
  };

  return (
    <>
      {showRes({ res })}

      {res ? (
        <Grid
          item
          xs={12}
          style={{
            backgroundColor: 'white',
            padding: '0px !important',
            height: '30vh',
            borderBottomRightRadius: '10px',
            borderBottomLeftRadius: '10px',
          }}
        >
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: '15px',
            }}
          >
            <Grid item xs={3}></Grid>
            <Grid item xs={6}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '25px',
                }}
              >
                <b>Questions Not Available for the selected sub-category. </b>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <b>Please try again after sometime. </b>
              </Grid>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  marginTop: '15px',
                }}
              >
                <Link
                  to="/user/home/quizSelection"
                  state={{ data: location.state.data }}
                  style={{ textDecoration: 'none' }}
                >
                  <ReusableButton
                    size="large"
                    variant="outlined"
                    className="candidateDltepageCancelBttn"
                    buttonName="Back"
                    style={{
                      fontSize: '16px',
                      fontWeight: 'normal',
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      textAalign: 'center',
                      color: '#000936',
                      border: '1px solid #000936',
                      padding: '7px 20px',
                      borderRadius: '4px',
                      marginRight: '8px',
                      // marginBottom:"25px",
                      textTransform: 'none',
                    }}
                    onClick={(e) =>
                      handleHome(e, location.state.data.dp_sub_caty_pk)
                    }
                  />
                </Link>
              </Grid>
            </Grid>
            <Grid item xs={3}></Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          {show ? (
            <>
              <Grid
                item
                xs={9.7}
                style={{
                  backgroundColor: 'white',
                  padding: '0px !important',
                  height: '70vh',
                  marginBottom:'100px',

                  borderBottomLeftRadius: '10px',
                }}
                className="Grid-In-Small-Screens"
              >
                <Box
                  sx={{ flexGrow: 1 }}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '50vh',
                  }}
                >
                  <ReactLoading
                    type={'spinningBubbles'}
                    color={'#fb2485'}
                    height={100}
                    width={100}
                  />
                </Box>
              </Grid>
              <Grid
                item
                xs={2.3}
                style={{
                  backgroundColor: '#02215d',
                  padding: '0px !important',
                  height: '70vh',
                  borderBottomRightRadius: '10px',
                  overflowY:'auto',
                  overflowX:'hidden'
                }}
                className="Grid-In-Small-Screens-Side"

              >
                <div className="container-quest">
                  {arr1.map((data, index) => {
                    return (
                      <>
                        <Button
                          className="rect1"
                          size="small"
                          onClick={(e) => {
                            handleQClick(
                              index,
                              e,
                              quizId,
                              questIndex,
                              data.question_pk
                            );
                            setShow(true);
                          }}
                        >
                          <QuizLayout
                            qnum={index + 1}
                            color={visited === index ? '#fb2485' : null}
                            ans={answered.includes(index) ? true : false}
                            visit={index == visited ? true : false}
                          />
                        </Button>
                      </>
                    );
                  })}
                </div>
              </Grid>
            </>
          ) : (
            <>
              {callback({ start })}
              {arr1.map((data, index) => {
                // console.log(data, 'dat');
                // console.log(index, 'index');
                const questionname = data.question;
                // console.log(data.question_pk, 'qpk');

                // console.log(questionname);

                // eslint-disable-next-line no-lone-blocks
                {
                  if (index === questIndex) {
                    return (
                      <>
                        <Grid
                          item
                          xs={9.7}
                          style={{
                            backgroundColor: 'white',
                            padding: '0px !important',
                            height: '70vh',
                            borderBottomLeftRadius: '10px',
                            marginBottom:'100px'
                          }}
                          className="Grid-In-Small-Screens"

                        >
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              marginTop: '15px',
                            }}
                          >
                            {/* <Grid item xs={1}></Grid> */}
                            <Grid item xs={10}>
                              <b className='questions-to-display'>
                                {' '}
                                {index + 1}.{questionname}{' '}
                              </b>
                            </Grid>
                            {/* <Grid item xs={1}>
                            </Grid> */}
                          </Grid>

                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            value={optionsOpted}
                            onChange={(e) => {
                              handleChange(e, index);
                            }}
                          >
                            {[data.options].map((opt) => {
                              //console.log(opt, 'opt');
                              // console.log(value,"val");
                              return (
                                <>
                                  {opt.a.a && opt.a.a !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'start',
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.a.id}
                                            control={<Radio />}
                                            label={opt.a.a}
                                            className="options-for-questions"
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : 
                                  <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                    }
                                  {opt.b.b && opt.b.b !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'start',
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.b.id}
                                            control={<Radio />}
                                            label={opt.b.b}
                                            className="options-for-questions"
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : 
                                  <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                    }
                                  {opt.c.c && opt.c.c !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'start',
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.c.id}
                                            control={<Radio />}
                                            label={opt.c.c}
                                            className="options-for-questions"
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : 
                                  <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                  }
                                  {opt.d.d && opt.d.d !== "null" ? (
                                    <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'start',
                                        }}
                                      >
                                        <Grid item xs={1}></Grid>
                                        <Grid
                                          item
                                          xs={10}
                                          style={{
                                            display: 'flex',
                                            justifyContent: 'start',
                                          }}
                                        >
                                          <FormControlLabel
                                            value={opt.d.id}
                                            control={<Radio />}
                                            label={opt.d.d}
                                            className="options-for-questions"
                                          />
                                        </Grid>
                                        <Grid item xs={1}></Grid>
                                      </Grid>
                                    </>
                                  ) : 
                                  <>
                                      <Grid
                                        item
                                        xs={12}
                                        style={{ height: "60px" }}
                                      ></Grid>
                                    </>
                                    }
                                    
                                  {/* <Grid item xs={12} style={{display:'flex',justifyContent:'start'}}><FormControlLabel value="fe\male" control={<Radio />} label={opt.b} /></Grid>
<Grid item xs={12} style={{display:'flex',justifyContent:'start'}}><FormControlLabel value="fe'male" control={<Radio />} label={opt.c} /></Grid>
<Grid item xs={12} style={{display:'flex',justifyContent:'start'}}><FormControlLabel value="felmale" control={<Radio />} label={opt.d} /></Grid> */}
                                </>
                              );
                            })}
                          </RadioGroup>
                        
                          <Grid
                            item
                            xs={12}
                            style={{
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              height:"15vh",
                              // flexDirection:'column'
                            }}
                            className="Button-Class-Small-Screen"
                          >
                            {/* <Grid item xs={1}></Grid> */}
                            <Grid item xs={12} sm={0.5} md={1} className="Cancel-In-Small">

                              <ReusableButton
                                size="large"
                                variant="outlined"
                                className="candidateDltepageCancelBttn"
                                buttonName="Cancel"
                                style={{
                                  fontSize: '16px',
                                  fontWeight: 'normal',
                                  fontStretch: 'normal',
                                  fontStyle: 'normal',
                                  lineHeight: 'normal',
                                  letterSpacing: 'normal',
                                  textAalign: 'center',
                                  color: '#000936',
                                  border: '1px solid #000936',
                                  padding: '7px 20px',
                                  borderRadius: '4px',
                                  marginRight: '8px',
                                  // width: '98px',
                                  // height: '44px',
                                  marginTop: '24px',
                                  // marginBottom:"25px",
                                  textTransform: 'none',
                                }}
                                  onClick={
                                    (e) => {
                                      onClickCancel(
                                        e,
                                        quizId
                                      );
                                    }    
                                }
                              />
                              </Grid>

                            <Grid item xs={12} sm={5} md={5.5}></Grid>

                                {index === 0 ? 
                              <Grid item xs={12} sm={1} md={1} style={{display:{xs:'none',sm:'flex'}}}></Grid>
                              :
                              <Grid item xs={12} sm={1} md={1} className="Previous-In-Small">
                              <>
                              <ReusableButton
                                  size="large"
                                  variant="outlined"
                                  className="candidatePrevpageCancelBttn"
                                  buttonName="Previous"
                                  style={{
                                    // width: '98px',
                                    // height: '44px',
                                    flexGrow: 0,
                                    margin: '24px 50px 0 20px',
                                    padding: '13px 32px 13px 33px',
                                    // marginBottom:"25px",
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAalign: 'center',
                                    color: '#000936',
                                    padding: '8px 12px',
                                    borderRadius: '4px',
                                    textTransform: 'none',
                                    border: '1px solid #000936',
                                  }}
                                  // onClick={()=>setQuestIndex(questIndex-1)}
                                  onClick={(e) => {
                                    onClickPrevious(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                />
                              </>  
                              </Grid>
                              }
                            {/* <Grid item xs={index === 0 ? 0 : 12} sm={1} md={1} className="Previous-In-Small">
                              {index === 0 ? <></> : (
                                <ReusableButton
                                  size="large"
                                  variant="outlined"
                                  className="candidatePrevpageCancelBttn"
                                  buttonName="Previous"
                                  style={{
                                    // width: '98px',
                                    // height: '44px',
                                    flexGrow: 0,
                                    margin: '24px 50px 0 20px',
                                    padding: '13px 32px 13px 33px',
                                    // marginBottom:"25px",
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAalign: 'center',
                                    color: '#000936',
                                    padding: '8px 12px',
                                    borderRadius: '4px',
                                    textTransform: 'none',
                                    border: '1px solid #000936',
                                  }}
                                  // onClick={()=>setQuestIndex(questIndex-1)}
                                  onClick={(e) => {
                                    onClickPrevious(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                />
                              )}
                            </Grid> */}

                            <Grid item xs={0} sm={1.5} md={1}></Grid>
                            <Grid item xs={12} sm={1} md={1} className="Next-In-Small">
                              {index + 1 < arr1.length ? (
                                <ReusableButton
                                  size="large"
                                  variant="contained"
                                  className="candidateNextepageDeleteBttn"
                                  buttonName="Next"
                                  style={{
                                    backgroundColor: '#fb2485',
                                    // width: '98px',
                                    // height: '44px',
                                    flexGrow: 0,
                                    margin: '24px 50px 0 20px',
                                    padding: '13px 32px 13px 33px',
                                    // marginBottom:"25px",
                                    fontSize: '16px',
                                    fontWeight: 'normal',
                                    fontStretch: 'normal',
                                    fontStyle: 'normal',
                                    lineHeight: 'normal',
                                    letterSpacing: 'normal',
                                    textAalign: 'center',
                                    color: '#fff',
                                    padding: '8px 28px',
                                    borderRadius: '4px',
                                    textTransform: 'none',
                                  }}
                                  onClick={(e) => {
                                    onClickNext(
                                      e,
                                      quizId,
                                      questIndex,
                                      data.question_pk
                                    );
                                    setShow(true);
                                  }}
                                ></ReusableButton>
                              ) : (
                                <Link
                                  to="/user/home/quizResult"
                                  state={{ data: location.state.data }}
                                  style={{ textDecoration: 'none' }}
                                >
                                  <ReusableButton
                                    size="large"
                                    variant="contained"
                                    className="candidatesubmitepageDeleteBttn"
                                    buttonName="Submit"
                                    style={{
                                      backgroundColor: '#fb2485',
                                      // width: '98px',
                                      // height: '44px',
                                      flexGrow: 0,
                                      margin: '24px 50px 0 20px',
                                      padding: '13px 32px 13px 33px',
                                      // marginBottom:"25px",
                                      fontSize: '16px',
                                      fontWeight: 'normal',
                                      fontStretch: 'normal',
                                      fontStyle: 'normal',
                                      lineHeight: 'normal',
                                      letterSpacing: 'normal',
                                      textAalign: 'center',
                                      color: '#fff',
                                      padding: '8px 18px',
                                      borderRadius: '4px',
                                      textTransform: 'none',
                                    }}
                                    //    onClick={()=>setQuestIndex(questIndex+1)}
                                    onClick={(e) => {
                                      onClickSubmit(
                                        e,
                                        quizId,
                                        data.question_pk
                                      );
                                      setShow(true);
                                    }}
                                  ></ReusableButton>
                                </Link>
                              )}
                            </Grid>
                            <Grid item xs={1} sm={1} md={0.5}></Grid>
                          </Grid>
                        </Grid>
                        {show ? (
                          <Grid
                            item
                            xs={9.7}
                            style={{
                              backgroundColor: 'white',
                              padding: '0px !important',
                              height: '70vh',
                              marginBottom:'100px',
                              borderBottomLeftRadius: '10px',
                            }}
                            className="Grid-In-Small-Screens"

                          >
                            <Box
                              sx={{ flexGrow: 1 }}
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                height: '50vh',
                              }}
                            >
                              <ReactLoading
                                type={'spinningBubbles'}
                                color={'#fb2485'}
                                height={100}
                                width={100}
                              />
                            </Box>
                          </Grid>
                        ) : null}
                        <Grid
                          item
                          xs={2.3}
                          style={{
                            backgroundColor: '#02215d',
                            padding: '0px !important',
                            height: '70vh',
                            borderBottomRightRadius: '10px',
                            overflowY:'auto',
                  overflowX:'hidden'

                          }}
                          className="Grid-In-Small-Screens-Side"

                        >
                          <div className="container-quest">
                            {arr1.map((data, index) => {
                              return (
                                <>
                                  <Button
                                    className="rect1"
                                    size="small"
                                    onClick={(e) => {
                                      handleQClick(
                                        index,
                                        e,
                                        quizId,
                                        questIndex,
                                        data.question_pk
                                      );
                                      setShow(true);
                                    }}
                                  >
                                    <QuizLayout
                                      qnum={index + 1}
                                      color={
                                        visited === index ? '#fb2485' : null
                                      }
                                      ans={
                                        answered.includes(index) ? true : false
                                      }
                                      visit={index == visited ? true : false}
                                    />
                                  </Button>
                                </>
                              );
                            })}
                          </div>
                        </Grid>

                        {/* <Grid
                          item
                          xs={12}
                          style={{
                            backgroundColor: 'white',
                            padding: '0px !important',
                            height: '15vh',
                            borderBottomRightRadius: '10px',
                            borderBottomLeftRadius: '10px',
                          }}
                        >
                         
                        </Grid> */}
                      </>
                    );
                  }
                }
              })}
            </>
          )}
        </>
      )}
      <Dialog open={showModal}>
        <DialogContent>
          <DialogContentText>
            Quiz Timed Out!! Please Click ok to Submit
          </DialogContentText>
          <DialogActions>
            <Link
              to="/user/home/quizResult"
              state={{ data: location.state.data }}
              style={{ textDecoration: 'none' }}
            >
              <ReusableButton
                size="large"
                variant="contained"
                className="candidateDltepageDeleteBttn"
                buttonName="OK"
                style={{
                  backgroundColor: '#fb2485',
                  marginRight: '10px',
                  // marginBottom:"25px",
                  fontSize: '16px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAalign: 'center',
                  color: '#fff',
                  padding: '8px 28px',
                  borderRadius: '4px',
                  textTransform: 'none',
                }}
                //    onClick={()=>setQuestIndex(questIndex+1)}
                onClick={(e) => {
                  setShowModal(false);

                  onClickSubmit(e, quizId, question[visited].question_pk);
                  setShow(true);
                }}
              ></ReusableButton>
            </Link>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default Questions;
