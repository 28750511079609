import React from 'react';
import Checkbox from "@mui/material/Checkbox";

export const ReusableRememberMe = ({color,size,onChange,checked}) => {
  return (
    <>
    <Checkbox color={color} size={size} onChange={onChange} checked={checked}/>
    </>
  )
}
