import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core';
const styles = makeStyles((theme) => ({
  main: {
    padding: theme.spacing(2),
    position: 'absolute',
    top: theme.spacing(5),
  },
}));
export default function MyDialog(props) {
  const {title, children, openPopup,style, setOpenPopup} = props;
  const classes = styles();
  return (
    <Dialog
      open={openPopup}
      maxWidth="sm"
      style={style}
      classes={{ paper: classes.main }}
    >
      <DialogTitle>
        <div>
          <Typography variant="h6" component="div"style={{fontFamily:"Georgia, serif"}}>
            {title}
          </Typography>
        </div>
      </DialogTitle>

      <DialogContent >{children}</DialogContent>
    </Dialog>
  );
}
