import React, { useState, useEffect } from 'react';
//import { DataGrid } from '@mui/x-data-grid'
// import { DataTable } from '../../../components/reusable-datagrid/reusable-datgr-';
import { DataTable } from '../../../components/Custom/datagrid/datagrid';
import ReusableButton from '../../../components/reusable-button/reusable-button';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Notification from '../../../components/Custom/Notification';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ReactLoading from 'react-loading';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import './products.css';
import { Grid, Box, Paper } from '@material-ui/core';
import { MyForm, Form } from '../../../components/MyForm';
import { Custom } from '../../../components/Custom/Custom';
import EditsProduct from './edits-products';
import { makeStyles } from '@material-ui/core';
import API from '../../../api/index';
import AddPopUp from './AddPopUp';
const api1 = API.Api1;

const initialValues = {
  productName: '',
  productCredits: '',
  productImage: '',
};

const editProductData = {
  product_name: '',
  product_credits: '',
  product_image: '',
  product_id: '',
  product_status: '',
};

const styles = makeStyles((theme) => ({
  inputStyle: {
    '& .MuiOutlinedInput-root': {
      position: 'relative',
      borderRadius: '4px',
      marginTop: '8px',
    },
  },
}));
const SheetJSFT = ['jpg', 'png', 'jpeg']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

const Products = (props) => {
  const classes = styles();
  const [tableData, setTableData] = useState([]);
  // const [productDetails, setProductDetails] = useState(initialValues);
  const [productData, setProductData] = useState(editProductData);

  const [fileMerge, setFileMerge] = useState();
  const [open, setOpen] = useState(false);
  const [done, setDone] = useState(undefined);
  const [openEdit, setOpenEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState('');

  useEffect(() => {
    getProductData();
  }, []);

  const getProductData = async () => {
    await axios({
      method: 'get',
      url: `${api1}/admin/products/`,
      headers: { token: `qwerty`, 'Content-Type': 'application/json' },
    })
      .then((response) => {
        // console.log('data', response);
        if (response.status === 200) {
          // console.log("response",response.data.data[0].products)
          const data = response.data.data[0].products;

          const productData = data.map((items, index) => ({
            sno: index + 1,
            id: items.product_pk,
            productname: items.product_name,
            image: items.product_logo,
            credits: items.product_credits,
            status: items.product_status == 'Y' ? 'Active' : 'InActive',
          }));
          // console.log(productData,"productData")
          setTableData(productData);
          setDone(true);
        }
      })
      .catch(function (error) {
        //console.log('axios error', error);
      });
  };
  // //console.log(tableData,"tableData")

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ('productName' in inputValues)
      code.productName = inputValues.productName
        ? ''
        : 'Please enter a product name';

    if ('productCredits' in inputValues)
      code.productCredits = inputValues.productCredits
        ? ''
        : 'Please enter product credits';

    if ('productImage' in inputValues)
      code.productImage = inputValues.productImage ? '' : 'Image is required';
    setErrors({
      ...code,
    });
    if (inputValues === values)
      //  console.log(values, 'values');
      return Object.values(code).every((x) => x === '');
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);

  const handleEdit = async (id) => {
    // //console.log(id,"edit id")
    
    setOpenEdit(true);
    // getDepartments();
    setEditId(id);

    //console.log(id, 'finaldata');
    const response = await axios.get(`${api1}/admin/products/${id}`);
    const data = response;
    const finalData = data.data.data[0].product[0];
    //console.log(finalData.product_credits, 'finaldata');
    setProductData({
      ...productData,
      product_name: finalData.product_name,
      product_image: finalData.product_logo,
      product_credits: finalData.product_credits,
      product_id: finalData.product_pk,
      product_status: finalData.product_status,
    });
  };
  // //console.log(productData,"productafterformat")

  const handleEditClose = () => {
    setOpenEdit(false);
    // resetForm()
  };

  const columns = [
    {
      field: "sno",
      headerName: "S.No",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "productname",
      headerName: "Product Name",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "image",
      headerName: "Image",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <img src={params.value} alt="product Img" width="40" height="40" />
        </div>
      ),
    },
    {
      field: "credits",
      headerName: "No.of.Credits",
      headerClassName: "super-app-theme--header",
      flex: 1,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      type: "string",
      headerClassName: "super-app-theme--header",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      width: 160,
      renderCell: (params) => {
        return (
          <span>
            {params.row.status}

            <Switch
              checked={params.row.status === "Active"}
              // checked={false}
              size="large"
              onChange={() => {
                // //console.log(tableData);
                handleChange(params.row.id, params.row.status);
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
          </span>
        );
      },
    },
    {
      field: "actions",
      type: "Actions",
      headerName: "Actions",
      flex: 1,
      headerClassName: "super-app-theme--header",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <>
          <span
            className="editbutton"
            //style={{ display: 'flex', justifyContent: 'end !important' }}
          >
            <IconButton aria-label="edit" size="small">
              <EditIcon
                color="primary"
                fontSize="inherit"
                style={{
                  minWidth: "14px !important",
                  //fontSize: '14px !important',
                  color: "blue !important",
                }}
                onClick={() => handleEdit(params.id)}
              />
            </IconButton>
          </span>

          <span>
            <ReusableButton
              size="extra-large"
              className="qstnUpldPageBttn"
              //buttonName="Filter"
              startIcon={<DeleteOutlineIcon />}
              //onClick={() => handleDelete(params.id)}
              onClick={() => handleClickOpenDelModal(params.id)}
            />
            <Dialog
              open={openModal}
              // className="dialogBox"
              //  TransitionComponent={"Transition"}
              // BackdropProps={{ style: { backgroundColor: "transparent" } }}
              keepMounted
              onClose={handleCloseByCancelModal}
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogContent
                style={{ boxShadow: "none" }}
                BackdropProps={{
                  style: { boxShadow: "none", transition: "none" },
                }}
              >
                <DialogContentText id="alert-dialog-slide-description">
                  Are you sure you want to delete the product?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <ReusableButton
                  size="large"
                  variant="outlined"
                  className="qstnUpldPageBttn"
                  buttonName="Cancel"
                  style={{
                    // backgroundColor: "#080825",
                    color: "#080825",
                    fontFamily: "Poppins",
                    fontSize: "0.8em",
                    fontWeight: 600,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    outline: "auto",
                    borderRadius: "4px",
                  }}
                  onClick={handleCloseByCancelModal}
                />
                <ReusableButton
                  size="large"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Delete"
                  style={{
                    backgroundColor: "#fe1c7f",
                    color: "#fff",
                    fontFamily: "Poppins",
                    fontSize: "0.8em",
                    fontWeight: 200,
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    textAlign: "left",
                    borderRadius: "4px",
                  }}
                  onClick={() => handleDelete(id)}
                >
                  Delete
                </ReusableButton>
              </DialogActions>
            </Dialog>
          </span>
        </>
      ),
    },
  ];

  const handleClickOpenDelModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const handleChange = async (id, status) => {
    //console.log(tableData, 'tableData');
    if (status === 'Active') status = 'InActive';
    else if (status === 'InActive') status = 'Active';
    //console.log(status, 'again');
    let raw = {};
    tableData.map((item) => {
      if (item.id === id) {
        //console.log('matched item');
        item.status = status;
        raw = JSON.stringify({
          // video_link: item.video_link,
          // video_name: item.video_name,
          product_status: status == 'Active' ? 'Y' : 'N',
        });
      }
    });
    //console.log(tableData, 'aftertableData');
    //console.log(raw, 'raw');
    // setDone(false)

    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
    };

    await fetch(`${api1}/admin/product/${id}`, requestOptions)
      .then((response) => {
        //console.log(response, 'responseStatus');
        // getProductData();
      })
      .catch((error) => console.log('error', error));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = async (id) => {
    const requestOptions = {
      method: 'DELETE',
    };
    //console.log('requestOptions', requestOptions);
    fetch(`${api1}/admin/products/${id}`, requestOptions).then((response) => {
      //console.log(response, 'responsedELETE');
      if (response.status === 200) {
        getProductData();
        setOpenModal(false);
        setNotify({
          isOpen: true,
          message: 'Product Deleted Successfully',
          type: 'success',
        });
      } else {
        setNotify({
          isOpen: true,
          message: 'Product Not  Deleted',
          type: 'error',
        });
      }
    });
  };

 



  return (
    <>
     
      <Box
        sx={{
          flexGrow: 1,
          padding: '0px 80px 0px 80px !important',
          marginBottom: '220px',
        }}
      >
        <Box sx={{ flexGrow: 1 }} style={{ marginTop: '20px' }}>
          <Grid container spacing={2}>
            <Grid item xs={8} style={{ textAlign: 'end', paddingRight: 150 }}>
              <h4
                style={{
                  fontSize: '1.5em',
                  fontFamily: 'Poppins',
                  color: 'white',
                  fontStretch: 'normal',
                  letterSpacing: 'normal',
                }}
              >
                Goodies Bank
              </h4>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ textAlign: 'end', margin: 'auto', paddingRight: '35px' }}
            >
              <ReusableButton
                size="small"
                style={{
                  backgroundColor: 'white',
                  color: '#000936',
                  textTransform: 'initial',
                }}
                variant="contained"
                onClick={handleClickOpen}
                //   type={type}
                //    className={`${className}`}
                startIcon={<AddCircleOutlineIcon />}
                buttonName="Add New Product"
              />
              <AddPopUp
                getProductData={getProductData}
                open={open}
                setOpen={setOpen}
              />
            </Grid>
          </Grid>
        </Box>

        <div
          style={{
            width: '100%',

            textAlign: 'center',
            backgroundColor: 'white',

            borderRadius: '15px',
            marginBottom: '20px',
          }}
        >
          <Box
            sx={{
              width: '100%',
              '& .super-app-theme--header': {
                backgroundColor: '#f1f1f1',
                color: '#02215b',
                fontSize: '16px',
              },
            }}
          >
            <DataTable
              rows={tableData}
              columns={columns}
              pageSize={10}
              rowHeight={40}
              rowsPerPageOptions={[10]}
              autoHeight
              width="100%"
              // style={{ marginBottom: '20px', overFlowX: 'hidden' }}

              hideFooterSelectedRowCount={true}
            />
          </Box>
        </div>

        <EditsProduct
          open={openEdit}
          id={editId}
          handleClose={handleEditClose}
          productData={productData}
          setProductData={setProductData}
          getProductData={getProductData}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </Box>
      {/* )} */}
    </>
  );
};

export default Products;
