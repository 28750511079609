import React, { useEffect, useState } from "react";
import Notification from "../../../components/Custom/Notification";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import API from "../../../api/index";
import { Custom } from "../../../components/Custom/Custom";
import { FormHelperText } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import ReactLoading from "react-loading";

const api1 = API.Api1;
const api2 = API.Api2;

const dpSubCatDropdownValues = [
  {
    id: "Y",
    title: "Active",
  },
  {
    id: "N",
    title: "Inactive",
  },
];

const inputErrorMsg = {
  forDepname: "",
  forDetStatus: "",
};

const inputFieldErrorStatus = {
  depNameStatus: false,
  depStatusStatus: false,
};

const initialValues = {
  dpcatname: "",
  dpcatstatus: "",
  dpfk: "",
};

export default function EditCategory(props) {
  const { open, id, handleClose, getAllData, depid } = props;
  //console.log(depid, 'depid from props');
  //console.log(id, 'id from props');
  const [cat, setCat] = useState(initialValues);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleOnChange = (e) => {
    setCat({ ...cat, [e.target.name]: e.target.value });
  };

  const handleCloseCat = () => {
    handleClose();
    setCat(initialValues);
    setErrorStatus({
      ...errorStatus,
      depStatusStatus: false,
      depNameStatus: false,
    });
  };

  useEffect(() => {
    getCatById(id);
  }, [id]);

  const getCatById = async (id) => {
    setLoading(true);
    //console.log(id, 'id into get req');
    //console.log(depid, 'depid from props');

    const response = await axios.get(
      `${api1}/admin/dp_categories/category/${id}`
    );
    if (response.data.api_status === 200) {
      setLoading(false);

      const categoriesdata = response.data.data[0].department_categories[0];

      setCat({
        ...cat,
        dpcatname: categoriesdata.dp_caty_name,
        dpcatstatus: categoriesdata.dp_caty_status,
        dpfk: categoriesdata.dp_fk,
      });
    }
  };

  const { dpcatname, dpcatstatus, dpfk } = cat;

  const onSubmitEditCat = async (e, id) => {
    e.preventDefault();
    if (dpcatname === "") {
      setErrorStatus({ ...errorStatus, depNameStatus: true });
      setInputFieldErrors({ forDepname: "Enter Category Name" });
    } else if (dpcatstatus === "") {
      setErrorStatus({
        ...errorStatus,
        depStatusStatus: true,
        depNameStatus: false,
      });
      setInputFieldErrors({
        ...inputFieldErrors,
        forDetStatus: "Please Select Status",
      });
    } else {
      setLoadingDialog(true);

      setErrorStatus({
        ...errorStatus,
        depStatusStatus: false,
        depNameStatus: false,
      });
      const raw = {
        dp_caty_name: dpcatname,
        dp_caty_status: dpcatstatus,
        dp_fk: dpfk,
      };
      const headers = {
        "Content-Type": "application/json",
      };
      //console.log(raw, "raueditdata");
      await axios
        .put(`${api1}/admin/dp_categories/${id}`, raw, { headers })
        .then((response) => {
          //console.log(id, "catId for edit");
          //console.log(response, "edit cat Response");
          if (response.data.api_status === 200) {
            setLoadingDialog(false);

            handleCloseCat();
            getAllData();
            setNotify({
              isOpen: true,
              message: "Category Updated Successfully",
              type: "success",
            });
          } else if (response.data.api_status === 502) {
            setLoadingDialog(false);
            setNotify({
              isOpen: true,
              message: "The value cannot be accepted(duplicate value found)",
              type: "error",
            });
          } else {
            setLoadingDialog(false);
            setNotify({
              isOpen: true,
              message: "Category Cannot be updated",
              type: "success",
            });
          }
        })
        .catch((error) => console.log("error", error));
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "16px" }}
        //  maxWidth={maxwidth} fullWidth={fullWidth}
      >
        {loading ? (
          <Box
            sx={{ flexGrow: 1 }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
              width: "109vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <form
          // onSubmit={handleSubmitCategory}
          >
            <DialogTitle style={{ marginLeft: "-8px" }}>
              Edit Category
            </DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1, padding: "16px" }}>
                <Grid container>
                  <Grid item xs={12} md={12}>
                    <div>
                      <b>
                        <label
                          style={{
                            marginBottom: "4px",
                            justifyContent: "start",
                            display: "flex",
                          }}
                        >
                          Category Name
                        </label>
                      </b>
                    </div>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <ReusableInputfield
                      style={{ justifyContent: "center", display: "flex" }}
                      type="text"
                      name="dpcatname"
                      value={dpcatname}
                      placeholder="Category name"
                      size="medium"
                      onChange={handleOnChange}
                    />
                    {errorStatus.depNameStatus && (
                      <FormHelperText style={{ color: "red" }}>
                        {inputFieldErrors.forDepname}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <div>
                      <b>
                        <label
                          style={{
                            marginBottom: "4px",
                            justifyContent: "start",
                            display: "flex",
                            paddingTop: "10px",
                          }}
                        >
                          Status
                        </label>
                      </b>
                    </div>
                  </Grid>
                  <Grid item xs={4} md={4}>
                    {/* <ReusableInputfield
                            style={{ justifyContent: 'center', display: 'flex' }}
                            type="text"
                            name="dpcatstatus"
                            value={dpcatstatus}
                            placeholder="Give Y/N"
                            size="medium"
                            onChange={handleOnChange}
                            /> */}
                    {/* <InputLabel id="demo-controlled-open-select-label">
                    Select Option
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="dpcatstatus"
                    value={dpcatstatus}
                    // label="Age"
                    size="large"
                    defaultValue={"Y"}
                    style={{
                      justifyContent: "start",
                      display: "flex",
                    }}
                    autoWidth
                    // onChange={(e) => setDp_Caty_Status(e.target.value)}
                    onChange={handleOnChange}
                  >
                    <MenuItem value={"Y"}>Active</MenuItem>
                    <MenuItem value={"N"}>Inactive</MenuItem>
                  </Select> */}

                    <Custom.Dropdown3
                      name="dpcatstatus"
                      value={dpcatstatus}
                      options={dpSubCatDropdownValues}
                      // label="Status"
                      defaultValue="Y"
                      onChange={handleOnChange}
                      style={{
                        justifyContent: "start",

                        display: "flex",
                        width: "100%",
                      }}
                      error={errorStatus.depStatusStatus}
                      errorMsg={inputFieldErrors.forDetStatus}
                    />
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                onClick={handleCloseCat}
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={{
                  color: "#000936",
                  border: "1px solid #000936",
                  marginRight: "10px",
                  marginBottom: "20px",
                }}
              >
                Cancel
              </ReusableButton>
              <ReusableButton
                onClick={(e) => onSubmitEditCat(e, id)}
                //   type="submit"
                size="large"
                variant="contained"
                buttonName={
                  loadingDialog ? (
                    <div
                      style={{
                        width: "58px",
                        margin: "0px",
                        padding: "0px",
                        height: "27px",
                      }}
                    >
                      <CircularProgress size={25} />
                    </div>
                  ) : (
                    "Update"
                  )
                }
                style={{
                  backgroundColor: "#000936",
                  marginRight: "30px",
                  marginBottom: "20px",
                }}
              ></ReusableButton>
            </DialogActions>
          </form>
        )}
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
