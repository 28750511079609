import * as React from 'react';
import { DataGrid  } from '@mui/x-data-grid';

export const DataTable = ({rows,columns,pageSize,className,autoHeight,hideFooter,rowsPerPageOptions,rowHeight,hideFooterPagination,hideFooterSelectedRowCount})=>{
    return (
      <>
      <DataGrid
          rows={rows}
          columns={columns}
          pageSize={pageSize}
          autoHeight={autoHeight}
          rowHeight={rowHeight}
         rowsPerPageOptions={rowsPerPageOptions}
          // checkboxSelection={checkboxSelection}
          className={`${className}`} 
          hideFooter={hideFooter}
          hideFooterPagination={hideFooterPagination}
          hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        />
      </>
    );
}