import React,{useState} from 'react';
import { Grid, Box, Typography } from '@mui/material';
import CountDownTimer from './quiz-timer'
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';

function CircularProgressWithLabel(props) {
    //console.log("CircularProgressWithLabel")

const [finish,setFinish]=useState(false)

    const {start,MinSecs,callback}=props
    const setNewData = (data) => {
      
     // setMinSecs  ({ minutes: childdata[0], seconds: childdata[1] });
     // console.log(data);
      if(data[0]===0&&data[1]===0){
        setFinish(true)
        
      }
     // console.log(finish);
      callback(finish)
     
    };
 
    return (
      <>
   
        <Box sx={{ position: 'relative', display: 'inline-flex' }}>
          <CircularProgress variant="determinate" {...props} />
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="caption" component="div" color="white">
              {/* {`${timeForTest}`}  */}

              <CountDownTimer
                parentcallback={setNewData}
                res={start}
                MinSecs={MinSecs}
              />
              {/* {`${Math.round(props.value)}%`} */}
            </Typography>
          </Box>
        </Box>
      </>
    );
  }

  CircularProgressWithLabel.propTypes = {
  
    value: PropTypes.number.isRequired,
  };
export default CircularProgressWithLabel;