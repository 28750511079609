import React, { useState, useEffect } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
// import { makeStyles } from "@material-ui/core/styles";
import ListSubheader from "@mui/material/ListSubheader";
import "./style.css";
import axios from "axios";
import API from "../../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;
const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
export const NewsLetter = () => {
  const [newsData, setNewsData] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = async () => {
    await axios({
      method: "POST",
      url: `${api2}/employeeportal/getMailChimpHtml`,
      headers: { token: `qwerty`, "Content-Type": "application/json" },
    })
      .then(function (response) {
        if (response.status) {
          const data = response.data.newsHtmlData;
          console.log(data, "responseData");
          let ndata = [];
          data.map((items, index) => {
            // console.log(index)
            let year, month, dt;
            const d = new Date(items.create_time);
            year = d.getFullYear();
            month = d.getMonth();
            dt = d.getDate();

            if (dt < 10) {
              dt = "0" + dt;
            }

            const date = monthNames[month] + " " + dt;
            if (
              items.settings.from_name === "ABSYZ Newsletter" &&
              items.settings.title !== ""
            ) {
              let updatedData = {
                date: date,
                title: items.settings.title,
                url: items.archive_url,
              };
              // console.log(items.settings)
              ndata.push(updatedData);
              console.log(ndata, "newsletterarray");
              setNewsData(ndata);
            } else if (
              items.settings.from_name === "ABSYZ Newsletter" &&
              items.settings.title === ""
            ) {
              const newsLetterString = items.settings.subject_line.slice(17);
              console.log(newsLetterString, "newsLetterString");
              let updatedData = {
                date: date,
                title: newsLetterString,
                url: items.archive_url,
              };
              ndata.push(updatedData);
              console.log(ndata, "newsletterarray");
              setNewsData(ndata);
            }
          });
        }
      })
      .catch(function (error) {
        // console.log('axios error', error);
      });
  };

  console.log(newsData, "newsletterData");
  return (
    <>
      {/* {console.log(newsData)} */}
      <List
        style={{ maxHeight: "20em", overflow: "auto" }}
        sx={{
          width: "100%",

          bgcolor: "background.paper",
          padding: 0,
          borderRadius: "12px",
          // marginTop: "44px",
          overflow: "hidden",
        }}
      >
        <ListSubheader
          component="div"
          id="nested-list-subheader"
          sx={{ borderTopLeftRadius: "12px", borderTopRightRadius: "12px" }}
        >
          <span
            style={{
              flexGrow: 0,
              fontFamily: "Poppins",
              fontSize: "20px",
              fontWeight: 500,
              fontStretch: "normal",
              fontStyle: "normal",
              lineHeight: "normal",
              letterSpacing: "normal",
              textAlign: "center",
              color: "#000",
            }}
            className="newsletter-text"
          >
            Newsletter
          </span>
        </ListSubheader>
        {newsData.map((value) => (
          <>
            <ListItem
              sx={{ paddingTop: "4px", paddingBottom: "4px", }}
              key={value} // disableGutters
              secondaryAction={<ListItemText  primary={value.date} className="overflow-text2"/>}
            >
              <a
                href={value.url}
                target="_blank"
                style={{ textDecoration: "none" }}
              >
                <ListItemText primary={value.title} className="overflow-text"  />
              </a>
            </ListItem>
            <Divider
              style={{
                marginTop: "9px",
                marginBottom: "9px",
                marginLeft: "15px",
                marginRight: "15px",
                backgroundColor: "#bfbfbf",
              }}
            />
          </>
        ))}
      </List>
    </>
  );
};
