import React, { useState, useEffect } from "react";
import { Grid, Box, Paper } from "@material-ui/core";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import AddIcon from "@mui/icons-material/Add";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableInputfield } from "../../../components/reusable-input/reusable-input";
import Notification from "../../../components/Custom/Notification";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import "./departments.css";
import API from "../../../api/index";
import { Custom } from "../../../components/Custom/Custom";
import { FormHelperText } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";

const api1 = API.Api1;
const api2 = API.Api2;

const dpSubCatDropdownValues = [
  {
    id: "Y",
    title: "Active",
  },
  {
    id: "N",
    title: "Inactive",
  },
];

const inputErrorMsg = {
  forDepname: "",
  forDetStatus: "",
};

const inputFieldErrorStatus = {
  depNameStatus: false,
  depStatusStatus: false,
};

export default function SubCategoryAdd(props) {
  // const {openSubCategory,handleClose,getAllData,departid01,categoryid01}=props
  const { open, handleClose, getAllData, departid01, categoryid01 } = props;

  //console.log(departid01,"departid")
  //console.log(categoryid01,"catid");
  //  const [openSubCategory, setOpenSubCategory] = useState(false);
  const [subCatDatas, setSubCatDatas] = useState({
    subcatname: "",
    subcatstatus: "",
  });
  const [dp_sub_caty_name, setDp_Sub_Caty_Name] = useState("");
  const [dp_sub_caty_status, setDp_Sub_Caty_Status] = useState("");
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [loadingDialog, setLoadingDialog] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleCloseSubCat = () => {
    handleClose();
    setDp_Sub_Caty_Status("");
    setDp_Sub_Caty_Name("");
    setErrorStatus({
      ...errorStatus,
      depStatusStatus: false,
      depNameStatus: false,
    });
  };

  //   const handleCloseSubCategory = () => {
  //     setOpenSubCategory(false);
  //   };

  const handleSubmitSubCategory = async (e) => {
    e.preventDefault();
    if (dp_sub_caty_name === "") {
      setErrorStatus({ ...errorStatus, depNameStatus: true });
      setInputFieldErrors({ forDepname: "Enter Sub-Category Name" });
    } else if (dp_sub_caty_status === "") {
      setErrorStatus({
        ...errorStatus,
        depStatusStatus: true,
        depNameStatus: false,
      });
      setInputFieldErrors({
        ...inputFieldErrors,
        forDetStatus: "Please Select Status",
      });
    } else {
      setLoadingDialog(true);
      setErrorStatus({
        ...errorStatus,
        depStatusStatus: false,
        depNameStatus: false,
      });
      const userData02 = {
        dp_sub_caty_name: dp_sub_caty_name,
        dp_sub_caty_status: dp_sub_caty_status,
        dp_fk2: departid01,
        dp_caty_fk: categoryid01,
      };
      //console.log(userData02.dp_caty_fk,"userdata");
      await axios
        .post(`${api1}/admin/sub_dept`, userData02)
        .then((response) => {
          //console.log(response, "resofsubcat");
          //console.log(userData02, "userdata02");
          if (response.data.api_status === 200) {
            setLoadingDialog(false);
            handleCloseSubCat();
            getAllData();
            setNotify({
              isOpen: true,
              message: " Sub-Category added successfully",
              type: "success",
            });
          } else if (response.data.api_status === 502) {
            setLoadingDialog(false);
            setNotify({
              isOpen: true,
              message: "The value cannot be accepted(duplicate value found)",
              type: "error",
            });
          } else {
            setLoadingDialog(false);
            setNotify({
              isOpen: true,
              message: "Sub-Category cannot be added",
              type: "error",
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "16px" }}
        //  maxWidth={maxwidth} fullWidth={fullWidth}
      >
        <form onSubmit={handleSubmitSubCategory}>
          <DialogTitle style={{ marginLeft: "-8px" }}>
            Add New Sub Category
          </DialogTitle>
          <DialogContent>
            <Box sx={{ flexGrow: 1, padding: "10px !important" }}>
              <Grid container>
                <Grid item xs={12} md={12}>
                  <div>
                    <b>
                      <label
                        style={{
                          marginBottom: "4px",
                          justifyContent: "start",
                          display: "flex",
                        }}
                      >
                        Sub-Category Name
                      </label>
                    </b>
                  </div>
                </Grid>
                <Grid item xs={12} md={12}>
                  <ReusableInputfield
                    style={{ justifyContent: "center", display: "flex" }}
                    type="text"
                    name="subcatname"
                    value={dp_sub_caty_name}
                    placeholder="Sub-Category name"
                    size="medium"
                    onChange={(e) => setDp_Sub_Caty_Name(e.target.value)}
                    // onChange={handleOnChange}
                  />
                  {errorStatus.depNameStatus && (
                    <FormHelperText style={{ color: "red" }}>
                      {inputFieldErrors.forDepname}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} md={12}>
                  <div>
                    <b>
                      <label
                        style={{
                          marginBottom: "4px",
                          justifyContent: "start",
                          display: "flex",
                          paddingTop: "10px",
                        }}
                      >
                        Status
                      </label>
                    </b>
                  </div>
                </Grid>
                <Grid item xs={4} md={4}>
                  {/* <ReusableInputfield
                            style={{ justifyContent: 'center', display: 'flex' }}
                            type="text"
                            name="subcatstatus"
                            value={dp_sub_caty_status}
                            placeholder="Give Y/N"
                            size="medium"
                            onChange={(e) => setDp_Sub_Caty_Status(e.target.value)}
                            // onChange={handleOnChange}

                          /> */}
                  {/* <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    name="subcatstatus"
                    value={dp_sub_caty_status}
                    // label="Age"
                    size="large"
                    defaultValue={"Y"}
                    style={{
                      justifyContent: "start",
                      display: "flex",
                    }}
                    autoWidth
                    onChange={(e) => setDp_Sub_Caty_Status(e.target.value)}
                  >
                    <MenuItem value={"Y"}>Active</MenuItem>
                    <MenuItem value={"N"}>InActive</MenuItem>
                  </Select> */}
                  <Custom.Dropdown3
                    name="subcatstatus"
                    value={dp_sub_caty_status}
                    options={dpSubCatDropdownValues}
                    // label="Status"
                    defaultValue="Y"
                    onChange={(e) => setDp_Sub_Caty_Status(e.target.value)}
                    style={{
                      justifyContent: "start",
                      display: "flex",
                      width: "100%",
                    }}
                    error={errorStatus.depStatusStatus}
                    errorMsg={inputFieldErrors.forDetStatus}
                  />
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
          <DialogActions>
            <ReusableButton
              onClick={handleCloseSubCat}
              size="large"
              variant="outlined"
              buttonName="Cancel"
              style={{
                color: "#000936",
                border: "1px solid #000936",
                marginRight: "10px",
                marginBottom: "20px",
              }}
            >
              Cancel
            </ReusableButton>
            <ReusableButton
              //    onClick={handleSubmitSubCategory}
              // onClick={(e) => handleSubmitSubCategory(e)}
              type="submit"
              size="large"
              variant="contained"
              buttonName={
                loadingDialog ? <CircularProgress size={25} /> : "Add"
              }
              style={{
                backgroundColor: "#000936",
                marginRight: "30px",
                marginBottom: "20px",
              }}
            >
              Add
            </ReusableButton>
          </DialogActions>
        </form>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
