//new
import { React, useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
// import Checkbox from "@mui/material/Checkbox";
import Stack from '@mui/material/Stack';
// import Link from "@mui/material/Link";
import ReusableButton from '../../../components/Custom/button/button';
import { ReusableCards } from '../../../components/Custom/cards/cards';
// import { ReusableSelect } from "../../../components/Custom/select/select";
import { Custom } from '../../../components/Custom/Custom';
import { DataTable } from '../../../components/Custom/datagrid/datagrid';
import './questions-upload.css';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import axios from 'axios';
import './questions-upload.css';
//import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { CompetencyLevel } from './competency-level';
import Notification from '../../../components/Custom/Notification';
import ReactLoading from 'react-loading';
import ReactPaginate from 'react-paginate';

import API from '../../../api/index';

const api1 = API.Api1;
const api2 = API.Api2;

// const Question = {
//   salesforcesQstn: true,
//   digitalsQstn: true,
//   generalsQstn: true,
// };

const initialValues = {
  no_of_questions: 0,
  countdown_time: 0,
  question_credits: 0,
  pass_score: 0,
  attempts: 0,
  competency_level: 0,
};

const SheetJSFT = ['xlsx', 'csv']
  .map(function (x) {
    return '.' + x;
  })
  .join(',');

const QuestionsUpload = (props) => {
  const [departTypes, setDepartTypes] = useState('');
  const [basicState, setBasicState] = useState(initialValues);
  const [intermediateState, setIntermediateState] = useState(initialValues);
  const [advanceState, setAdvanceState] = useState(initialValues);
  const [tableData, setTableData] = useState([]);
  const [done, setDone] = useState(undefined);
  //const [dataid, setdataid] = useState();
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const [questions, setQuestion] = useState('');
  const [filterClick, setFilterClick] = useState(false);
  const [showValues, setShowValues] = useState(false);
  const hiddenFileInput = useRef(null);
  const [filterButton, setFilterButton] = useState('Filter');

  const [fileMerge, setFileMerge] = useState('');
  const [indexValue, setIndexValue] = useState('');

  const [departmentValueDD, setDepartmentValueDD] = useState('');
  const [departmentApiDD, setDepartmentApiDD] = useState([]);
  const [depDependent, setDepDependent] = useState(false);

  const [categoryValueDD, setCategoryValueDD] = useState('');
  const [categoryApiDD, setCategoryApiDD] = useState([]);

  const [subCategoryValueDD, setSubCategoryValueDD] = useState('');
  const [subCategoryApiDD, setSubCategoryApiDD] = useState([]);

  const [competencyLevelValueDD, setCompetencyLevelValueDD] = useState('');
  const [competencyLevelApiDD, setCompetencyLevelApiDD] = useState([]);

  const [depFilter, setDepFilter] = useState('');
  const [catFilter, setCatFilter] = useState('');
  const [subCatFilter, setSubCatFilter] = useState('');
  const [compLevelFilter, setCompLevelFilter] = useState('');

  // const [searchClicked, setSearchClicked] = useState([]);
  const [salesforceQstn, setSalesforceQstn] = useState(0);
  const [digitalQstn, setDigitalQstn] = useState(0);
  const [generalQstn, setGeneralQstn] = useState(0);

  const [openEdit, setOpenEdit] = useState(false);
  const [competencyLevelModalData, setCompetencyLevelModalData] = useState([]);

  const [basicContent, setBasicContent] = useState(true);
  const [intermediateContent, setIntermediateContent] = useState(true);
  const [advanceContent, setAdvanceContent] = useState(true);
  const [clicked, setClicked] = useState(false);
  const [totalquestionscountforpage, setTotalQuestionsCountForPage] =
    useState('');
  const [available, setAvailable] = useState(true);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });

  useEffect(() => {
    getIdValue(pageNumber);
    getDeparmentApi();
    getCompetencyLevelApi();
    // getApiDefaultData();
    // getCategoryApi();
  }, []);

  const getApiDefaultData = (data) => {
    // console.log(data, "data");
    data.map((item, index) => {
      if (item.competency_pk === 1) {
        setBasicState({
          ...basicState,
          no_of_questions: item.no_of_questions,
          attempts: item.no_of_attempts,
          countdown_time: item.countdown_time,
          pass_score: item.pass_score,
          question_credits: item.question_credits,
          competency_level: item.competency_level,
          competency_pk: item.competency_pk,
        });
      } else if (item.competency_pk === 3) {
        setIntermediateState({
          ...intermediateState,
          no_of_questions: item.no_of_questions,
          attempts: item.no_of_attempts,
          countdown_time: item.countdown_time,
          pass_score: item.pass_score,
          question_credits: item.question_credits,
          competency_level: item.competency_level,
          competency_pk: item.competency_pk,
        });
      } else if (item.competency_pk === 4) {
        setAdvanceState({
          ...advanceState,
          no_of_questions: item.no_of_questions,
          attempts: item.no_of_attempts,
          countdown_time: item.countdown_time,
          pass_score: item.pass_score,
          question_credits: item.question_credits,
          competency_level: item.competency_level,
          competency_pk: item.competency_pk,
        });
      }
    });
  };
  let pageNumber = 0;

  const getIdValue = async (pageNumber) => {
    //console.log(pageNumber, 'pgno');
    const requestOptions = {
      method: 'POST',
      headers: { token: 'qwerty', 'Content-Type': 'application/json' },
      //body: JSON.stringify(getDesignation1),
      body: JSON.stringify({
        category: null,
        department: null,
        efficiencyLevel: null,
        subCategory: null,
        page: pageNumber,
      }),
    };
    const response = await fetch(`${api1}/admin/getQuestions`, requestOptions);
    // console.log(response, "responseofquestions");
    const data = await response.json();
    console.log(data, 'data of questionspage');
    const result = data;
    const questionsCount = result.data[0].dp_questions_count;
    //console.log(questionsCount, 'questionscount');
    const totalQuestionsCount = result.data[0].total_questions;
    setTotalQuestionsCountForPage(totalQuestionsCount, 'totalQuestionsCount');

    {
      questionsCount.map((item) => {
        // //console.log(item, "filterobject")
        if (item.department === 'Digital') {
          setDigitalQstn(item.count);
          // digitalsQstn = item[1]
        } else if (item.department === 'Salesforce') {
          setSalesforceQstn(item.count);
          // salesforcesQstn = item[1]
        } else if (item.department === 'General') {
          setGeneralQstn(item.count);
          // generalsQstn  = item[1]
        }
      });
    }
    const apiData = result.data[0].questions.reverse();
    //console.log(apiData, 'apiData');
    const updatedData = apiData.map((items, index) => ({
      sno: pageNumber * 10 + (index + 1),
      id: items.questionId,
      question: items.question,
      department: items.department,
      answer: items.answer,
      category: items.category,
      subcategory: items.subCategory,
      competencylevel: items.level,
    }));
    setTableData(updatedData);
    setDone(true);
    //console.log(updatedData, 'ticking');
  };

  const handlePageClick = async (e, pageNumber) => {
    //console.log(e.selected);
    if (clicked) {
      //console.log('inside clicked true');
      filterPgNo = e.selected;
      console.log(filterPgNo,"filterPgNo")
      // const getFilterData = async (filterPgNo) => {
      //   const requestOptions = {
      //     method: 'POST',
      //     headers: { token: 'qwerty', 'Content-Type': 'application/json' },
      //     body: JSON.stringify({
      //       department: depFilter,
      //       category: catFilter,
      //       subCategory: subCatFilter,
      //       efficiencyLevel: compLevelFilter,
      //       page: filterPgNo,
      //     }),
      //   };

      //   const response = await fetch(`${api1}/admin/getQuestions`,requestOptions );
      //   const data = await response.json();
      //   const result = data;
      //   console.log(result,"pagination")
      //   // //console.log(result.payload.length,"result")
       
      //   const questionsCount = result.data[0].dp_questions_count;
      //   //console.log(questionsCount, 'questionscount');
      //   if (result.data[0].questions !== 0) {
      //     let holder = '';
      //     {
      //       questionsCount.map((item) => {
      //         // //console.log(item, "filterobject")
      //         if (item.department === 'Digital') {
      //           setDigitalQstn(item.count);
      //           // digitalsQstn = item[1]
      //         } else if (item.department === 'Salesforce') {
      //           setSalesforceQstn(item.count);
      //           // salesforcesQstn = item[1]
      //         } else if (item.department === 'General') {
      //           setGeneralQstn(item.count);
      //           // generalsQstn  = item[1]
      //         }
      //       });
      //     }
      //   }
      //   if (result.api_status === 200) {
      //     const data = result.data[0].questions.reverse();
      //     setDone(true);
      //     ////console.log(data,"statusCode")
      //     const filteredData = data.map((items, index) => ({
      //       sno: index + 1,
      //       id: items.questionId,
      //       question: items.question,
      //       department: items.department,
      //       answer: items.answer,
      //       category: items.category,
      //       subcategory: items.subCategory,
      //       competencylevel: items.level,
      //     }));
      //     setTableData(filteredData);
      //   }
      // };
      // getFilterData(filterPgNo);
      handleFilterSearch(filterPgNo)
    } 
    else if (clicked === false) {
      pageNumber = e.selected;
      getIdValue(pageNumber);
    }
  };

  let array = [];
  const getDeparmentApi = async () => {
    const res = await fetch(`${api1}/admin/departments`);
    const getCon = await res.json();
    // //console.log(getCon.data[0],"getCon");

    Object.entries(getCon.data[0]).map((item) => {
      ////console.log(item, "object")
      array = item[1].map((value) => ({
        id: value.dp_pk,
        title: value.dp_name,
        message: value.dp_status,
      }));
    });
    setDepartmentApiDD(array);
    // //console.log(array, "arraydp_name");
  };
  // //console.log(departmentApiDD, "departmentApiDD");

  const getCompetencyLevelApi = async () => {
    const res = await fetch(`${api1}/level`);
    const getCon = await res.json();
    ////console.log(getCon.data[0], "getCon");

    let array = [];
    Object.entries(getCon.data[0]).map((item) => {
      ////console.log(item, "object");

      array = item[1].map((value) => ({
        id: value.competency_pk,
        title: value.competency_level,
        countdowntime: value.countdown_time,
        questions: value.No_of_questions,
        passscore: value.pass_score,
        attempts: value.attempts,
        credits: value.question_credits,
      }));
    });
    setCompetencyLevelApiDD(array);
  };
  // //console.log(competencyLevelApiDD, "competencyLevelApiDD");

  // //console.log(tableData,"tabledata")
  const handleClickOpen = (value) => {
    // //console.log(value.row.id, "gettingquestion?");
    setId(value.row.id);
    setQuestion(value.row.question);
    setOpen(true);
  };

  const handleCloseByCancel = () => {
    setOpen(false);
  };

  const handleCloseByDelete = (paramsId) => {
    //console.log(paramsId, 'paramsIdID');
    const requestOptions = {
      method: 'GET',
      headers: { token: 'qwerty' },
    };
    fetch(
      `${api2}/employeeportal/deletequestionbyid/${paramsId}`,
      requestOptions
    ).then((response) => {
      //console.log(response, 'response');
      if (response.status === 200) {
        getIdValue();
        setOpen(false);
        setNotify({
          isOpen: true,
          message: 'Question Deleted Successfully',
          type: 'success',
        });
      } else {
        getIdValue();
        setOpen(false);
        setNotify({
          isOpen: true,
          message: 'Question Not Deleted Please Try Again',
          type: 'error',
        });
      }
    });
  };

  const columns = [
    {
      field: 'sno',
      headerName: 'S.No',
      width: 20,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'center',
    },
    {
      field: 'question',
      headerName: 'Question',
      // flex: 1,
      width: 380,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'department',
      headerName: 'Department',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'category',
      headerName: 'Category',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'subcategory',
      headerName: 'Sub-Category',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'competencylevel',
      headerName: 'Competency Level',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerClassName: 'super-app-theme--header',
      headerAlign: 'left',
      align: 'left',
      renderCell: (params) => (
        <div>
          <ReusableButton
            size="large"
            className="qstnUpldPageBttn"
            startIcon={<DeleteOutlineOutlinedIcon color="primary" fontSize="small" />}
            onClick={() => handleClickOpen(params)}
          />
          <Dialog
            open={open}
            // className="dialogBox"
            // TransitionComponent={Transition}
            maxWidth="sm"
            // keepMounted
            onClose={handleCloseByCancel}
            // aria-describedby="alert-dialog-slide-description"
            BackdropProps={{
              style: {
                background: '#00000022',
                boxShadow: '0px 0px 0px 0px !important',
              },
            }}
          >
            <DialogTitle></DialogTitle>
            <DialogContent
              style={{ boxShadow: 'none' }}
              BackdropProps={{
                style: { boxShadow: 'none', transition: 'none' },
              }}
            >
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete the question?
                <span style={{ fontWeight: 'bold', marginLeft: '5px' }}>
                  "{questions}"
                </span>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                size="medium"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={{
                  fontSize: '16px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAalign: 'center',
                  color: '#000936',
                  border: '1px solid #000936',
                  padding: '7px 20px',
                  borderRadius: '4px',
                  marginRight: '8px',
                  // marginBottom:"25px",
                  textTransform: 'none',
                }}
                onClick={handleCloseByCancel}
              />
              <Button
                size="medium"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName="Delete"
                style={{
                  backgroundColor: '#fb2485',
                  // marginRight:"30px",
                  // marginBottom:"25px",
                  fontSize: '16px',
                  fontWeight: 'normal',
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAalign: 'center',
                  color: '#fff',
                  padding: '8px 28px',
                  borderRadius: '4px',
                  textTransform: 'none',
                  marginRight: '10px',
                }}
                onClick={() => handleCloseByDelete(id)}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ),
    },
  ];

  const handleClickByFilter = () => {
    if (filterClick === false) {
      setFilterClick(true);
      setFilterButton('Close');
    } else {
      setFilterClick(false);
      getIdValue();
      // setMakeTrue({digitalsQstn:true,
      //   salesforcesQstn:true,
      //   generalsQstn:true});
      setFilterButton('Filter');
    }
  };

  const handleUploadClick = async (event) => {
    // setSnackbarOpen(true);
    //console.log('hit', fileMerge);
    var formData = new FormData();
    formData.append('file', fileMerge);

    // const requestOptions = {
    //   method: "POST",
    //   headers: { token: "qwerty",'Content-Type': 'application/json' },
    //   body: formData,
    // };
    // //console.log("requestOptions", requestOptions);
    // fetch(`http://143.110.183.48:9090/employeeportal/uploadQuestions`,requestOptions
    // ).then((response) => {
    //   //console.log("res", response, JSON.stringify(response.json()));

    // });

    const body = formData;
    const headers = {
      token: 'qwerty',
      'Content-Type': 'application/json',
    };
    axios
      .post(`${api2}/employeeportal/uploadQuestions`, body, { headers })
      // .then(response => //console.log(response.status,"response"))
      .then((response) => {
        if (response.status === 200) {
          // //console.log(response, "success");
          // alert("Successfully question Added");
          //setSnackbarOpen(true);
          setShowValues(false);
          setNotify({
            isOpen: true,
            message: 'File Uploaded successfully',
            type: 'success',
          });
        } else {
          setNotify({
            isOpen: true,
            message: 'File Not Uploaded',
            type: 'error',
          });
        }
      });
  };
  const handleChange = (files /*:File*/) => {
    //console.log('file', files.target.files);
    setFileMerge(files.target.files[0]);
    setShowValues(true);
  };

  const handleDeptDropdown = (event) => {
    //console.log(event.target.value, 'selectedValue');
    // //console.log(event,"Dep")
    setDepartmentValueDD(event.target.value);
    setDepFilter(event.target.value);
    const selectedValue = event.target.value;
    //  //console.log(selectedValue, "selectedValue");

    setIndexValue(departmentValueDD);
    setDepDependent(true);

    // departmentApiDD.map((value) => {
    //   // //console.log(value.id,"value(selected Value)")
    //   if (value.id === selectedValue) {
    //     setDepartTypes(value.title);
    //     // //console.log(value.title,"valueTitle");
    //   }
    // });

    let array1 = [];
    let array = [];

    const getCategoryData = async (indexValue) => {
      // //console.log(indexValue,"indexvalue")
      const res = await fetch(`${api1}/admin/dp_categories/${indexValue}`);
      const getCon = await res.json();
      // //console.log(getCon.data[0], "getCon");

      Object.entries(getCon.data[0]).map((item) => {
        array1 = item[1];
      });
      // //console.log(array1,"array1");
      array = array1.map((value, index) => ({
        id: value.dp_caty_pk,
        title: value.dp_caty_name,
        department: value.dp_name,
        foreignkey: value.dp_fk,
      }));
      // //console.log(array,"array")
      setCategoryApiDD(array);
    };

    getCategoryData(selectedValue);
  };
  // //console.log(categoryApiDD,"categoryApiDD")
  // //console.log(depFilter,"depFilter")

  const handleCatDropdown = (event) => {
    //console.log(event.target.value, 'Cat');
    const categorySelected = event.target.value;
    setCategoryValueDD(categorySelected);
    setCatFilter(event.target.value);

    // categoryApiDD.map((value) => {
    //   // //console.log(value.id,"value(selected Value)")
    //   if (value.id === categorySelected) {
    //     // console.log(value.id,selectedValue,"both");
    //     setCatFilter(value.title);
    //   }
    // });

    let dummyArray1 = [];
    let dummyArray2 = [];

    const getSubCategoryData = async (categorySelected, departmentValueDD) => {
      //console.log(departmentValueDD, categorySelected, 'departmentValueDD');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          dp_fk2: departmentValueDD,
          dp_caty_fk: categorySelected,
        }),
      };
      const response = await fetch(
        `${api1}/admin/sub_dept/get`,
        requestOptions
      );
      const data = await response.json();
      console.log(data, 'dataerror');
      const result = data.data[0];
      // //console.log(result, "result");
      Object.entries(result).map((item) => {
        // //console.log(item[1], "subcattyobject");
        dummyArray1 = item[1];
      });
      dummyArray2 = dummyArray1.map((value, index) => ({
        id: value.dp_sub_caty_pk,
        catid: value.dp_caty_id,
        deptid: value.dp_id,
        title: value.department_sub_category,
        department: value.department,
        category: value.department_category,
      }));
      // //console.log(dummyArray1,"dummyArray1");
      setSubCategoryApiDD(dummyArray2);
    };
    getSubCategoryData(categorySelected, departmentValueDD);
  };

  const handleSubCatDropdown = (event) => {
    setSubCategoryValueDD(event.target.value);
    setSubCatFilter(event.target.value);

  };
  // //console.log(subCatFilter, "subCatFilter");

  const handleComptLevelDropdown = (event) => {
    let compLevelselected;

    setCompetencyLevelValueDD(event.target.value);
    setCompLevelFilter(event.target.value);

  };
  // //console.log(competencyLevelValueDD, "competencyLevelValueDD");

  let filterPgNo = 0;

  const handleFilterSearch = async () => {
    setClicked(true);
    console.log(compLevelFilter,"compLevelFilter")
    
    // const getFilterData = async (filterPgNo) =>{
    const requestOptions = {
      method: 'POST',
      headers: { token: 'qwerty', 'Content-Type': 'application/json' },
      body: JSON.stringify({
        department: depFilter || null,
        category: catFilter || null,
        subCategory: subCatFilter || null,
        efficiencyLevel: compLevelFilter || null,
        page: filterPgNo,
      }),
    };

    const response = await fetch(`${api1}/admin/getQuestions`, requestOptions);
    const data = await response.json();
    const result = data;
    // //console.log(result.payload.length,"result")
    console.log(result, 'filtersearch');
    // //console.log(result.data[0].questions,"result of filter questions");

    // }
    if (data.api_status === 404) {
      setAvailable(false);
    }

    // if(departTypes === holder){
    //   //console.log(departTypes,holder,"havingboth")
    //   Object.entries(result.filter).map((item) => {
    //     //console.log(item[1],"object")

    //   });
    // }

    if (result.api_status === 200) {
      setAvailable(true);
      const questionsCount = result.data[0].dp_questions_count;
      // if(result.data[0].questions != 0){
      let holder = '';
      {
        questionsCount.map((item) => {
          // //console.log(item, "filterobject")
          if (item.department === 'Digital') {
            setDigitalQstn(result.data[0].total_questions);
            setSalesforceQstn(0);
            setGeneralQstn(0);
            // digitalsQstn = item[1]
          } else if (item.department === 'Salesforce') {
            setSalesforceQstn(result.data[0].total_questions);
            setDigitalQstn(0);
            setGeneralQstn(0);
            // salesforcesQstn = item[1]
          } else if (item.department === 'General') {
            setGeneralQstn(result.data[0].total_questions);
            setDigitalQstn(0);
            setSalesforceQstn(0);
            // generalsQstn  = item[1]
          }
        });
      }

      const pagecount = result.data[0].dp_questions_count[0].count;
      setTotalQuestionsCountForPage(pagecount);
      const data = result.data[0].questions.reverse();
      setDone(true);
      ////console.log(data,"statusCode")
      const filteredData = data.map((items, index) => ({
        sno:  filterPgNo * 10 + (index + 1),
        id: items.questionId,
        question: items.question,
        department: items.department,
        answer: items.answer,
        category: items.category,
        subcategory: items.subCategory,
        competencylevel: items.level,
      }));
      setTableData(filteredData);
      //console.log(filteredData, 'filterdata');
    }

    // }
    // getFilterData(filterPgNo);
  };
  //console.log(tableData, 'tableData');

  const handleCompetencyLevel = async () => {
    setOpenEdit(true);
    // //console.log("clicked the competency level");

    const requestOptions = {
      method: 'GET',
      //headers: { token: "qwerty" },
    };
    const response = await fetch(`${api1}/level`, requestOptions);
    // //console.log(response, "filteredresponse");
    const data = await response.json();
    // //console.log(data.data[0].competency_levels, "filtereddata");
    setCompetencyLevelModalData(data.data[0].competency_levels);
    getApiDefaultData(data.data[0].competency_levels);
    setDone(true);
  };
  // //console.log(competencyLevelModalData,"setCompetencyLevelModalData")

  const handleEditClose = () => {
    setOpenEdit(false);
    setBasicContent(true);
    setIntermediateContent(true);
    setAdvanceContent(true);
  };

  return (
    <>
      {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
          }}
        >
          <ReactLoading
            type={'spinningBubbles'}
            color={'#fb2485'}
            height={100}
            width={100}
          />
        </Box>
      ) : (
        <Box component="main" className="overallQUDiv" sx={{ flexGrow: 1 }}>
          <div>
            <Grid container spacing={2}>
              <Grid item xs={4}></Grid>
              <Grid item xs={4}>
                <h2 className="questionsPageTitle">Questions Inventory</h2>
              </Grid>

              <Grid
                item
                xs={4}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                  position: 'relative',
                  right: '24px',
                }}
              >
                <ReusableButton
                  size="medium"
                  variant="contained"
                  className="qstnUpldPageBttn"
                  buttonName="Competency Levels"
                  style={{
                    backgroundColor: '#ffff',
                    color: '#000936',
                    fontFamily: 'Helvetica',
                    fontSize: '14px',
                    fontWeight: '500',
                    fontStretch: 'normal',
                    fontStyle: 'normal',
                    lineHeight: 'normal',
                    letterSpacing: 'normal',
                    textAlign: 'center',
                    padding: '12px 28px 12px 28px',
                    borderRadius: '6px',
                    textTransform: 'none',
                  }}
                  onClick={handleCompetencyLevel}
                />
              </Grid>
            </Grid>
          </div>

          <Container
            component="main"
            maxWidth="xl"
            sx={{ Padding: '0px !important' }}
          >
            <Card
              variant="outlined"
              sx={{ marginTop: '2%', Width: '100%', borderRadius: 0 }}
            >
              <Box>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'start',
                    flexDirection: 'column',
                    padding: '18px 30px 18px 30px',
                  }}
                >
                  <span>
                    Please Upload the Questions file in Spreadsheet(csv)* Format
                  </span>

                  <Grid container spacing={2}>
                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        alignItems: 'center',
                      }}
                    >

                      <input
                        type="file"
                        name="file"
                        className="upload-box-csv"
                        accept={SheetJSFT}
                        style={{
                          marginTop: '18px',
                          fontSize: '0.8em',
                          fontFamily: 'Poppins',
                        }}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      style={{
                        display: 'flex',
                        justifyContent: 'end',
                        alignItems: 'center',
                      }}
                    >
                      {showValues ? (
                        <>
                          <Button
                            type="button"
                            className="UpldBttn"
                            variant="contained"
                            startIcon={<FileUploadOutlinedIcon />}
                            style={{
                              marginTop: '18px',
                              backgroundColor: '#fb2485',
                              color: '#fff',
                              fontFamily: 'Poppins',
                              fontSize: '0.8em',
                              fontWeight: 200,
                              fontStretch: 'normal',
                              fontStyle: 'normal',
                              lineHeight: 'normal',
                              letterSpacing: 'normal',
                              textAlign: 'left',
                              padding: '12px 28px 12px 28px',
                              borderRadius: '6px',
                            }}
                            onClick={handleUploadClick}
                          >
                            Upload Excel
                          </Button>
                          {/* <Snackbar
                      open={snackbarOpen}
                      autoHideDuration={3000}
                      onClose={handleSnackbarClose}>
                        <Alert onClose={handleSnackbarClose} severity="success" >
                      This is a success message!
                    </Alert></Snackbar> */}
                        </>
                      ) : null}
                    </Grid>
                  </Grid>
                </div>
              </Box>
            </Card>
          </Container>

          <Container
            component="main"
            maxWidth="xl"
            sx={{ marginTop: '16px', width: '100% !important' }}
          >
            <Grid
              container
              spacing={2}
              style={{ display: 'flex', justifyContent: 'center' }}
            >
              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'start',
                  alignItems: 'center',
                }}
              >
                <ReusableCards
                  text="Salesforce"
                  // value={makeTrue.salesforcesQstn === true ? salesforceQstn : 0}
                  value={salesforceQstn}
                  style={{
                    minHeight: 55,
                    minWidth: '95%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: '0px',
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ReusableCards
                  // value={makeTrue.digitalsQstn === true ? digitalQstn : 0}
                  value={digitalQstn}
                  text="Digital"
                  style={{
                    minHeight: 55,
                    minWidth: '95%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: '0px',
                  }}
                />
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={4}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  alignItems: 'center',
                }}
              >
                <ReusableCards
                  // value={makeTrue.generalsQstn === true ? generalQstn : 0}
                  value={generalQstn}
                  text="General"
                  style={{
                    minHeight: 55,
                    minWidth: '95%',
                    display: 'flex',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    borderRadius: '0px',
                  }}
                />
              </Grid>
            </Grid>
          </Container>

          <Container component="main" maxWidth="xl">
            <Card
              variant="outlined"
              sx={{
                marginTop: '2%',
                Width: '100%',
                borderRadius: 0,
                marginBottom: 12,
              }}
            >
              <Grid
                container
                spacing={2}
                style={{ padding: '16px 26px 16px 26px' }}
              >
                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'left' }}
                >
                  <span
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      color: '#080825',
                      fontFamily: 'Poppins',
                      fontSize: '1.3em',
                      fontWeight: 600,
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                    }}
                  >
                    Recent Shared Questions
                  </span>
                </Grid>

                <Grid
                  item
                  xs={6}
                  style={{ display: 'flex', justifyContent: 'right' }}
                >
                  <ReusableButton
                    size="large"
                    variant="contained"
                    className="filterButton"
                    buttonName={filterButton}
                    startIcon={<FilterAltIcon />}
                    style={{
                      backgroundColor: '#000d36',
                      color: '#fff',
                      fontFamily: 'Poppins',
                      fontSize: '0.8em',
                      fontWeight: 200,
                      fontStretch: 'normal',
                      fontStyle: 'normal',
                      lineHeight: 'normal',
                      letterSpacing: 'normal',
                      textAlign: 'left',
                      padding: '12px 28px 12px 28px',
                      borderRadius: '6px',
                    }}
                    onClick={handleClickByFilter}
                  />
                </Grid>
              </Grid>
              {filterClick !== true ? null : (
                <>
                  <Grid
                    container
                    spacing={2}
                    className="filterGrid"
                    style={{
                      display: 'flex',
                      justifyContent: 'space-evenly',
                      padding: '10px 0px 10px 0px',
                      marginLeft: '0px',
                      width: '100%',
                      marginBottom: '20px',
                    }}
                  >
                    <Grid item xs={2}>
                      <InputLabel
                        sx={{
                          float: 'left',
                          fontWeight: '600',
                          marginBottom: '6px',
                        }}
                      >
                        Department
                      </InputLabel>
                      <Custom.Dropdown1
                        fullWidth
                        displayEmpty
                        // label="select value"
                        name="department"
                        value={departmentValueDD}
                        options={departmentApiDD}
                        size="small"
                        style={{ height: '44px' }}
                        //menustyle={{height:"200px"}}
                        onChange={handleDeptDropdown}
                      />
                    </Grid>

                    {/* {depDependent !== true ? null : ( */}
                    <>
                      <Grid item xs={2}>
                        <InputLabel
                          sx={{
                            float: 'left',
                            fontWeight: '600',
                            marginBottom: '6px',
                          }}
                        >
                          Category
                        </InputLabel>
                        <Custom.Dropdown1
                          displayEmpty
                          fullWidth
                          name="Category"
                          value={categoryValueDD}
                          options={categoryApiDD}
                          size="small"
                          style={{ height: '44px' }}
                          onChange={handleCatDropdown}
                        />
                      </Grid>

                      {/* {catDependent !== true ? null : (
                        <> */}
                      <Grid item xs={2}>
                        <InputLabel
                          sx={{
                            float: 'left',
                            fontWeight: '600',
                            marginBottom: '6px',
                          }}
                        >
                          Sub-Category
                        </InputLabel>
                        <Custom.Dropdown1
                          displayEmpty
                          fullWidth
                          name="Sub-Category"
                          value={subCategoryValueDD}
                          options={subCategoryApiDD}
                          size="small"
                          style={{ height: '44px' }}
                          onChange={handleSubCatDropdown}
                        />
                      </Grid>
                      {/* </>
                      )} */}
                    </>
                    {/* )} */}

                    <Grid item xs={2}>
                      <InputLabel
                        sx={{
                          float: 'left',
                          fontWeight: '600',
                          marginBottom: '6px',
                        }}
                      >
                        Competency Level
                      </InputLabel>

                      <Custom.Dropdown1
                        fullWidth
                        displayEmpty
                        // label="select value"
                        name="competencyLevel"
                        value={competencyLevelValueDD}
                        options={competencyLevelApiDD}
                        size="small"
                        style={{ height: '44px' }}
                        //menustyle={{height:"200px"}}
                        onChange={handleComptLevelDropdown}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={2}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'end',
                      }}
                    >
                      <ReusableButton
                        size="large"
                        variant="contained"
                        className="filterSearchButton"
                        buttonName="Search"
                        style={{
                          color: '#fff',
                          fontFamily: 'Poppins',
                          fontSize: '0.8em',
                          fontWeight: 200,
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          letterSpacing: 'normal',
                          textAlign: 'left',
                          padding: '12px 28px 12px 28px',
                          borderRadius: '6px',
                        }}
                        onClick={handleFilterSearch}
                      />
                    </Grid>
                  </Grid>
                </>
              )}

              <Box
                sx={{
                  width: '100%',
                  '& .super-app-theme--header': {
                    backgroundColor: '#f1f1f1',
                    color: '#02215b',
                    fontSize: '16px',
                  },
                }}
              >
                {available ? (
                  <>
                    <DataTable
                      rows={tableData}
                      columns={columns}
                      rowHeight={40}
                      autoHeight
                      pageSize={10}
                      hideFooter={true}
                      hideFooterPagination={true}
                      hideFooterSelectedRowCount={true}
                    />
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        margin: '20px 0px 0px 0px',
                      }}
                    >
                      <ReactPaginate
                        nextLabel="next>"
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={6}
                        marginPagesDisplayed={2}
                        pageCount={Math.ceil(totalquestionscountforpage / 10)}
                        previousLabel="<previous"
                        pageClassName="pageClassName"
                        pageLinkClassName="pageLink"
                        previousClassName="previousClass"
                        previousLinkClassName="previousLinkClass"
                        nextClassName="nextClass"
                        nextLinkClassName="nextLinkClass"
                        breakLabel="...."
                        breakClassName="breakClass"
                        breakLinkClassName="breakLinkClass"
                        containerClassName="pagination"
                        activeClassName="activeClass"
                        renderOnZeroPageCount={null}
                      />
                    </div>
                  </>
                ) : (
                  <Grid
                    item
                    xs={12}
                    style={{
                      backgroundColor: 'white',
                      padding: '0px !important',
                      height: '30vh',
                      borderBottomRightRadius: '10px',
                      borderBottomLeftRadius: '10px',
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        marginTop: '15px',
                      }}
                    >
                      <Grid item xs={3}></Grid>
                      <Grid item xs={6}>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '25px',
                          }}
                        >
                          <b>
                            Questions Not Available for the selected
                           filter
                          </b>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '15px',
                          }}
                        >
                          <b>Please try again after sometime. </b>
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            marginTop: '15px',
                          }}
                        ></Grid>
                      </Grid>
                      <Grid item xs={3}></Grid>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Card>
          </Container>

          <CompetencyLevel
            open={openEdit}
            handleClose={handleEditClose}
            competencyLevelModalData={competencyLevelModalData}
            basicContent={basicContent}
            intermediateContent={intermediateContent}
            advanceContent={advanceContent}
            setBasicContent={setBasicContent}
            setIntermediateContent={setIntermediateContent}
            setAdvanceContent={setAdvanceContent}
            setOpenEdit={setOpenEdit}
            openEdit={openEdit}
            basicState={basicState}
            setBasicState={setBasicState}
            intermediateState={intermediateState}
            setIntermediateState={setIntermediateState}
            advanceState={advanceState}
            setAdvanceState={setAdvanceState}
          />

          <Notification notify={notify} setNotify={setNotify} />
        </Box>
      )}
    </>
  );
};
export default QuestionsUpload;
