import React, { useState, useEffect } from 'react';
import { DataTable } from '../../../components/Custom/datagrid/datagrid';
import ReusableButton from '../../../components/reusable-button/reusable-button';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import axios from 'axios';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import Notification from '../../../components/Custom/Notification';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import VideocamOutlinedIcon from '@mui/icons-material/VideocamOutlined';
import ReactLoading from 'react-loading'

import {
  TextField,
  Grid,
  makeStyles,
  Typography,
  Box,
  Paper,
} from '@material-ui/core';
import { MyForm, Form } from '../../../components/MyForm';
import { Custom } from '../../../components/Custom/Custom';
import MyDialog from '../../../components/Custom/Dialog';
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;
const getStatus = [
  { id: '1', title: 'Active' },
  { id: '2', title: 'InActive' },
];
const initialValues = {
  videoName: '',
  videoLink: '',
  status: 'Active',
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const Absyziantalk = (props) => {
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(false);
  //   const [maxwidth,setMaxWidth] = useState('sm');
  // const [checked, setChecked] = React.useState(true);
  const [dataid, setdataid] = useState();
  const [done,setDone] = useState(undefined)
  // const [datas, setDatas] = useState({
  //   name: '',
  //   link: '',
  //   status: '',
  // });
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  // const [videoName, setVideoName] = useState('');
  // const [videoLink, setVideoLink] = useState('');
  // const [status, setStatus] = useState('');
  // const [message, setMessage] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [id, setId] = useState('');
  const columns = [
    { field: 'sno', headerName: 'S.No', width: 100, editable: true },
    {
      field: ' videoName ',
      headerName: "ABSYZian's Talk ",
      width: 650,
      renderCell: (params) => {
        return (
          <span>
            {params.row.video_status === 'Active' ? (
              <a href={params.row.video_link} target="_blank">
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <VideocamOutlinedIcon style={{ marginRight: 5 }} />
                  {params.row.video_name}
                </div>
              </a>
            ) : (
              <a
                href={params.row.video_link}
                target="_blank"
                style={{ pointerEvents: 'none', color: 'grayText' }}
              >
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <VideocamOutlinedIcon style={{ marginRight: 5 }} />
                  {params.row.video_name}
                </div>
              </a>
            )}
          </span>
        );
      },
    },
    {
      field: 'status',
      type: 'string',
      headerName: 'Status',
      width: 160,
      renderCell: (params) => {
        return (
          <span>
            {params.row.video_status}
            <Switch
              checked={params.row.video_status === 'Active'}
              // checked={false}
              size="large"
              onChange={() => {
                // console.log(tableData);
                handleChange(params.row.id, params.row.video_status);
              }}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </span>
        );
      },
    },
    {
      field: 'actions',
      type: 'string',
      headerName: 'Actions',
      width: 100,
      renderCell: (params) => (
        // console.log(params.id)
        <span>
          <ReusableButton
            size="extra-large"
            className="qstnUpldPageBttn"
            //buttonName="Filter"
            startIcon={<DeleteOutlineIcon />}
            //onClick={() => handleDelete(params.id)}
            onClick={() => handleClickOpenDelModal(params.id)}
          />
          <Dialog
            open={openModal}
            className="dialogBox"
            // TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseByCancelModal}
            aria-describedby="alert-dialog-slide-description"
            BackdropProps={{
              style: {
                background: '#00000022',
                boxShadow: '0px 0px 0px 0px !important',
              },
            }}          >
            <DialogTitle></DialogTitle>
            <DialogContent
            //  BackdropProps={{ style: { backgroundColor: "currentcolor" } }}
            >
              <DialogContentText id="alert-dialog-slide-description">
                Are you sure you want to delete the video?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                size="large"
                variant="outlined"
                className="qstnUpldPageBttn"
                buttonName="Cancel"
                style={{
                  // backgroundColor: "#080825",
                  color: '#080825',
                  fontFamily: 'Poppins',
                  fontSize: '0.8em',
                  fontWeight: 600,
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  outline: 'auto',
                  borderRadius: '4px',
                }}
                onClick={handleCloseByCancelModal}
              />
              <ReusableButton
                size="large"
                variant="contained"
                className="qstnUpldPageBttn"
                buttonName="Delete"
                style={{
                  backgroundColor: '#fe1c7f',
                  color: '#fff',
                  fontFamily: 'Poppins',
                  fontSize: '0.8em',
                  fontWeight: 200,
                  fontStretch: 'normal',
                  fontStyle: 'normal',
                  lineHeight: 'normal',
                  letterSpacing: 'normal',
                  textAlign: 'left',
                  borderRadius: '4px',
                }}
                onClick={() => handleDelete(id)}
              >
                Delete
              </ReusableButton>
            </DialogActions>
          </Dialog>
        </span>
      ),
    },
  ];

  //   const handleChange = (event) => {
  //     setChecked(event.target.checked);
  //   };
  const handleClickOpenDelModal = (id) => {
    setId(id);
    setOpenModal(true);
  };

  const handleCloseByCancelModal = () => {
    setOpenModal(false);
  };

  const handleChange = async (id, status) => {
    // console.log(id,status,"status")
    if (status === 'Active') status = 'InActive';
    else if (status === 'InActive') status = 'Active';
    // console.log(tableData);
    let raw = {};
    tableData.map((item) => {
      if (item.id === id) {
        // console.log('matched item');
        item.video_status = status;
        raw = JSON.stringify({
          video_link: item.video_link,
          video_name: item.video_name,
          video_status: status === 'Active' ? 'Y' : 'N',
        });
      }
    });
    // console.log(tableData);
    // console.log(raw);
    setDone(false) 
    // const raw = JSON.stringify({

    // });
    // console.log(raw);
    var myHeaders = new Headers();
    myHeaders.append('Content-Type', 'application/json');

    var requestOptions = {
      method: 'PUT',
      headers: myHeaders,
      body: raw,
    };

    await fetch(
      `${api1}/videos/${id}`,
      requestOptions
    )
      .then((response) => {
        getTableData();
        // setNotify({
        //    isOpen: true,
        //  message: 'Candidate Details Updated Successfully',
        // type: 'success',
        // });
      })
      .catch((error) => 
      console.log('error', error)
      );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    getTableData();
  }, []);
  const getTableData = async () => {
    await axios({
      method: 'get',
      url: `${api1}/videos`,
      headers: { token: `qwerty`, 'Content-Type': 'application/json' },
    })
      .then(function (response) {
        if (response.status) {
          const data = response.data.data[0].videos;

          // console.log('data', data);
          // data.map((items, index) => {
          //   console.log('items', items.video_name);
          //   console.log('index', index);
          // });

          const updatedData = data.map((items, index) => ({
            sno: index + 1,
            id: items.video_pk,
            video_name: items.video_name,
            video_link: items.video_link,
            video_status: items.video_status === 'Y' ? 'Active' : 'InActive',
          }));
          // console.log('updatedData', updatedData);
          setTableData(updatedData);
          setDone(true) 
        }
      })
      .catch(function (error) {
        // console.log('axios error', error);
      });
  };

  //console.log(tableData, 'tableData');

  const handleDelete = async (id) => {
    //  alert("clickled")
    // console.log(id);
    await axios.delete(`${api1}/videos/${id}`);
    // console.log('working');
    setdataid(id);
    getTableData();
    setNotify({
      isOpen: true,
      message: 'Video deleted successfully',
      type: 'success',
    });
    setOpenModal(false);
  };

  //console.log(tableData);
  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log('called');
    if (validate()) {
      // console.log(values);
      // console.log('validated');
      const newData = {
        video_link: values.videoLink,
        video_name: values.videoName,
        video_status: values.status === 'Active' ? 'Y' : 'N',
      };
      // console.log(newData);
      const headers = {
        token: 'qwerty',
        'Content-Type': 'application/json',
        Accept: 'application/json',
      };
      const config = { headers };
      try {
        const response = await axios.post(
          `${api1}/videos`,
          newData,
          config
        );
        // console.log(response.status);
        // console.log(response.data);
      } catch (e) {
        // console.log('something went wrong!', e);
      }
      getTableData();
      setNotify({
        isOpen: true,
        message: 'Video added successfully',
        type: 'success',
      });
      resetForm();
      setOpen(false);
    }
  };

  const validate = (inputValues = values) => {
    let code = { ...errors };
    if ('videoName' in inputValues)
      code.videoName = inputValues.videoName ? '' : 'This Field is required';
    if ('videoLink' in inputValues)
      code.videoLink = new RegExp(
        '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
      ).test(inputValues.videoLink)
        ? ''
        : 'Please enter a valid URL';
    if ('status' in inputValues)
      code.status =
        inputValues.status.toLowerCase() === 'active' ||
        inputValues.status.toLowerCase() === 'inactive'
          ? ''
          : 'Please enter Active/InActive';
    setErrors({
      ...code,
    });
    if (inputValues === values)
      return Object.values(code).every((x) => x === '');
  };
  const { values, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);
  // const [openPopup, setOpenPopup] = useState(false);

  return (
    <>
         {!done ? (
        <Box
          sx={{ flexGrow: 1 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh'
          }}
        >
          <ReactLoading
            type={'spinningBubbles'}
            color={'#fb2485'}
            height={100}
            width={100}
          />
        </Box>
      ) : (
    <Box
      sx={{
        flexGrow: 1,
        padding: '0px 80px 0px 80px !important',
        marginBottom: '220px',
      }}
    >
      <Box sx={{ flexGrow: 1 }} style={{ marginTop: '20px' }}>
        <Grid container spacing={2}>
          <Grid item xs={8} style={{ textAlign: 'end', paddingRight: 150 }}>
            <h4
              style={{
                fontSize: '1.5em',
                fontFamily: 'Poppins',
                color: 'white',
                fontStretch: 'normal',
                letterSpacing: 'normal',
              }}
            >
              ABSYZian's Talk
            </h4>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ textAlign: 'end', margin: 'auto', paddingRight: '35px' }}
          >
            <ReusableButton
              size="small"
              style={{ backgroundColor: 'white', color: 'black' }}
              variant="contained"
              onClick={handleClickOpen}
              //   type={type}
              //    className={`${className}`}
              startIcon={<AddCircleIcon />}
              buttonName="Add New Link"
            />
            <Dialog
              open={open}
              onClose={handleClose}
              style={{ padding: '16px' }}
            >
              <Form onSubmit={handleSubmit}>
                <DialogTitle>Upload ABSYZian's Talk Link</DialogTitle>
                <DialogContent>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <b>
                            <label style={{ marginLeft: 15, fontSize: 15 }}>
                              Video Name
                            </label>
                          </b>
                          <Custom.Input
                            name="videoName"
                            value={values.videoName}
                            label={'Name'}
                            size="small"
                            onChange={handleInputChange}
                            error={errors.videoName}
                          />
                        </div>
                      </Grid>

                      <Grid item xs={6}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <b>
                            <label style={{ marginLeft: '15px' }}>
                              Video Link
                            </label>
                          </b>
                          <Custom.LinkField
                            name="videoLink"
                            value={values.videoLink}
                            label={'Provide Link here'}
                            onChange={handleInputChange}
                            error={errors.videoLink}
                          />
                        </div>
                      </Grid>
                      <Grid item xs={6}>
                        <div
                          style={{ display: 'flex', flexDirection: 'column' }}
                        >
                          <b>
                            <label style={{ marginLeft: '15px' }}>Status</label>
                          </b>
                          {/* <Custom.Input
                            name="status"
                            value={values.status}
                            label={'Active/InActive'}
                            onChange={handleInputChange}
                            error={errors.status}
                          /> */}

                          <Custom.Dropdown
                            name="status"
                            // label="Select"
                            value={values.status}
                            onChange={handleInputChange}
                            options={getStatus}
                            error={errors.status}
                            size="small"
                            style={{
                              height: 44,
                              alignItems: 'center',
                              display: 'flex',
                            }}
                          ></Custom.Dropdown>
                        </div>
                      </Grid>
                      <Grid item xs={6}></Grid>
                    </Grid>
                  </Box>
                </DialogContent>
                <DialogActions>
                  <ReusableButton
                    onClick={() => {
                      handleClose();
                      resetForm();
                    }}
                    size="large"
                    variant="outlined"
                    buttonName="Cancel"
                    style={{
                      marginRight: '10px',
                      color: '#000936',
                      border: '1px solid #000936',
                      marginBottom: '40px',
                      width: '98px',
                      height: '38px',
                      borderRadius: 4,
                    }}
                  >
                    Cancel
                  </ReusableButton>
                  <ReusableButton
                    onClick={() => {
                      if (validate()) {
                        handleClose();
                      }
                    }}
                    type="submit"
                    size="large"
                    variant="contained"
                    buttonName="Add"
                    style={{
                      backgroundColor: '#fb2485',
                      marginRight: '30px',
                      marginBottom: '40px',
                      width: '98px',
                      height: '38px',
                      borderRadius: 4,
                    }}
                  >
                    Add
                  </ReusableButton>
                </DialogActions>
              </Form>
            </Dialog>
          </Grid>
        </Grid>
      </Box>

      <div
        style={{
          width: '100%',

          textAlign: 'center',
          backgroundColor: 'white',

          borderRadius: '15px',
          marginBottom: '20px',
        }}
      >
        <Box
          sx={{
            width: '100%',
            '& .super-app-theme--header': {
              backgroundColor: '#f1f1f1',
              color: '#02215b',
              fontSize: '16px',
            },
          }}
        >
          <DataTable
            rows={tableData}
            columns={columns}
            pageSize={10}
            rowHeight={40}
            rowsPerPageOptions={[10]}
            autoHeight
            width="100%"
            // style={{ marginBottom: '20px', overFlowX: 'hidden' }}

            hideFooterSelectedRowCount={true}
          />
        </Box>
      </div>
      <Notification notify={notify} setNotify={setNotify} />
    </Box>
      )}</>
  );
};

export default Absyziantalk;