import React, { useState, useEffect } from "react";
import { DataTable } from "../../../components/reusable-datagrid/reusable-datgrid";
import { Grid, Box, Paper, Typography } from "@material-ui/core";
import ReusableButton from "../../../components/reusable-button/reusable-button";
import { ReusableCards } from "../../../components/reusable-cards/reusable-cards";
// import { DataTable } from '../../../components/reusable-datagrid/reusable-datgrid';
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import axios from "axios";
import { Custom } from "../../../components/Custom/Custom";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { MyForm, Form } from "../../../components/MyForm";
import EditIcon from "@mui/icons-material/Edit";
import Notification from "../../../components/Custom/Notification";
import ReactLoading from "react-loading";

import "./candidate-count.css";

import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;

const initialValues = {
  firstname: "",
  lastname: "",
  email: "",
  department: "",
  designation: "",
  phone: "",
  dateOfJoin: new Date(),
};

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function Editpopup(props) {
  const { open, id, handleClose, departments, getIdValue, setLoadingTable } =
    props;
  const [user, setUser] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleOnChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const handleOnChangeNumber = (e) => {
    const value = e.target.value.replace(/\D/g, "");
    setUser({ ...user, [e.target.name]: value });
  };

  const validate = (inputValues = values) => {
    // console.log("validating")
    let code = { ...errors };
    if ("firstname" in inputValues)
      code.firstname = inputValues.firstname ? "" : "First Name is required";
    if ("email" in inputValues)
      code.email =
        inputValues.email && /$^|.+@.+..+/.test(inputValues.email)
          ? ""
          : "Email is not valid";
    if ("phone" in inputValues)
      code.phone =
        inputValues.phone.length > 9 ? "" : "Minimum 10 numbers required";
    if ("department" in inputValues)
      code.department =
        inputValues.department.length !== 0 ? "" : "Department is required";
    if ("designation" in inputValues)
      code.designation = inputValues.designation
        ? ""
        : "Designation is required";
    if ("lastname" in inputValues)
      code.lastname = inputValues.lastname ? "" : "Last Name is required";
    setErrors({
      ...code,
    });
    // console.log(inputValues);
    if (inputValues === values)
      return Object.values(code).every((x) => x === "");
  };
  const { values, setValues, handleInputChange, errors, setErrors, resetForm } =
    MyForm(initialValues, true, validate);
  useEffect(() => {
    getUserById(id);
  }, [id]);

  const getUserById = async (id) => {
    setLoading(true);
    const response = await axios.get(`${api1}/admin/userdetails/${id}`);
    // console.log(id);
    console.log(response, "resEdit");
    // console.log(response.data.data[0].users[0], 'userdetails');
    if (response.data.api_status === 200) {
      const data = response.data.data[0].users[0];
      // console.log(data);
      setUser({
        ...user,
        firstname: data.firstname,
        lastname: data.lastname,
        email: data.email,
        department: data.department,
        designation: data.designation,
        phone: data.phone,
        dateOfJoin: data.joiningdate,
      });
      setLoading(false);
    }
  };

  function convert(dateOfJoin) {
    var date = new Date(dateOfJoin),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  const {
    firstname,
    lastname,
    email,
    department,
    designation,
    phone,
    dateOfJoin,
  } = user;
  // console.log(department)

  const onSubmitEdit = async (id) => {
    if (validate()) {
      // console.log(id);
      // console.log(department)
      // console.log(designation)
      // console.log(phone)
      handleClose();
      setLoadingTable(true);
      const date = convert(values.dateOfJoin);
      // console.log(date);
      const raw = JSON.stringify({
        firstname: firstname,
        lastname: lastname,
        email: email,
        department: department,
        // getDepartmentCollection[department - 1].title,
        designation: designation,
        // getDesignation[designation - 1].title,
        phone: phone,
        joiningdate: date,
      });
      // console.log(raw);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
      };

      await fetch(`${api1}/admin/userdetails/${id}`, requestOptions)
        .then((response) => {
          setLoadingTable(true);
          setNotify({
            isOpen: true,
            message: "Candidate Details Updated Successfully",
            type: "success",
          });
          getIdValue();
        })
        .catch((error) => {
          console.log("error", error);
          setNotify({
            isOpen: true,
            message: "Candidate Details cannont be edited",
            type: "success",
          });
        });
      // }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        style={{ padding: "16px", overflowY: "hidden !important" }}
      >
        {loading ? (
          <Box
            sx={{ flexGrow: 1, width: "550px" }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100vh",
            }}
          >
            <ReactLoading
              type={"spinningBubbles"}
              color={"#fb2485"}
              height={100}
              width={100}
            />
          </Box>
        ) : (
          <Form
          //onSubmit={() => onSubmitEdit(id)}
          >
            <DialogTitle>Edit Candidate Record</DialogTitle>
            <DialogContent>
              <Box sx={{ flexGrow: 1 }}>
                <Grid container>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: 14, fontWeight: 500 }}>
                        First Name
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                          name="firstname"
                          placeholder="First Name"
                          value={firstname}
                          onChange={handleOnChange}
                          error={errors.firstname}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: 14 }} variant="h6">
                        Last Name
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                          name="lastname"
                          placeholder="Last Name"
                          onChange={handleOnChange}
                          value={lastname}
                          error={errors.lastname}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: 14 }} variant="h6">
                        Email Address
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                          name="email"
                          value={email}
                          placeholder="example@email.com"
                          onChange={handleOnChange}
                          error={errors.email}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: 14 }} variant="h6">
                        Department
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Dropdown
                          name="department"
                          label="Select"
                          value={department}
                          onChange={handleOnChange}
                          options={departments}
                          error={errors.department}
                          size="small"
                          style={{
                            height: 44,
                            alignItems: "center",
                            display: "flex",
                          }}
                        ></Custom.Dropdown>
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: 14 }} variant="h6">
                        Designation
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Input
                          name="designation"
                          placeholder="Designation"
                          onChange={handleOnChange}
                          value={designation}
                          error={errors.designation}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography style={{ fontSize: 14 }} variant="h6">
                        Phone Number
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.Phone
                          name="phone"
                          label="0000000000"
                          value={phone}
                          onChange={handleOnChangeNumber}
                          error={errors.phone}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{ marginLeft: 10, fontSize: 14 }}
                        variant="h6"
                      >
                        Joining Date
                      </Typography>
                      <span style={{ marginLeft: "-7px" }}>
                        <Custom.DatePicker
                          name="dateOfJoin"
                          value={dateOfJoin}
                          onChange={handleOnChange}
                          size="small"
                        />
                      </span>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </DialogContent>
            <DialogActions>
              <ReusableButton
                onClick={handleClose}
                size="large"
                variant="outlined"
                buttonName="Cancel"
                style={{
                  marginRight: "10px",
                  color: "#000936",
                  border: "1px solid #000936",
                  marginBottom: "40px",
                  width: "98px",
                  height: "38px",
                  borderRadius: 4,
                }}
              >
                Cancel
              </ReusableButton>
              <ReusableButton
                //  onClick={handleClose}
                //type="submit"
                onClick={() => onSubmitEdit(id)}
                size="large"
                variant="contained"
                buttonName="Add"
                style={{
                  backgroundColor: "#fb2485",
                  marginRight: "30px",
                  marginBottom: "40px",
                  width: "98px",
                  height: "38px",
                  borderRadius: 4,
                }}
              >
                Add
              </ReusableButton>
            </DialogActions>
          </Form>
        )}
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />
    </div>
  );
}
