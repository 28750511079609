// eslint-disable-next-line import/no-anonymous-default-export
let obj={
    loginApi: ``,
    AddUserApi:``,
    Api1:`http://localhost:5000`,
    //Api1:`https://api.absyzlounge.com`,
    Api2:`http://143.110.183.48:9090`
    }
    if(process.env.NODE_ENV==='production'){
        obj.Api1=`https://api.absyzlounge.com`;
        obj.Api2=`https://apiservices.absyzlounge.com`;
    }
    export default obj;
// export default  {
//     loginApi: ``,
//     AddUserApi:``,
//     Api1:`https://absyzloungeapi.herokuapp.com`,
//     //Api1:`https://api.absyzlounge.com`,
//     Api2:`http://143.110.183.48:9090`
//     }
