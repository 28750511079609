import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";

export default function Dropdown3(props) {
  const {
    name,
    label,
    value,
    size,
    error,
    onChange,
    style,
    placeholder,
    options,
    menustyle,
    defaultValue,
    displayEmpty,
    errorMsg,
  } = props;

  return (
    <FormControl variant="outlined" fullWidth {...(error && { error: true })}>
      <Select
        label={label}
        name={name}
        defaultValue={defaultValue}
        displayEmpty={displayEmpty}
        value={value}
        size={size}
        placeholder={placeholder}
        onChange={onChange}
        style={style}
      >
        {options.map((item) => (
          <MenuItem key={item.id} value={item.id}>
            {item.title}
          </MenuItem>
        ))}
      </Select>
      {error && <FormHelperText>{errorMsg}</FormHelperText>}
    </FormControl>
  );
}
