import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import './dashboard-carousel.css';
import ReactPlayer from 'react-player';
import Vid1 from '../../../../assets/videos/vid1.mp4';
import Vid2 from '../../../../assets/videos/vid2.mp4';
import Vid3 from '../../../../assets/videos/vid3.mp4';
import Vid4 from '../../../../assets/videos/vid4.mp4';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const images = [
  {
    title: 'vid1',
    src:Vid1
    // src:"https://www.youtube.com/watch?v=eGqvkSoQLvw&t=1s"

  },
  {
    title: 'vid2',
    src:Vid2
    // src:"https://www.youtube.com/watch?v=-z7YQKe95qA"
  },
  {
    title: 'vid3',
    src:Vid3
    // src:"https://www.youtube.com/watch?v=jmtp1KRLcIo"
  },
  {
    title: 'vid4',
    src:Vid4
    // src:"https://www.youtube.com/watch?v=taJiV5jeAGA"
  },
];

export const DashboardCarousel = () => {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = images.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  return (
    <Box sx={{ flexGrow: 1}}>
      <AutoPlaySwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        interval={50000}
        // animateHeight={true}
        //style={{width:"100%",overflow:"hidden"}}
        >
        {images.map((step, index) => (
          <div key={step.title}>
            {Math.abs(activeStep - index) <= 2 ? (
              <ReactPlayer
                url={step.src}
                alt={step.title}
                width="100%"
                height="100%"
                pip={true}
                controls={true}
                playing={true}
                muted={true}
                loop={true}
                style={{overflow:"hidden"}}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      
      <MobileStepper
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            // style={{ position: 'relative', top: '-60px',left:"20px" }}
            onClick={handleNext}
            disabled={activeStep === maxSteps - 1}
          >
            
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" 
          //style={{ position: 'relative', top: '-60px' }}
          onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            
          </Button>
        }
      />
    </Box>
  );
};