import React from 'react';


// import { withRouter } from "react-router-dom";
import Cookies from 'js-cookie';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import RButton from './button/button';
import Modal from '@mui/material/Modal';
const parseJwt = (token) => {
  try {
    return JSON.parse(window.atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border:"0px",
  boxShadow: 24,
  p: 4,
};
const AuthVerify = (props) => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  
  const navigate = useNavigate();
  //   props.history.listen(() => {
  const user = Cookies.get('jwt_token');
  console.log('khjsdbiluvhndfk', user);
  if (user) {
    const decodedJwt = parseJwt(user);
    console.log('khjsdbiluvhndfk', decodedJwt);
    if (decodedJwt.exp * 1000 < Date.now() && !open ) {
      //alert("hello world")
      setOpen(true);
     
    }
  }
  //   });

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Session has expired
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Please Login Again.
          </Typography>
<div style={{display:"flex",justifyContent:"flex-end"}}>
          <RButton
            buttonName={'OK'}
            className={'section3-feedback-sub'}
            onClick={() => {
                Cookies.remove('jwt_token');
                navigate("/")
                handleClose()
            }}
          />
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AuthVerify;
