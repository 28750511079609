import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
// import Link from "@mui/material/Link";
import { ReusableInputfield } from '../../../components/Custom/inputfield/inputfield';
import ReusableButton from '../../../components/Custom/button/button';
import { ReusableRememberMe } from '../../../components/Custom/remember-me/remember-me';
// import { blue } from "@mui/material/colors";
import AdminBg from '../../../assets/images/adminLoginPageBg.png';
import AdminContentPic from '../../../assets/images/adminLoginPageContentpic.png';
import Rectangle from '../../../assets/images/Rectangle.png';

import { makeStyles } from '@material-ui/core/styles';
import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import axios from 'axios';
import Notification from '../../../components/Custom/Notification';
import signupImg from '../../../assets/images/signupImg.png';

import { useNavigate } from 'react-router-dom';
import API from "../../../api/index";

const api1 = API.Api1;
const api2 = API.Api2;
const useStyles = makeStyles({
  inputfieldLabel: {
    '& .MuiFormLabel-root': {
      color: 'black',
      fontWeight: 400,
    },
  },
});
const inputFieldData = {
  email: '',
  confirmPassword: '',
  password: '',
};

const inputErrorMsg = {
  forConfirmPassword: '',
  forPassword: '',
};

const inputFieldErrorStatus = {
  confirmPasswordStatus: false,
  passwordStatus: false,
};

export default function ResetPassword(props) {
  const [notify, setNotify] = useState({
    isOpen: false,
    message: '',
    type: '',
  });
  const [inputFieldValues, setInputFieldValues] = useState(inputFieldData);
  const [inputFieldErrors, setInputFieldErrors] = useState(inputErrorMsg);
  const [errorStatus, setErrorStatus] = useState(inputFieldErrorStatus);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isChecked, setIsChecked] = useState(false);

  const navigate = useNavigate();
  let encryptEmail = searchParams.get('q');
  // //console.log(atob(encryptEmail))
  useEffect(() => {
    storeEmail(encryptEmail);
  }, []);

  const storeEmail = (encryptEmail) => {
    setInputFieldValues({ ...inputFieldValues, email: atob(encryptEmail) });
  };
  // console.log(inputFieldValues.email, 'email');
  const classes = useStyles();

  const handleSubmit = async () => {
    var passwordValidation =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/;

    // console.log(inputFieldValues.email,"input VALUE gIVEN");
    // console.log(inputFieldValues.password,"input VALUE gIVEN")

    if (passwordValidation.test(inputFieldValues.password) === false) {
      setErrorStatus({ ...errorStatus, passwordStatus: true });
      setInputFieldErrors({
        ...inputFieldErrors,
        forPassword: 'Enter Valid Password ',
      });
    } else if (inputFieldValues.password !== inputFieldValues.confirmPassword) {
      setErrorStatus({ ...errorStatus, confirmPasswordStatus: true });
      setInputFieldErrors({
        ...inputFieldErrors,
        forConfirmPassword: 'Password is Not Matching with Confirm Password',
      });
    } else {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        //body: JSON.stringify(getDesignation1),
        body: JSON.stringify({
          email: inputFieldValues.email,
          password: inputFieldValues.password,
          confirmPassword: inputFieldValues.confirmPassword,
        }),
      };
      const response = await fetch(
        `${api1}/user/reset-password`,
        requestOptions
      );

      const data = await response.json();
      //console.log(data, 'responsedata');
      //console.log(response);

      if (response.status === 200) {
        setNotify({
          isOpen: true,
          message: 'Password Reset Successfully',
          type: 'success',
        });
      }
      navigate('/');
      // const jwtToken = data.data[0].jwttoken;
      // //console.log(data.data[0].jwttoken, "data");

      // if(response.status === 200){
      //   alert("Login successfully")
      // }

      // const onSubmitSuccess = (jwtToken) => {
      //   Cookies.set("jwt_token", jwtToken, {
      //     expires: 30,
      //   });
      //   // navigate("/home");
      // };

      // if (response.ok === true) {
      //   onSubmitSuccess(jwtToken);
      // } else {
      //   //console.log("check the response");
      // }

      // if (jwtToken !== undefined) {
      //   // return <Navigate to="/home" />;
      //   //console.log(jwtToken,"jwtToken")
      // }
    }
  };

  const handleTextChangeConfirmPassword = (event, value) => {
    //console.log('values', event.target.value);
    // setEmail(event.target.value);
    setInputFieldValues({
      ...inputFieldValues,
      confirmPassword: event.target.value,
    });
  };
  const handleTextChangePassword = (event, value) => {
    //console.log('values', event.target.value);
    // setPassword(event.target.value);
    setInputFieldValues({ ...inputFieldValues, password: event.target.value });
  };

  // const handleTextChangeEmail = (event) => {
  //   //console.log("values", event.target.value);
  //     // setEmail(event.target.value);
  //     setInputFieldValues({...inputFieldValues,  confirmPassword:event.target.value})
  // };

  const onChangeCheckbox = (event) => {
    setIsChecked(event.target.checked);
    //console.log(isChecked, 'isChecked');
  };

  return (
    <>
      <div
        component="main"
        className="OverallDiv"
        style={{
          height: '115vh',
          zIndex: 1,
          position: 'absolute',
          width: '100%',
        }}
      >
        <Container component="main" maxWidth="md">
          <Card
            variant="outlined"
            sx={{ marginTop: '7%', Width: '100%', borderRadius: 0 }}
          >
            <CardContent sx={{ padding: '15px 33px 33px 0px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                  <div>
                    <img
                      src={signupImg}
                      alt="AdminContentPic"
                      width="335"
                      height="410"
                      style={{ marginLeft: '25px', marginTop: '30px' }}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} md={6} className={classes.textGrid}>
                  <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <span
                      className="adminLoginHeadingThree"
                      style={{ marginTop: '14px', marginBottom: '7px' }}
                    >
                      Reset Password
                    </span>
                  </Box>

                  <Box
                    sx={{
                      '& .MuiTextField-root': { width: '38ch' },
                      marginTop: 2,
                    }}
                  >
                    <Stack direction="column" spacing={3}>
                      <ReusableInputfield
                        variant="outlined"
                        name="email"
                        margin="normal"
                        required
                        type="text"
                        value={inputFieldValues.email}
                        // defaultValue={inputFieldValues.email}
                        //id="email"
                        id="outlined-basic"
                        fullWidth
                        label="E-Mail"
                        //onChange={(e) => handleTextChangeEmail(e, "email")}
                        autoComplete="off"
                        InputProps={{
                          readOnly: true,
                        }}
                        className={classes.inputfieldLabel}
                        autoFocus

                        // error={errorStatus.emailStatus}
                        // helperText={inputFieldErrors.forEmail}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        value={inputFieldValues.password}
                        onChange={(e) =>
                          handleTextChangePassword(e, 'password')
                        }
                        id="password"
                        // id="outlined-basic"
                        autoComplete="off"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        className={classes.inputfieldLabel}
                        error={errorStatus.passwordStatus}
                        helperText={inputFieldErrors.forPassword}
                      />

                      <ReusableInputfield
                        variant="outlined"
                        name="confirmPassword"
                        margin="normal"
                        required
                        type="password"
                        value={inputFieldValues.confirmPassword}
                        //id="email"
                        id="outlined-basic"
                        fullWidth
                        label="Confirm Password"
                        onChange={(e) =>
                          handleTextChangeConfirmPassword(e, 'confirmPassword')
                        }
                        autoComplete="off"
                        inputProps={{
                          autoComplete: 'off',
                        }}
                        className={classes.inputfieldLabel}
                        autoFocus
                        error={errorStatus.confirmPasswordStatus}
                        helperText={inputFieldErrors.forConfirmPassword}
                      />

                      <Stack
                        direction="row"
                        spacing={2}
                        style={{
                          margin: 0,
                          display: 'flex',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Stack direction="row" spacing={2}>
                          <ReusableRememberMe
                            color="default"
                            size="small"
                            name="lsRememberMe"
                            checked={isChecked}
                            onChange={onChangeCheckbox}
                          />
                          <label
                            style={{
                              margin: 0,
                              display: 'flex',
                              alignItems: 'center',
                            }}
                            className="loginFieldText"
                          >
                            Remember Me
                          </label>
                        </Stack>
                        {/* <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Link
                            href="#"
                            underline="none"
                            style={{
                              fontSize: "0.8em",
                              color: "#717171",
                              fontFamily: "Poppins",
                              fontWeight: 600,
                              fontStretch: "normal",
                              fontStyle: "normal",
                              lineHeight: "normal",
                              letterSpacing: "normal",
                            }}
                            className="loginFieldText"
                          >
                            Forgot Password?
                          </Link>
                        </div> */}
                      </Stack>
                    </Stack>

                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'start',
                        paddingTop: '15px',
                      }}
                    >
                      <ReusableButton
                        size="medium"
                        variant="contained"
                        type="submit"
                        className="adminLoginButton"
                        buttonName="Change Password"
                        style={{
                          backgroundColor: '#fe1c7f',
                          fontFamily: 'Poppins',
                          fontSize: '0.8em',
                          fontWeight: 200,
                          fontStretch: 'normal',
                          fontStyle: 'normal',
                          lineHeight: 'normal',
                          letterSpacing: 'normal',
                          textAlign: 'left',
                          color: '#fff',
                          padding: '12px 35px 12px 35px',
                          borderRadius: '4px',
                        }}
                        onClick={handleSubmit}
                      />
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
}
